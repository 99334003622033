import { type QueryClient } from '@tanstack/react-query'
import { type LoaderFunctionArgs } from 'react-router-dom'
import { assignedUsersQuery } from '#src/routes/enable/roleplay/scenario/queries'

export type InviteParticipantsLoaderResType = Awaited<ReturnType<ReturnType<typeof inviteParticipantsLoader>>>

export const inviteParticipantsLoader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId || !params.scenarioId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const members = await queryClient.fetchQuery(assignedUsersQuery(params.companyId, params.scenarioId))

		return {
			members,
			companyId: params.companyId,
			scenarioId: params.scenarioId,
		}
	}

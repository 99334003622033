import { type QueryClient } from '@tanstack/react-query'
import { type LoaderFunctionArgs, useLoaderData } from 'react-router-dom'
import { z } from 'zod'
import { EmptyStateCard } from '#src/components'
import { PageLayout } from '#src/components/ui/PageLayout'
import { Surface } from '#src/components/ui/surface'
import { InviteMemberForm } from './invite'
import { allRolesQuery, membersQuery, rolesQuery } from './queries'
import { MembersSchema, RolesSchema } from './schema'

export type TeamLoaderResponse = Awaited<ReturnType<ReturnType<typeof loader>>>

export const TeamLoaderResponseSchema = z.object({
	handle: z.object({
		companyId: z.string(),
	}),
	members: MembersSchema,
	roles: RolesSchema,
})

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const [members, roles, allRoles] = await Promise.all([
			queryClient.fetchQuery(membersQuery(params.companyId)),
			queryClient.fetchQuery(rolesQuery(params.companyId)),
			queryClient.fetchQuery(allRolesQuery(params.companyId)),
		])

		return {
			handle: {
				companyId: params.companyId,
			},
			members,
			roles,
			allRoles,
		}
	}

export default function Team() {
	const { members, allRoles } = useLoaderData() as TeamLoaderResponse

	const roleText = (role: string) => {
		return (
			Object.entries(allRoles).find((entry) => entry[1] === role)?.[0] ?? 'Unknown role' // prettier-ignore
		)
	}

	return (
		<PageLayout title="Manage your team">
			<Surface className="flex w-full flex-col gap-8">
				<InviteMemberForm />

				<section className="flex flex-col gap-2">
					<h2 className="text-title-md text-neutral-1-fg">Members</h2>
					{members.length === 0 ? (
						<EmptyStateCard icon="group" title="You don't have any members yet." />
					) : (
						<ul>
							{members.map(member => (
								<li
									key={member.email}
									className="flex flex-col gap-0.5 border-neutral-1-bd p-3 [&:not(:first-child)]:border-t"
								>
									<p className="text-body-md text-neutral-2-fg">
										{member.email}&nbsp;
										{!member.accepted ? <span className="text-body-sm text-neutral-3-fg">(invite sent)</span> : null}
									</p>
									<p className="text-body-sm text-neutral-3-fg">{member.roles.map(roleText).join(', ')}</p>
								</li>
							))}
						</ul>
					)}
				</section>
			</Surface>
		</PageLayout>
	)
}

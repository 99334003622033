import { type QueryClient } from '@tanstack/react-query'
import { type LoaderFunctionArgs } from 'react-router-dom'
import { z } from 'zod'
import { showToast } from '#src/context/ToastContext'
import { statisticsQuery } from '#src/routes/lead-research-agent/queries'

export const LoaderSchema = z.object({
	data: z.object({
		lra: z.object({
			launched: z.number(),
			completed: z.number(),
		}),
	}),
})

export type LoaderResType = Awaited<ReturnType<ReturnType<typeof loader>>>

export type LoaderRes = z.infer<typeof LoaderSchema>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params: { companyId } }: LoaderFunctionArgs): Promise<LoaderRes> => {
		if (!companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		try {
			const lraStatistics = await queryClient.fetchQuery(statisticsQuery(companyId))
			return {
				data: {
					lra: {
						launched: lraStatistics.inProgressCount + lraStatistics.inQueueCount,
						completed: lraStatistics.completedCount,
					},
				},
			}
		} catch (e) {
			showToast({
				message: `${(e as Response).status}: ${(e as Response).statusText} | ${(e as Response).url}`,
				type: 'error',
			})
			return {
				data: {
					lra: {
						launched: 0,
						completed: 0,
					},
				},
			}
		}
	}

import * as Sentry from '@sentry/react'
import { type QueryClient } from '@tanstack/react-query'
import { createBrowserRouter } from 'react-router-dom'
import Root, { loader as rootLoader } from '#src/routes/_layout/root'
import adminRouter from '#src/routes/_router/admin'
import { copilotRouter } from '#src/routes/_router/copilot'
import mainRouter from '#src/routes/_router/main'
import authRouter from '#src/routes/auth/router'
import developersRouter from '#src/routes/developers/router'
import RootBoundary from '#src/routes/root-boundary'

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)

export const createAppRouter = (client: QueryClient) =>
	sentryCreateBrowserRouter([
		{
			element: <Root />,
			errorElement: <RootBoundary />,
			loader: rootLoader(client),
			children: [
				authRouter(),
				copilotRouter(client),
				adminRouter(client),
				mainRouter(client),
				...(import.meta.env.DEV ? [developersRouter()] : []),
			],
		},
	])

import { type QueryClient } from '@tanstack/react-query'
import { type RouteObject } from 'react-router-dom'
import DefaultSidebarLayout from '#src/layouts/DefaultSidebarLayout'
import { chatRouter } from '#src/routes/enable/chat/router'
import EnableDashboard, { loader as enableDashboardLoader } from '#src/routes/enable/dashboard'
import { glossaryRouter } from '#src/routes/enable/glossary/router'
import { loader as enableModuleLoader, type EnableModuleLoaderResponse } from '#src/routes/enable/loaders/root'
import { personasRouter } from '#src/routes/enable/personas/router'
import { roleplayRouter } from '#src/routes/enable/roleplay/router'
import RouteBoundary from '#src/routes/route-boundary'
import { routes } from '#src/utils/routes'

export default (client: QueryClient) =>
	({
		path: '/enable/:companyId',
		element: <DefaultSidebarLayout />,
		loader: enableModuleLoader,
		handle: {
			crumb: (data: EnableModuleLoaderResponse) => [
				{
					to: routes.enable.index({
						companyId: data.handle.companyId,
					}),
					label: 'Enable',
					icon: 'data-enrichment',
				},
			],
		},
		children: [
			{
				index: true,
				loader: enableDashboardLoader(client),
				element: <EnableDashboard />,
				errorElement: <RouteBoundary />,
			},
			personasRouter(client),
			glossaryRouter(client),
			chatRouter(client),
			roleplayRouter(client),
		],
	}) satisfies RouteObject

import { Fragment, type ReactNode } from 'react'
import { NavLink, useFetcher } from 'react-router-dom'
import { Button, buttonVariants } from '#src/components/ui/button'
import { Icon, type IconName } from '#src/components/ui/icon'
import useHeaderActions from '#src/hooks/layouts/useHeaderActions'
import { cn } from '#src/utils/misc'

const Action = ({
	icon,
	label,
	id,
	action,
	method,
}: {
	icon?: string
	label: ReactNode
	id: string
	action: string
	method: 'PUT' | 'POST' | 'PATCH' | 'DELETE'
}) => {
	const fetcher = useFetcher({ key: id })

	return (
		<fetcher.Form action={action} method={method}>
			<Button
				type="submit"
				variant="ghost"
				size="sm"
				disabled={fetcher.state !== 'idle'}
				className="flex flex-nowrap items-center gap-1 hover:bg-neutral-1-bg-selected"
			>
				{icon ? <Icon name={icon as IconName} size="sm" /> : null}
				{label}
			</Button>
		</fetcher.Form>
	)
}

const Actions = () => {
	const actions = useHeaderActions()

	if (!actions?.length) return null

	return (
		<ol className="flex items-center gap-1">
			{actions.map((action, index) => (
				<Fragment key={index}>
					<li className="text-body-md font-medium text-neutral-2-fg">
						{action.to ? (
							<NavLink
								to={action.to}
								className={({ isPending, isActive }) =>
									cn(
										buttonVariants({ variant: 'ghost', size: 'sm' }),
										'flex flex-nowrap items-center gap-1 hover:bg-neutral-1-bg-selected',
										isPending ? 'pointer-events-none' : '',
										isActive ? 'bg-neutral-1-bg-selected' : '',
										action.className,
									)
								}
							>
								{action.icon ? <Icon name={action.icon as IconName} size="sm" /> : null}
								{action.label}
							</NavLink>
						) : null}
						{action.id && action.action && action.method ? (
							<Action
								id={action.id}
								action={action.action}
								method={action.method}
								icon={action.icon}
								label={action.label}
							/>
						) : null}
					</li>
				</Fragment>
			))}
		</ol>
	)
}

export default Actions

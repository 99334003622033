export const MAX_UPLOAD_SIZE = 1024 * 1024 * 3 // 3MB
export const ACCEPTED_FILE_TYPES = ['text/csv'] as const

export enum FILE_TYPES {
	Accounts = 'account',
	AccountsAndContacts = 'account_contact',
}

export enum LRA_JOB_STATUS {
	Launching = 'launching',
	InProgress = 'in_progress',
	Completed = 'completed',
	CompletedWithQuestions = 'completed_with_questions',
	Cancelled = 'cancelled',
	Error = 'error',
}

export const JobStatusName: Record<LRA_JOB_STATUS, string> = {
	[LRA_JOB_STATUS.Launching]: 'In progress...',
	[LRA_JOB_STATUS.InProgress]: 'In progress...',
	[LRA_JOB_STATUS.Completed]: 'Completed',
	[LRA_JOB_STATUS.CompletedWithQuestions]: 'Completed',
	[LRA_JOB_STATUS.Cancelled]: 'Stopped',
	[LRA_JOB_STATUS.Error]: 'Error',
} as const

export enum EXPORT_TYPE {
	Full = 'full',
	ICPIBPFull = 'icp_ibp_full',
	Contacts = 'contacts',
	Accounts = 'accounts',
	ICPAccounts = 'icp_accounts',
	IBPContacts = 'ibp_accounts',
}

export enum FORM_STEPS {
	First = 'file',
	Second = 'ecosystems',
	Third = 'additional_info',
}

import { captureException } from '@sentry/react'
import { type QueryClient } from '@tanstack/react-query'
import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { showToast } from '#src/context/ToastContext'
import { assignUserToScenario } from '#src/routes/enable/roleplay/scenario/mutations'
import { roleplayScenarioKeys } from '#src/routes/enable/roleplay/scenario/queries'
import { routes } from '#src/utils/routes'

export const inviteParticipantsAction =
	(queryClient: QueryClient) =>
	async ({ params, request }: ActionFunctionArgs) => {
		if (!params.companyId || !params.scenarioId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})
		const formData = await request.formData()
		const selectedMembers = formData.getAll('members')

		const assignRequests = selectedMembers.map(async item => {
			return await assignUserToScenario(params.companyId!, params.scenarioId!, item as string)
		})

		await queryClient.invalidateQueries({
			queryKey: roleplayScenarioKeys.detail(params.companyId, params.scenarioId),
		})
		await queryClient.invalidateQueries({
			queryKey: roleplayScenarioKeys.assignedUsers(params.companyId, params.scenarioId),
		})

		try {
			await Promise.all(assignRequests)
			showToast({
				message: 'Invitations were sent',
				type: 'success',
			})
		} catch (error) {
			captureException(error)
		}

		return redirect(
			routes.enable.roleplay.scenario.index({
				companyId: params.companyId,
				scenarioId: params.scenarioId,
			}),
		)
	}

import { useMemo } from 'react'
import { useLoaderData, useRouteLoaderData } from 'react-router-dom'
import { type z } from 'zod'
import { type MainLoaderResponse } from '#src/routes/_layout/main'
import { type CompaniesSchema, type CompanySchema } from '#src/routes/company/schema'

type UseCompanyLoaderType = {
	companies: z.infer<typeof CompaniesSchema>
	company: z.infer<typeof CompanySchema>
}

export const useCompany = () => {
	const { company } = useRouteLoaderData('main-loader') as MainLoaderResponse
	const loaderData = useLoaderData() as UseCompanyLoaderType

	const companiesOptions = useMemo(() => {
		const companies = loaderData?.companies ?? []

		if (!companies.length) return []

		return companies.map(({ id, name }) => ({
			value: id,
			label: name,
		}))
	}, [loaderData])

	return { companiesOptions, company }
}

import { getFormProps, getInputProps, getSelectProps, getTextareaProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { clsx } from 'clsx'
import { useEffect, useState } from 'react'
import { Form, useActionData, useFetcher, useLoaderData, useNavigate, useSearchParams } from 'react-router-dom'
import { ErrorList, Field, SwitchField, TextareaField } from '#src/components/forms'
import { FileInput } from '#src/components/forms/FileInput'
import { RadioGroup } from '#src/components/forms/RadioGroup'
import ProductTip from '#src/components/product-tip'
import { Button } from '#src/components/ui/button'
import { DialogContent, DialogRoot } from '#src/components/ui/dialog'
import { FormStepper } from '#src/components/ui/FormStepper'
import { StatusButton } from '#src/components/ui/status-button'
import { FILE_TYPES, FORM_STEPS } from '#src/routes/lead-research-agent/constants'
import { type LRADownloadTemplateActionRes } from '#src/routes/lead-research-agent/routes/downloadTemplate'
import { LeadResearchAgentCreateJobSchema } from '#src/routes/lead-research-agent/schema'
import { cn, useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { type LRACreateActionRes, type LRACreateLoaderRes } from '../routes/create'
import { CheckboxTreeViewWithSearch } from './components'

const FORM_NAME = 'lra-job-create'

export const View = () => {
	const actionData = useActionData() as LRACreateActionRes
	const {
		values: { companyId },
		data: { ecosystems },
	} = useLoaderData() as LRACreateLoaderRes

	const navigate = useNavigate()
	const isPending = useIsPending()

	const [step, setStep] = useState<FORM_STEPS>(FORM_STEPS.First)

	useEffect(() => {
		const failedStep = actionData && 'failedStep' in actionData ? actionData?.failedStep : undefined
		if (failedStep) {
			setStep(failedStep)
		}
	}, [actionData])

	const [form, fields] = useForm({
		id: FORM_NAME,
		defaultValue: {
			companyId,
			contactFinderEnabled: false,
			type: FILE_TYPES.Accounts,
			verticals: ecosystems.map(ecosystem => ecosystem.verticals.map(vertical => String(vertical.id))).flat(),
		},
		constraint: getZodConstraint(LeadResearchAgentCreateJobSchema),
		lastResult: actionData && 'result' in actionData ? actionData.result : null,
		shouldValidate: 'onSubmit',
		shouldRevalidate: 'onInput',
		onValidate: ({ formData }) =>
			parseWithZod(formData, {
				schema: LeadResearchAgentCreateJobSchema,
			}),
	})

	const handleContinue = () => {
		switch (step) {
			case FORM_STEPS.First: {
				form.validate({ name: fields.type.name })
				form.validate({ name: fields.file.name })
				if (!fields.type.errors?.length && !fields.file.errors?.length) {
					setStep(FORM_STEPS.Second)
				}
				break
			}
			case FORM_STEPS.Second: {
				form.validate({ name: fields.verticals.name })
				if (!fields.verticals.errors?.length) {
					setStep(FORM_STEPS.Third)
				}
				break
			}
			case FORM_STEPS.Third: {
				form.validate({ name: fields.title.name })
				form.validate({ name: fields.comment.name })
				if (!fields.title.errors?.length && !fields.comment.errors?.length) {
					const formRef = document.getElementById(FORM_NAME) as HTMLFormElement
					formRef.requestSubmit()
				}
				break
			}
		}
	}

	const handleCancel = () => {
		switch (step) {
			case FORM_STEPS.First:
				navigate({
					pathname: routes.leadResearchAgent.index({ companyId }),
					search: searchParams.toString(),
				})
				break
			case FORM_STEPS.Second:
				setStep(FORM_STEPS.First)
				break
			case FORM_STEPS.Third:
				setStep(FORM_STEPS.Second)
				break
		}
	}

	const downloadFetcher = useFetcher<LRADownloadTemplateActionRes>()

	const handleDownload = (type: FILE_TYPES) => {
		const form = new FormData()
		form.append('type', type)
		downloadFetcher.submit(form, {
			method: 'POST',
			action: routes.leadResearchAgent.downloadTemplate({ companyId }),
		})
	}

	const [searchParams] = useSearchParams()

	return (
		<DialogRoot
			defaultOpen={true}
			onOpenChange={isOpen =>
				!isOpen &&
				navigate({
					pathname: routes.leadResearchAgent.index({ companyId }),
					search: searchParams.toString(),
				})
			}
		>
			<DialogContent
				dialogHeading="Create Lead Research Agent Job"
				className="w-full max-w-prose py-0"
				{...(step === FORM_STEPS.Second && {
					footerInfo: (
						<>
							<b>{fields.verticals.value?.length ?? 0}</b> verticals selected
						</>
					),
				})}
				saveBtn={
					<StatusButton
						status={isPending ? 'pending' : (form.status ?? 'idle')}
						disabled={isPending}
						size="sm"
						onClick={handleContinue}
						type="button"
						form={FORM_NAME}
					>
						Continue
					</StatusButton>
				}
				cancelBtn={
					<Button onClick={handleCancel} variant="outline" size="sm">
						Cancel
					</Button>
				}
			>
				<Form method="POST" {...getFormProps(form)} encType="multipart/form-data" className="w-full">
					<input
						type="text"
						name={fields.companyId.name}
						value={fields.companyId.value}
						onChange={() => {}}
						className="hidden"
					/>
					<div className="flex flex-col gap-6">
						<FormStepper
							active={step}
							steps={[
								{ name: FORM_STEPS.First, label: 'Import file' },
								{ name: FORM_STEPS.Second, label: 'Select ecosystems' },
								{ name: FORM_STEPS.Third, label: 'Additional info' },
							]}
						/>
						<div className={clsx('flex flex-col gap-6', step !== FORM_STEPS.First && 'hidden')}>
							<RadioGroup
								inputProps={{
									...getSelectProps(fields.type),
									defaultValue: FILE_TYPES.Accounts,
									key: undefined,
								}}
								options={[
									{
										value: FILE_TYPES.Accounts,
										label: 'Accounts',
										icon: 'portfolio',
									},
									{
										value: FILE_TYPES.AccountsAndContacts,
										label: 'Accounts + Contacts',
										icon: 'user-multiple',
									},
								]}
								errors={fields.type.errors}
								onChange={e =>
									form.update({
										name: fields.type.name,
										value: e,
									})
								}
							/>
							<div className={cn('flex flex-col gap-1', fields.type.value !== FILE_TYPES.Accounts && 'hidden')}>
								<SwitchField
									labelProps={{
										children: (
											<>
												<span>Use Contact Finder tool (beta)</span>
												<ProductTip
													className="ml-1 text-neutral-3-fg"
													content="Select this to launch Contact Finder that will find contacts for accounts according to your IBP mapping."
												/>
											</>
										),
									}}
									switchProps={{
										...getInputProps(fields.contactFinderEnabled, {
											type: 'checkbox',
										}),
									}}
								/>
							</div>
							<FileInput
								accept={{ 'text/csv': ['.csv'] }}
								labelProps={{ children: 'Import CSV' }}
								inputProps={{
									...getInputProps(fields.file, { type: 'file' }),
								}}
								errors={fields.file.errors}
								onValueChange={files => {
									if (!files.length) {
										return
									}
									form.update({
										name: fields.title.name,
										value: files[0].name,
										validated: false,
									})
								}}
							/>
							<div className="flex flex-row items-center gap-6">
								<p className="flex-1 text-body-sm text-neutral-3-fg">
									{`Use ${fields.type.value === FILE_TYPES.Accounts ? 'accounts' : 'accounts and contacts'} import template with predefined columns, and plug in your data. `}
									<a
										href="https://docs.evergrowth.com/platform/organize-guides/lead-research-agent-launcher/how-to-use-the-lead-research-agent-launcher"
										target="_blank"
										rel="noreferrer"
										className="text-brand-1-fg underline"
									>
										Learn more
									</a>
								</p>
								<Button
									onClick={() => handleDownload(fields.type.value as FILE_TYPES)}
									type="button"
									variant="ghost"
									size="sm"
									disabled={downloadFetcher.state !== 'idle'}
								>
									Download template
								</Button>
							</div>
						</div>
						<div className={clsx('flex flex-col gap-6', step !== FORM_STEPS.Second && 'hidden')}>
							<CheckboxTreeViewWithSearch
								inputProps={{
									name: fields.verticals.name,
									defaultValue: fields.verticals.value as string[],
									onChange: e =>
										form.update({
											name: fields.verticals.name,
											value: e,
										}),
								}}
								errors={fields.verticals.errors}
								searchPlaceholder="Find verticals"
								options={ecosystems.map(ecosystem => ({
									id: String(ecosystem.id),
									label: ecosystem.name,
									children: ecosystem.verticals.map(vertical => ({
										id: String(vertical.id),
										label: vertical.name,
									})),
								}))}
							/>
						</div>
						<div className={clsx('flex flex-col gap-6', step !== FORM_STEPS.Third && 'hidden')}>
							<Field
								labelProps={{ children: 'Name' }}
								inputProps={{
									...getInputProps(fields.title, { type: 'text' }),
									placeholder: 'Enter job name',
								}}
								errors={fields.title.errors}
							/>
							<TextareaField
								labelProps={{
									children: 'Comment',
								}}
								textareaProps={{
									...getTextareaProps(fields.comment),
									placeholder: 'You can type extra notes about your job',
									rows: 10,
								}}
								errors={fields.comment.errors}
							/>
						</div>
						<ErrorList errors={form.errors} id={form.errorId} />
					</div>
				</Form>
			</DialogContent>
		</DialogRoot>
	)
}

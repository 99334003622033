import * as ToastPrimitive from '@radix-ui/react-toast'
import { useState, useEffect, type ReactNode } from 'react'
import { Icon, type IconName } from '#src/components/ui/icon'
import { type ClassName } from '#src/types/styles'
import { cn } from '#src/utils/misc'
import { generateUniqueId } from '#src/utils/randoms'

type ToastType = 'success' | 'warning' | 'error' | 'custom'

type CustomToastOptions = {
	iconName: IconName
	iconClassName?: ClassName
}

type ToastOptions = {
	message: string
	duration?: number
	position?: 'top' | 'bottom'
	type: ToastType
} & (({ type: Exclude<ToastType, 'custom'> } & Partial<CustomToastOptions>) | ({ type: 'custom' } & CustomToastOptions))

type ToastID = string

type ToastItem = Omit<ToastOptions, 'type'> & { id: ToastID }

const toastRef = {
	current: null as null | ((options: ToastOptions) => void),
}

export const showToast = (options: ToastOptions) => {
	if (toastRef.current) {
		toastRef.current(options)
	} else {
		console.warn('ToastProvider is not mounted.')
	}
}

const ICON_VARIANTS: Record<Exclude<ToastType, 'custom'>, IconName> = {
	success: 'checkmark-filled',
	warning: 'warning-alt-filled',
	error: 'error-filled',
} as const

const ICON_COLOR_VARIANTS: Record<Exclude<ToastType, 'custom'>, ClassName> = {
	success: 'text-green-70',
	warning: 'text-status-warning-fg',
	error: 'text-status-danger-fg',
} as const

export const ToastProvider = ({ children }: { children: ReactNode }): JSX.Element => {
	const [toasts, setToasts] = useState<ToastItem[]>([])
	const [viewportPosition, setViewportPosition] = useState<'top' | 'bottom'>('bottom') // State to store viewport position

	const showToastInternal = ({
		message,
		iconName,
		iconClassName,
		duration = 5000,
		position = 'bottom',
		type,
	}: ToastOptions): void => {
		const id = generateUniqueId()
		const newToast: ToastItem = {
			id,
			message,
			iconName: iconName ?? ICON_VARIANTS[type],
			iconClassName: iconClassName ?? (type !== 'custom' ? ICON_COLOR_VARIANTS[type] : undefined),
			duration,
			position,
		}
		setViewportPosition(position)
		setToasts(prevToasts => [...prevToasts, newToast])
	}

	useEffect(() => {
		toastRef.current = showToastInternal
		return () => {
			toastRef.current = null
		}
	}, [])

	const removeToast = (id: string): void => {
		setToasts(prevToasts => prevToasts.filter(toast => toast.id !== id))
	}

	return (
		<ToastPrimitive.Provider>
			{children}
			<ToastPrimitive.Provider>
				{toasts.map(toast => (
					<ToastPrimitive.Root
						key={toast.id}
						duration={toast.duration}
						onOpenChange={open => {
							if (!open) {
								removeToast(toast.id)
							}
						}}
						className="rounded bg-[#393939] p-4 text-body-md text-neutral-inverse-fg shadow radix-state-closed:animate-out radix-state-closed:slide-out-to-right radix-state-open:animate-in radix-state-open:slide-in-from-right radix-swipe-cancel:translate-x-0 radix-swipe-end:animate-out radix-swipe-end:slide-out-to-right radix-swipe-move:translate-x-[var(--radix-toast-swipe-move-x)]"
					>
						<ToastPrimitive.Description className="flex items-center gap-3">
							{toast.iconName && <Icon name={toast.iconName} size="md" className={toast.iconClassName} />}
							{toast.message}
						</ToastPrimitive.Description>
					</ToastPrimitive.Root>
				))}
				<ToastPrimitive.Viewport
					className={cn(
						'fixed z-[10000] m-0 flex w-max list-none flex-col gap-2.5 p-6',
						viewportPosition === 'top' ? 'right-0 top-0' : 'bottom-0 right-0',
					)}
				/>
			</ToastPrimitive.Provider>
		</ToastPrimitive.Provider>
	)
}

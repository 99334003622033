import { useQuery, type QueryClient } from '@tanstack/react-query'
import { type LoaderFunctionArgs, useLoaderData } from 'react-router-dom'
import { avatarVariantByPersonaType } from '#src/components/chat/avatar'
import ChatInput from '#src/components/chat/input'
import ChatLayout from '#src/components/chat/layout'
import Message from '#src/components/chat/message'
import Resolved from '#src/components/chat/resolved'
import { Logo } from '#src/components/ui/logo'
import { PageLayout } from '#src/components/ui/PageLayout'
import { userQuery } from '#src/routes/auth/queries'
import { checkIsAdminSession, checkIsUserSession } from '#src/utils/misc'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import { useSendMessageMutation } from '../mutations'
import { chatQuery, chatsQuery, conversationQuery } from '../queries'
import { streamMessageQuery } from '../stream'

export type AidtLoaderResponse = Awaited<ReturnType<ReturnType<typeof loader>>>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const user = await queryClient.fetchQuery(userQuery())

		if (!checkIsUserSession(user.roles) && !checkIsAdminSession(user.roles)) {
			throw new Response('Page Not Found', {
				status: 404,
				statusText: 'Not Found',
			})
		}

		const chats = await queryClient.fetchQuery(chatsQuery(params.companyId))

		if (!params.conversationId)
			return {
				handle: {
					companyId: params.companyId,
					conversationId: undefined,
					conversationData: null,
				},
				conversationStatus: null,
				chats,
				user,
			}

		const conversationData = await queryClient.fetchQuery(conversationQuery(params.companyId, params.conversationId))

		return {
			handle: {
				companyId: params.companyId,
				conversationId: params.conversationId,
				conversationData,
			},
			conversationStatus: conversationData?.conversation.status,
			chats,
			user,
		}
	}

export default function Aidt() {
	const params = useParsedRouteParams(['companyId', 'conversationId'])
	const {
		user,
		handle: { conversationData },
	} = useLoaderData() as AidtLoaderResponse

	if (!conversationData) {
		throw new Error('Missing conversationData')
	}

	const { send, status } = useSendMessageMutation({
		companyId: params.companyId,
		conversationId: params.conversationId,
	})

	const { data: chat } = useQuery(chatQuery(params.companyId, params.conversationId))

	if (['crashed'].includes(conversationData?.conversation.status)) {
		return <ChatCrashed />
	}

	if (!['ready', 'closed', 'unread'].includes(conversationData?.conversation.status)) {
		return <ChatPending />
	}

	return (
		<PageLayout>
			<ChatLayout
				key={`${params.conversationId}-${status === 'pending' ? 'pending' : 'default'}`}
				containerClassName={!chat?.length ? 'justify-center' : undefined}
				status={conversationData?.conversation.status === 'closed' ? <Resolved /> : undefined}
				content={
					<>
						{(chat || []).map(message => (
							<Message
								key={message.id}
								message={{
									id: message.id,
									message: message.message,
									author: {
										name: message.isAi ? conversationData.conversation.name : message.author,
										avatarVariant: avatarVariantByPersonaType(conversationData.conversation.type),
										showAuthorName: message.isAi || (checkIsAdminSession(user.roles) && !conversationData.writable),
									},
									status: message.status,
									isReply: message.isAi,
								}}
							/>
						))}

						<StreamedMessage />
					</>
				}
				footer={
					conversationData.writable && conversationData?.conversation?.status !== 'closed' ? (
						<ChatInput send={send} disabled={status === 'pending'} />
					) : undefined
				}
			/>
		</PageLayout>
	)
}

function StreamedMessage() {
	const params = useParsedRouteParams(['companyId', 'conversationId'])
	const {
		handle: { conversationData },
	} = useLoaderData() as AidtLoaderResponse

	if (!conversationData) {
		throw new Error('Missing parameters')
	}

	const { data: streamedMessage } = useQuery(
		streamMessageQuery({
			companyId: params.companyId,
			conversationId: params.conversationId,
		}),
	)

	if (!streamedMessage) return null

	return (
		<Message
			message={{
				id: streamedMessage.id,
				message: streamedMessage.message,
				author: {
					name: conversationData.conversation.name,
					avatarVariant: avatarVariantByPersonaType(conversationData.conversation.type),
					showAuthorName: true,
				},
				status: streamedMessage.status,
				isReply: streamedMessage.isAi,
			}}
		/>
	)
}

function ChatPending() {
	return (
		<div className="relative flex h-full w-full flex-col items-center justify-center gap-4 overflow-hidden">
			<div className="relative flex flex-col items-center justify-center gap-4">
				<div className="pointer-events-none absolute inset-0 flex animate-pulsing-rings-1 select-none flex-col items-center justify-center opacity-0">
					<div className="min-h-[670px] w-[42.5%] min-w-[670px] rounded-full border border-[#0A1E7A] bg-transparent pb-[42.5%] opacity-[0.06]" />
				</div>
				<div className="pointer-events-none absolute inset-0 flex animate-pulsing-rings-2 select-none flex-col items-center justify-center opacity-0">
					<div className="min-h-[500px] w-[32.5%] min-w-[500px] rounded-full border border-[#0A1E7A] bg-transparent pb-[32.5%] opacity-[0.09]" />
				</div>
				<div className="pointer-events-none absolute inset-0 flex animate-pulsing-rings-3 select-none flex-col items-center justify-center opacity-0">
					<div className="min-h-[330px] w-[22.5%] min-w-[330px] rounded-full border border-[#0A1E7A] bg-transparent pb-[22.5%] opacity-[0.14]" />
				</div>
				<Logo size="xl" type="symbol" />
				<p className="text-title-md text-neutral-1-fg">Initializing...</p>
			</div>
		</div>
	)
}

function ChatCrashed() {
	return (
		<div className="relative flex h-full w-full flex-col items-center justify-center gap-4 overflow-hidden">
			<Logo size="xl" type="symbol" />
			<h1 className="text-heading-sm text-neutral-1-fg">Ouch!</h1>
			<section className="flex flex-col items-center justify-center gap-1 text-center">
				<p className="text-title-sm text-neutral-1-fg">There was an issue with the chat.</p>
				<p className="text-body-sm font-normal text-neutral-1-fg">
					Our team is aware and working to fix the issue — apologies for any inconvenience.
				</p>
			</section>
		</div>
	)
}

import { type QueryClient } from '@tanstack/react-query'
import { type LoaderFunctionArgs } from 'react-router-dom'
import { userQuery } from '#src/routes/auth/queries'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem/queries'
import { scenarioTemplatesQuery, voicesQuery } from '#src/routes/enable/roleplay/scenario/queries'
import { checkIsReadOnlySession } from '#src/utils/misc'

export type NewRoleplayScenarioWizardLoaderResponse = Awaited<
	ReturnType<ReturnType<typeof newRoleplayScenarioWizardLoader>>
>

export const newRoleplayScenarioWizardLoader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const user = await queryClient.fetchQuery(userQuery())
		const readOnlySession = checkIsReadOnlySession(user.roles)

		if (readOnlySession) {
			throw new Response('Page Not Found', {
				status: 404,
				statusText: 'Not Found',
			})
		}

		const ecosystems = await queryClient.fetchQuery(ecosystemsQuery(params.companyId))
		const scenarioTemplates = await queryClient.fetchQuery(scenarioTemplatesQuery(params.companyId))
		const voices = await queryClient.fetchQuery(voicesQuery(params.companyId))

		if (!scenarioTemplates.length)
			throw new Response('No scenario templates provided', {
				status: 400,
				statusText: 'Bad Request',
			})

		return {
			companyId: params.companyId,
			conversationId: params.conversationId,
			ecosystems,
			scenarioTemplates,
			voices,
		}
	}

import { type QueryClient } from '@tanstack/react-query'
import { type ActionFunctionArgs } from 'react-router-dom'
import DeleteConfirm from '#src/components/delete-confirm'
import { Icon } from '#src/components/ui/icon'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import { deleteGlossaryTerm } from './mutations'
import { glossaryKeys } from './queries'

export type DeleteGlossaryTermActionResponse = Awaited<ReturnType<ReturnType<typeof action>>>

export const action =
	(queryClient: QueryClient) =>
	async ({ params }: ActionFunctionArgs) => {
		if (!params.companyId || !params.termId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		await deleteGlossaryTerm(params.companyId, params.termId)

		await queryClient.invalidateQueries({
			queryKey: glossaryKeys.all,
		})

		return null
	}

export function DeleteTermAsyncForm({ termId }: { termId: number }) {
	const params = useParsedRouteParams(['companyId'])
	const action = routes.enable.glossary.delete({
		companyId: params.companyId,
		termId: termId.toString(),
	})

	return (
		<DeleteConfirm
			trigger={
				<button className="inline-flex items-center justify-center">
					<Icon name="trash" size="font" />
				</button>
			}
			action={action}
			title="Delete term?"
			description="This action cannot be undone. This will permanently delete the term."
		/>
	)
}

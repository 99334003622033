import { useQuery, type QueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useLoaderData, type LoaderFunctionArgs, Link, Outlet, useNavigate } from 'react-router-dom'
import { z } from 'zod'
import { EmptyStateCard } from '#src/components'
import AiGenerated from '#src/components/ai-generated'
import Markdown from '#src/components/markdown'
import Priority from '#src/components/priority'
import ProductTip from '#src/components/product-tip'
import Status from '#src/components/status'
import Table, { type TableDataGroup } from '#src/components/table'
import { Icon } from '#src/components/ui/icon'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '#src/components/ui/tooltip'
import { userQuery } from '#src/routes/auth/queries'
import { UserSchema } from '#src/routes/auth/schema'
import { criteriaQuery, dataPointsQuery, ecosystemsQuery } from '#src/routes/calibrate/ecosystem/queries'
import {
	CriteriaAPISchema,
	DataPointsAPISchema,
	EcosystemAPISchema,
	EcosystemListAPISchema,
} from '#src/routes/calibrate/ecosystem/schema'
import { PersonaList } from '#src/routes/calibrate/verticals/components/PersonaList'
import { PersonaListHeader } from '#src/routes/calibrate/verticals/components/PersonaListHeader'
import { PersonasCheckboxGroupProvider } from '#src/routes/calibrate/verticals/context/PersonasCheckboxGroupContext'
import { checkIsReadOnlySession, cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { autofocusFieldMutation, autofocusFieldQuery, autofocusVerticalQuery } from './autofocus'
import { DeleteVerticalAsyncForm } from './delete'
import { verticalsQuery } from './queries'

export type VerticalLoaderResponse = Awaited<ReturnType<ReturnType<typeof loader>>>

export const VerticalsLoaderResponseSchema = z.object({
	handle: z.object({
		companyId: z.string(),
		ecosystemId: z.string().nullable().optional(),
		ecosystemName: z.string(),
		readOnlySession: z.boolean(),
	}),
	ecosystem: EcosystemAPISchema,
	ecosystems: EcosystemListAPISchema,
	criteria: CriteriaAPISchema,
	datapoints: DataPointsAPISchema,
	user: UserSchema,
})

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId || !params.ecosystemId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		void queryClient.prefetchQuery({
			...verticalsQuery(params.companyId, Number(params.ecosystemId)),
		})

		const user = await queryClient.fetchQuery(userQuery())
		const ecosystems = await queryClient.fetchQuery(ecosystemsQuery(params.companyId))
		const ecosystem = ecosystems?.find(e => e.id === Number(params.ecosystemId))
		const criteria = await queryClient.fetchQuery(criteriaQuery(params.companyId, params.ecosystemId))
		const datapoints = await queryClient.fetchQuery(dataPointsQuery(params.companyId, params.ecosystemId))

		return {
			handle: {
				companyId: params.companyId,
				ecosystemId: params.ecosystemId,
				ecosystemName: ecosystem?.name,
				readOnlySession: checkIsReadOnlySession(user.roles),
			},
			ecosystem,
			ecosystems,
			criteria,
			datapoints,
			user,
		}
	}

export default function Vertical() {
	const {
		handle: { companyId, ecosystemId, readOnlySession },
		datapoints,
		criteria,
		ecosystem,
	} = useLoaderData() as VerticalLoaderResponse

	const navigate = useNavigate()
	const { data: autofocus } = useQuery(autofocusFieldQuery())
	const { data: autofocusVertical } = useQuery(autofocusVerticalQuery())

	// NOTE: this will scroll into view if the board
	// is very wide given many verticals
	useEffect(() => {
		if (autofocusVertical) {
			setTimeout(() => {
				const element = document.getElementById(`vertical-column-${autofocusVertical}`)

				element?.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'center',
				})
			}, 100)
		}
	}, [autofocusVertical])

	const { data: verticals } = useQuery({
		...verticalsQuery(companyId, Number(ecosystemId)),
		refetchInterval: ({ state: { data } }) => {
			if (data?.some(vertical => vertical.prompt.status && ['Pending', 'In Progress'].includes(vertical.prompt.status)))
				return 4000
			else return false
		},
		refetchIntervalInBackground: true,
		throwOnError: true,
	})

	const data = verticals?.length
		? {
				head: {
					options: { sticky: true },
					focus: autofocus?.includes('name'),
					columns: [
						{ children: '' },
						...verticals.map(vertical => ({
							children: (
								<section className="flex w-full flex-col gap-1">
									<div className="flex w-full items-center justify-between gap-2">
										{readOnlySession ? (
											<p className="truncate text-title-sm">{vertical.name}</p>
										) : (
											<Link
												replace
												preventScrollReset
												className="grid grid-cols-[1fr,max-content] grid-rows-1 items-center gap-1"
												id={`vertical-column-${vertical.id ?? ''}`}
												to={routes.calibrate.verticals.edit({
													companyId: companyId,
													ecosystemId: ecosystemId,
													verticalId: vertical.id.toString(),
												})}
												onClick={() => autofocusFieldMutation('name')}
											>
												<p className="w-full truncate text-title-sm" title={vertical.name}>
													{vertical.name}
												</p>
												<Icon name="edit" className="inline-block text-title-sm" />
											</Link>
										)}
										{readOnlySession ? null : (
											<div className="flex items-center justify-between gap-2">
												<Link
													replace
													preventScrollReset
													className="flex items-center gap-1 text-title-sm"
													to={routes.calibrate.verticals.duplicate({
														companyId: companyId,
														ecosystemId: ecosystemId,
														verticalId: vertical.id.toString(),
													})}
													onClick={() => autofocusFieldMutation('name')}
												>
													<Icon name="copy" className="text-title-sm" />
												</Link>
												<DeleteVerticalAsyncForm ecosystemId={Number(ecosystemId)} verticalId={String(vertical.id)}>
													<Icon name="trash" className="text-title-sm" />
												</DeleteVerticalAsyncForm>
											</div>
										)}
									</div>
									<div className="flex items-center gap-2">
										<Status status={vertical.status} />
										<Priority priority={vertical.priority} />
									</div>
								</section>
							),
						})),
					],
				},
				bodyGroups: [
					{
						provider: PersonasCheckboxGroupProvider,
						providerProps: { verticals: ecosystem?.verticals },
						collapsible: {
							options: {
								sticky: true,
								collapsible: true,
								defaultCollapsed: true,
							},
							columns: [
								{
									children: (
										<p className="flex items-center gap-1 text-body-md font-semibold text-neutral-1-fg">
											Personas
											<span className="font-normal text-neutral-3-fg">
												{ecosystem?.verticals.reduce(
													(totalPersonaCount, vertical) =>
														totalPersonaCount + (vertical.personas?.length ? vertical.personas.length : 0),
													0,
												) ?? 0}
											</span>
											<ProductTip content="Personas: Personas are created using a chain of AI prompts that process data from market research and real customer information, including demographics, job roles, and industry needs. These personas refine the model, guiding pain point generation, persona mapping, and sales coach modeling. To add or edit a persona, click “+ Add” in the personas section, complete the “Create Persona” form, then adjust pain point details and AI settings for accuracy." />
										</p>
									),
								},
								...verticals.map(vertical => ({
									children: (
										<PersonaListHeader
											key={vertical.id}
											vertical={vertical}
											ecosystem={ecosystem}
											companyId={companyId}
											ecosystemId={ecosystemId}
											readOnlySession={readOnlySession}
										/>
									),
								})),
							],
						},
						body: {
							rows: [
								{
									columns: [
										{
											children: '',
										},
										...verticals.map(vertical => {
											const v = ecosystem?.verticals.find(v => v.id === vertical.id)

											if (!v?.personas?.length || !ecosystem)
												return {
													className: 'align-middle py-4',
													children: (
														<section className="flex w-full flex-col items-center justify-center gap-2">
															<Icon name="user-avatar" size="lg" className="text-neutral-3-fg" />
															<p className="text-center text-body-sm font-normal text-neutral-1-fg">
																You don&apos;t have any personas in <br />
																<span className="font-semibold">{vertical.name}</span>
															</p>
															{readOnlySession ? null : (
																<Link
																	to={routes.calibrate.persona.edit({
																		companyId: companyId,
																		ecosystemId: ecosystemId.toString(),
																		verticalId: vertical.id.toString(),
																		personaId: null,
																	})}
																	className="flex flex-nowrap items-center gap-1 bg-transparent py-2 text-button-sm text-link hover:text-link-hover active:text-link-pressed"
																>
																	<Icon name="add" /> Add Persona
																</Link>
															)}
														</section>
													),
												}

											return {
												className: 'align-top',
												children: (
													<section className="flex w-full flex-col items-start justify-start gap-2">
														<PersonaList
															personas={v.personas}
															companyId={companyId}
															ecosystemId={ecosystemId}
															verticalId={v.id.toString()}
														/>
													</section>
												),
											}
										}),
									],
								},
							],
						},
					},
					{
						collapsible: {
							options: { sticky: true, collapsible: criteria?.length > 0 },
							columns: [
								{
									children: (
										<section className="flex w-full items-center justify-between">
											<p className="flex items-center gap-1 text-body-md font-semibold text-neutral-1-fg">
												Qualification Criteria
												<span className="font-normal text-neutral-3-fg">{criteria?.length ?? 0}</span>
												<ProductTip content="Qualification Criteria: The specific characteristics and attributes used to define and segment your personas based on market research and real customer data." />
											</p>
											{readOnlySession ? null : (
												<Link
													to={routes.calibrate.criteria.edit({
														companyId: companyId,
														ecosystemId: ecosystemId,
													})}
													className="text-button-sm text-link hover:text-link-hover active:text-link-pressed"
												>
													Edit
												</Link>
											)}
										</section>
									),
								},
								...verticals.map(() => ({
									className: 'border-r-transparent',
									children: '',
								})),
							],
						},
						...(criteria?.length
							? {
									body: {
										id: 'group-4',
										rows: criteria.map((criterion, index) => ({
											focus: autofocus?.includes(`answers[${index}].value`),
											columns: [
												{
													className: 'align-top',
													children: (
														<>
															{criterion.dataSources ? (
																<TooltipProvider>
																	<Tooltip delayDuration={0}>
																		<TooltipTrigger className="h-full w-full text-left">
																			<div className="absolute right-0 top-0 h-0 w-0 border-b-0 border-l-[15px] border-t-[13px] border-l-transparent border-t-brand-2-bd" />
																			<p className="whitespace-pre-line text-body-md font-medium text-neutral-1-fg">
																				{criterion.question}
																			</p>
																		</TooltipTrigger>
																		<TooltipContent side="right" sideOffset={16} align="center" className="max-w-60">
																			AI Agent instructions:&nbsp;
																			{criterion.dataSources}
																		</TooltipContent>
																	</Tooltip>
																</TooltipProvider>
															) : (
																<p className="whitespace-pre-line text-body-md font-medium text-neutral-1-fg">
																	{criterion.question}
																</p>
															)}
														</>
													),
												},
												...verticals.map(vertical => ({
													...(readOnlySession
														? {}
														: {
																onClick: () => {
																	autofocusFieldMutation(`answers[${index}].value`)
																	navigate(
																		routes.calibrate.verticals.edit({
																			companyId: companyId,
																			ecosystemId: ecosystemId,
																			verticalId: vertical.id.toString(),
																		}),
																		{ replace: true, preventScrollReset: true },
																	)
																},
															}),
													className: 'align-top',
													children: (
														<p className="text-body-md font-normal text-neutral-2-fg">
															{vertical.answers?.find(answer => answer.criterionId === criterion.id)?.value}
														</p>
													),
												})),
											],
										})),
									},
								}
							: {}),
					},
					{
						collapsible: {
							options: { sticky: true, collapsible: true },
							columns: [
								{
									children: (
										<section className="flex w-full items-center justify-between">
											<p className="flex items-center gap-1 text-body-md font-semibold text-neutral-1-fg">
												Enrichment Criteria
												<span className="font-normal text-neutral-3-fg">{datapoints?.length ?? 0}</span>
												<ProductTip content="Additional data to be collected about accounts that pass qualification." />
											</p>
											{readOnlySession ? null : (
												<Link
													to={routes.calibrate.datapoints.edit({
														companyId: companyId,
														ecosystemId: ecosystemId,
													})}
													className="text-button-sm text-link hover:text-link-hover active:text-link-pressed"
												>
													Edit
												</Link>
											)}
										</section>
									),
								},
								...verticals.map(() => ({
									className: 'border-r-transparent',
									children: '',
								})),
							],
						},
						...(datapoints?.length
							? {
									body: {
										id: 'group-6',
										rows: datapoints.map((datapoint, index) => ({
											focus: autofocus?.includes(`dataPoints[${index}].value`),
											columns: [
												{
													className: 'align-top',
													children: (
														<>
															{datapoint.dataSources ? (
																<TooltipProvider>
																	<Tooltip delayDuration={0}>
																		<TooltipTrigger className="h-full w-full text-left">
																			<div className="absolute right-0 top-0 h-0 w-0 border-b-0 border-l-[15px] border-t-[13px] border-l-transparent border-t-brand-2-bd" />
																			<p className="whitespace-pre-line text-body-md font-medium text-neutral-1-fg">
																				{datapoint.title}
																			</p>
																		</TooltipTrigger>
																		<TooltipContent side="right" sideOffset={16} align="center" className="max-w-60">
																			AI Agent instructions:&nbsp;
																			{datapoint.dataSources}
																		</TooltipContent>
																	</Tooltip>
																</TooltipProvider>
															) : (
																<p className="whitespace-pre-line text-body-md font-medium text-neutral-1-fg">
																	{datapoint.title}
																</p>
															)}
														</>
													),
												},
												...verticals.map(vertical => ({
													...(readOnlySession
														? {}
														: {
																onClick: () => {
																	autofocusFieldMutation(`dataPoints[${index}].value`)
																	navigate(
																		routes.calibrate.verticals.edit({
																			companyId: companyId,
																			ecosystemId: ecosystemId,
																			verticalId: vertical.id.toString(),
																		}),
																		{ replace: true, preventScrollReset: true },
																	)
																},
															}),
													className: 'align-top',
													children: (
														<p className="text-body-md font-normal text-neutral-2-fg">
															{vertical.dataPoints?.find(item => item.id === datapoint.id)?.value}
														</p>
													),
												})),
											],
										})),
									},
								}
							: {}),
					},
					{
						collapsible: {
							options: {
								sticky: true,
								collapsible: true,
							},
							columns: [
								{
									children: (
										<p className="flex items-center gap-1 text-body-md font-semibold text-neutral-1-fg">
											Business model description <AiGenerated />
											<ProductTip content="Business Model Description: The business model description is created using the vertical’s criteria values. This data helps the AI to generate detailed descriptions of the business model for each vertical. These descriptions are crucial for identifying pain points and mapping personas accurately. To improve or fine-tune these descriptions, you can adjust the vertical’s criteria values, which will enhance the precision and relevance of the generated business model descriptions." />
										</p>
									),
								},
								...verticals.map(vertical => ({
									children: (
										<p
											className={cn(
												'truncate text-body-sm font-normal',
												['Pending', 'In Progress'].includes(vertical.prompt.status ?? '') ? 'animate-pulse' : '',
												vertical.prompt.status === 'Crashed' ? 'text-status-danger-fg' : 'text-neutral-2-fg',
											)}
										>
											{['Pending', 'In Progress'].includes(vertical.prompt.status ?? '')
												? vertical.bizDescription
													? 'Regenerating...'
													: 'Generating...'
												: vertical.prompt.status === 'Crashed'
													? `Failed to ${vertical.bizDescription ? 'regenerate' : 'generate'}`
													: vertical.bizDescription}
										</p>
									),
								})),
							],
						},
						body: {
							rows: [
								{
									columns: [
										{
											className: 'align-top',
											static: true,
											children: <p className="text-body-md font-medium text-neutral-1-fg">Description</p>,
										},
										...verticals.map(vertical => {
											return {
												className: 'align-top',
												static: true,
												children: <Markdown className="text-neutral-2-fg">{vertical.bizDescription}</Markdown>,
											}
										}),
									],
								},
								{
									focus: autofocus?.includes('bizDescriptionOverride'),
									columns: [
										{
											className: 'align-top',
											children: (
												<p className="flex items-center gap-1 text-body-md font-medium text-neutral-1-fg">
													Business model description (manual)
													<ProductTip content="Business model description (manual): A manually inputted summary of the business model to be incorporated into the AI-generated description above. This information is used to calibrate and refine the model." />
												</p>
											),
										},
										...verticals.map(vertical => {
											return {
												...(readOnlySession
													? {}
													: {
															onClick: () => {
																autofocusFieldMutation('bizDescriptionOverride')
																navigate(
																	routes.calibrate.verticals.edit({
																		companyId: companyId,
																		ecosystemId: ecosystemId,
																		verticalId: vertical.id.toString(),
																	}),
																	{ replace: true, preventScrollReset: true },
																)
															},
														}),
												className: 'align-top',
												children: (
													<p className="whitespace-pre-line text-body-md font-normal text-neutral-2-fg">
														{vertical.bizDescriptionOverride}
													</p>
												),
											}
										}),
									],
								},
								{
									focus: autofocus?.includes('bizModelLen'),
									columns: [
										{
											className: 'align-top',
											children: (
												<p className="text-body-md font-medium text-neutral-1-fg">Description length (# of words)</p>
											),
										},
										...verticals.map(vertical => {
											return {
												...(readOnlySession
													? {}
													: {
															onClick: () => {
																autofocusFieldMutation('bizModelLen')
																navigate(
																	routes.calibrate.verticals.edit({
																		companyId: companyId,
																		ecosystemId: ecosystemId,
																		verticalId: vertical.id.toString(),
																	}),
																	{ replace: true, preventScrollReset: true },
																)
															},
														}),
												className: 'align-top',
												children: (
													<p className="text-body-md font-normal text-neutral-2-fg">
														{Intl.NumberFormat('en').format(Number(vertical.bizModelLen))}
													</p>
												),
											}
										}),
									],
								},
							],
						},
					},
					{
						collapsible: {
							options: { sticky: true, collapsible: true },
							columns: [
								{
									children: <p className="text-body-md font-semibold text-neutral-1-fg">Notes</p>,
								},
								...verticals.map(() => ({
									className: 'border-r-transparent',
									children: '',
								})),
							],
						},
						body: {
							id: 'group-5',
							rows: [
								{
									focus: autofocus?.includes('listBuildingNotes'),
									columns: [
										{
											className: 'align-top',
											children: (
												<p className="flex items-center gap-1 text-body-md font-normal text-neutral-2-fg">
													List building notes
													<ProductTip content="List building notes: Provide guidelines, platforms, tools, and resources for effective list building and ICP mapping." />
												</p>
											),
										},
										...verticals.map(vertical => ({
											...(readOnlySession
												? {}
												: {
														onClick: () => {
															autofocusFieldMutation('listBuildingNotes')
															navigate(
																routes.calibrate.verticals.edit({
																	companyId: companyId,
																	ecosystemId: ecosystemId,
																	verticalId: vertical.id.toString(),
																}),
																{ replace: true, preventScrollReset: true },
															)
														},
													}),
											className: 'align-top',
											children: (
												<p className="whitespace-pre-line text-body-md font-normal text-neutral-2-fg">
													{vertical.listBuildingNotes}
												</p>
											),
										})),
									],
								},
								{
									focus: autofocus?.includes('otherNotes'),
									columns: [
										{
											className: 'align-top',
											children: (
												<p className="flex items-center gap-1 text-body-md font-normal text-neutral-2-fg">
													Other Notes
													<ProductTip content="Other Notes: Additional relevant information or remarks not covered under the above specific sections." />
												</p>
											),
										},
										...verticals.map(vertical => {
											return {
												...(readOnlySession
													? {}
													: {
															onClick: () => {
																autofocusFieldMutation('otherNotes')
																navigate(
																	routes.calibrate.verticals.edit({
																		companyId: companyId,
																		ecosystemId: ecosystemId,
																		verticalId: vertical.id.toString(),
																	}),
																	{ replace: true, preventScrollReset: true },
																)
															},
														}),
												className: 'align-top',
												children: (
													<ol className="flex h-full w-full flex-col gap-2 pl-4">
														{vertical.otherNotes?.map((note, index) => (
															<li key={index} className="list-disc">
																<p className="whitespace-pre-line text-body-md font-normal text-neutral-2-fg">{note}</p>
															</li>
														))}
													</ol>
												),
											}
										}),
									],
								},
							],
						},
					},
				],
			}
		: null

	if (!data) {
		return (
			<main className="flex w-full flex-grow flex-col items-center justify-center px-20">
				<EmptyStateCard
					icon="category"
					title="You don't have any verticals and personas for this ecosystem yet"
					actions={
						!readOnlySession
							? [
									{
										title: 'Add vertical',
										to: routes.calibrate.verticals.create({
											companyId,
											ecosystemId: ecosystemId.toString(),
										}),
									},
								]
							: []
					}
				/>
				<Outlet />
			</main>
		)
	}

	return (
		<main className="w-full flex-grow">
			<section className="flex gap-8">
				<Table key={verticals?.length} data={data as TableDataGroup} />
				<Outlet />
			</section>
		</main>
	)
}

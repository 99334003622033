import { type QueryClient } from '@tanstack/react-query'
import { type RouteObject } from 'react-router-dom'
import CopilotLoader, { loader as copilotLoader } from '#src/routes/enable/copilot'

/**
 * WARNING: This route is used by the Copilot feature.
 * It is referenced in client's CRM's and should not be removed.
 * If need be, please implement a redirect.
 */
export const copilotRouter = (client: QueryClient) =>
	({
		path: '/copilot/:contactId',
		element: <CopilotLoader />,
		loader: copilotLoader(client),
	}) satisfies RouteObject

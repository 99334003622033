import { type QueryClient } from '@tanstack/react-query'
import { type RouteObject } from 'react-router-dom'
import { personasIndexLoader, PersonasIndexView } from '#src/routes/enable/personas/routes'
import {
	personasDetailsLoader,
	type PersonasDetailsLoaderResType,
	PersonasDetailsView,
} from '#src/routes/enable/personas/routes/details'
import { personasRootLoader, type PersonasRootLoaderResType } from '#src/routes/enable/personas/routes/root'
import { PersonasSidebar } from '#src/routes/enable/personas/views/components/index'
import RouteBoundary from '#src/routes/route-boundary'
import { routes } from '#src/utils/routes'

export const personasRouter = (client: QueryClient) =>
	({
		path: 'personas/:verticalId',
		loader: personasRootLoader,
		handle: {
			crumb: (data: PersonasRootLoaderResType) => [
				{
					to: routes.enable.personas.index({
						companyId: data.handle.companyId,
						verticalId: data.handle.verticalId,
					}),
					label: 'Personas',
					icon: 'user-multiple',
				},
			],
		},
		children: [
			{
				index: true,
				id: 'personas-loader',
				element: <PersonasIndexView />,
				errorElement: <RouteBoundary />,
				loader: personasIndexLoader(client),
				handle: {
					layout: {
						enableHeader: true,
						sidePanelContent: <PersonasSidebar />,
						fullWidth: true,
						//sidePanelDefaultExpanded: true,
					},
				},
			},
			{
				path: ':personaId',
				element: <PersonasDetailsView />,
				errorElement: <RouteBoundary />,
				loader: personasDetailsLoader(client),
				handle: {
					layout: {
						enableHeader: true,
						fullWidth: true,
					},
					crumb: (data: PersonasDetailsLoaderResType) => [
						{
							to: routes.enable.personas.details({
								companyId: data.companyId,
								verticalId: data.verticalId,
								personaId: String(data.persona.id),
							}),
							label: data.persona.type,
							icon: 'user',
						},
					],
				},
			},
		],
	}) satisfies RouteObject

import { parseWithZod } from '@conform-to/zod'
import { captureException } from '@sentry/react'
import { type QueryClient } from '@tanstack/react-query'
import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { showToast } from '#src/context/ToastContext'
import { validateLinkedinUrl } from '#src/routes/enable/chat/mutations'
import { createRoleplayScenario } from '#src/routes/enable/roleplay/scenario/mutations'
import { roleplayScenarioKeys } from '#src/routes/enable/roleplay/scenario/queries'
import {
	type CreateScenarioFormSchemaType,
	getActiveNewScenarioWizardSchema,
} from '#src/routes/enable/roleplay/scenario/schema'
import { routes } from '#src/utils/routes'

export const newRoleplayScenarioWizardAction =
	(queryClient: QueryClient) =>
	async ({ request, params }: ActionFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const formData = await request.formData()
		const activeStep = formData.get('activeStep') as string
		const activeSchema = getActiveNewScenarioWizardSchema(activeStep)

		const submission = parseWithZod(formData, { schema: activeSchema })

		if (submission.status !== 'success') {
			throw Error('Failed to parse form data')
		}

		try {
			if (activeStep === 'instructions') {
				const fullFormData = JSON.parse(submission.payload.fullFormData as string) as CreateScenarioFormSchemaType
				const payload: CreateScenarioFormSchemaType = {
					...fullFormData,
					...submission.value,
				}

				const res = await createRoleplayScenario(params.companyId, {
					name: payload.name,
					personaId: payload.personaId,
					...(payload.linkedinUrl && { linkedinUrl: payload.linkedinUrl }),
					...(payload.contactId && { contactId: payload.contactId }),
					templateId: payload.templateId,
					personality: payload.personality,
					scenario: payload.scenario,
					evaluation: payload.evaluation,
					instructions: payload.instructions,
				})

				await queryClient.invalidateQueries({
					queryKey: roleplayScenarioKeys.all,
				})

				return redirect(
					routes.enable.roleplay.scenario.index({
						companyId: params.companyId,
						scenarioId: res.id,
					}),
				)
			}
		} catch (err) {
			captureException(err)

			return {
				ok: false,
				result: submission.reply({
					fieldErrors: {
						instructions: ['Unable to finish the scenario setup'],
					},
				}),
				formData,
			}
		}

		try {
			if ('linkedinUrl' in submission.value && submission.value.linkedinUrl) {
				const payload = {
					linkedinUrl: submission.value.linkedinUrl,
					personaId: submission.value.personaId,
				}
				const res = await validateLinkedinUrl(params.companyId, payload)

				showToast({
					message: 'LinkedIn URL verified',
					type: 'success',
					position: 'top',
				})

				return {
					ok: true,
					result: {
						...submission.reply(),
						contactId: res.uuid,
					},
					formData,
				}
			}
		} catch (err) {
			console.error(err)
			return {
				ok: false,
				result: submission.reply({
					fieldErrors: {
						linkedinUrl: ['Unable to verify Linkedin profile'],
					},
				}),
				formData,
			}
		}

		return {
			ok: true,
			result: submission.reply(),
			formData,
		}
	}

import { parseWithZod } from '@conform-to/zod'
import { type QueryClient } from '@tanstack/react-query'
import { type LoaderFunctionArgs } from 'react-router-dom'
import { FILTERS } from '#src/routes/prioritize/constants'
import {
	filterMutation,
	filterMutationHack,
	filterMutationIntentSource,
	filterMutationIntentType,
	filterMutationMappedPersonas,
	filterMutationWeight,
	sortMutation,
} from '#src/routes/prioritize/mutations'
import { filterQuery, sortQuery } from '#src/routes/prioritize/queries'
import {
	FilterFormSchema,
	HackEnumSchema,
	HackSchema,
	IntentSourceEnumSchema,
	IntentSourceSchema,
	IntentTypeEnumSchema,
	IntentTypeSchema,
	MappedPersonasEnumSchema,
	MappedPersonasSchema,
	SortEnumSchema,
	WeightEnumSchema,
	WeightSchema,
} from '#src/routes/prioritize/schema'
import { checkType } from '#src/utils/misc'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action =
	(queryClient: QueryClient) =>
	async ({ params, request }: LoaderFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const formData = await request.formData()

		const submission = parseWithZod(formData, {
			schema: FilterFormSchema,
		})

		if (submission.status !== 'success') {
			throw Error('Failed to parse form data')
		}

		const { intent, filter, value, sort } = submission.value

		if (intent === 'clear-all') {
			Object.values(FILTERS).forEach(filter => {
				filterMutation(filter, null)
			})

			return null
		} else if (intent === 'clear' && filter) {
			filterMutation(filter, null)

			return null
		} else if (intent === 'toggle' && filter && value) {
			const filters = await queryClient.fetchQuery(filterQuery(filter))

			switch (filter) {
				case FILTERS.TYPE:
					if (checkType(IntentTypeSchema, filters) && checkType(IntentTypeEnumSchema, value))
						if (!filters?.includes(value)) {
							filterMutationIntentType(filter, [...(filters ?? []), value])
						} else {
							filterMutationIntentType(
								filter,
								filters.filter(l => l !== value),
							)
						}
					break
				case FILTERS.SOURCE:
					if (checkType(IntentSourceSchema, filters) && checkType(IntentSourceEnumSchema, value))
						if (!filters?.includes(value)) {
							filterMutationIntentSource(filter, [...(filters ?? []), value])
						} else {
							filterMutationIntentSource(
								filter,
								filters.filter(l => l !== value),
							)
						}
					break
				case FILTERS.HACK:
					if (checkType(HackSchema, filters) && checkType(HackEnumSchema, value))
						if (!filters?.includes(value)) {
							filterMutationHack(filter, [...(filters ?? []), value])
						} else {
							filterMutationHack(
								filter,
								filters.filter(l => l !== value),
							)
						}
					break
				case FILTERS.WEIGHT:
					if (checkType(WeightSchema, filters) && checkType(WeightEnumSchema, value))
						if (!filters?.includes(value)) {
							filterMutationWeight(filter, [...(filters ?? []), value])
						} else {
							filterMutationWeight(
								filter,
								filters.filter(l => l !== value),
							)
						}
					break
				case FILTERS.MAPPED_PERSONAS:
					if (checkType(MappedPersonasSchema, filters) && checkType(MappedPersonasEnumSchema, value))
						if (!filters?.includes(value)) {
							filterMutationMappedPersonas(filter, [...(filters ?? []), value])
						} else {
							filterMutationMappedPersonas(
								filter,
								filters.filter(l => l !== value),
							)
						}
					break
				default:
					throw new Error('Invalid filter key')
			}

			return null
		} else if (intent === 'sort') {
			const sortBy = await queryClient.fetchQuery(sortQuery())

			if (checkType(SortEnumSchema, sort)) {
				if (!sortBy || sortBy.key !== sort) {
					sortMutation({ key: sort, direction: 'desc' })
				} else if (sortBy.key === sort && sortBy.direction === 'desc') {
					sortMutation({ key: sort, direction: 'asc' })
				} else if (sortBy?.key === sort && sortBy?.direction === 'asc') {
					sortMutation(null)
				}
			}

			return null
		}

		throw new Response('Missing parameters', {
			status: 400,
			statusText: 'Bad Request',
		})
	}

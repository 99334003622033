import { type RouteObject } from 'react-router-dom'
import AuthLayout from '#src/layouts/AuthLayout'
import { authLoginAction, authLoginLoader, AuthLoginView } from '#src/routes/auth/routes/login'
import { authLogoutLoader } from '#src/routes/auth/routes/logout'
import {
	authResetPasswordAction,
	authResetPasswordLoader,
	AuthResetPasswordView,
} from '#src/routes/auth/routes/reset-password'

export default () =>
	({
		element: <AuthLayout />,
		children: [
			{
				path: '/login',
				element: <AuthLoginView />,
				loader: authLoginLoader,
				action: authLoginAction,
			},
			{
				path: '/logout',
				loader: authLogoutLoader,
			},
			{
				path: '/resetPass/:token',
				element: <AuthResetPasswordView />,
				loader: authResetPasswordLoader,
				action: authResetPasswordAction,
			},
		],
	}) satisfies RouteObject

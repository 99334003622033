import * as DialogPrimitive from '@radix-ui/react-dialog'
import { cva, type VariantProps } from 'class-variance-authority'
import {
	type ComponentPropsWithoutRef,
	type ElementRef,
	forwardRef,
	type ReactNode,
	type MouseEvent,
	isValidElement,
	Fragment,
} from 'react'
import { Link, type To } from 'react-router-dom'
import { Button } from '#src/components/ui/button'
import { StatusButton } from '#src/components/ui/status-button'
import { cn } from '#src/utils/misc'
import { Icon } from './icon'

export const DialogRoot = DialogPrimitive.Root

const dialogContentVariants = cva(
	'm-6 flex max-w-screen-lg flex-col rounded-xl bg-neutral-1-bg shadow-lg animate-in fade-in zoom-in-90',
	{
		variants: {
			size: {
				sm: 'w-[450px]',
				md: 'w-[552px]',
				lg: 'w-[744px]',
			},
		},
		defaultVariants: {
			size: 'md',
		},
	},
)

type DialogButtonProps = {
	label?: string
	to?: To
	onClick?: (e: MouseEvent<HTMLButtonElement>) => void
	formId?: string
	type?: 'cancel' | 'submit'
}

type DialogTriggerProps = ComponentPropsWithoutRef<typeof DialogPrimitive.Trigger>

export const DialogTrigger = forwardRef<ElementRef<typeof DialogPrimitive.Trigger>, DialogTriggerProps>(
	({ children, ...props }, forwardedRef) => (
		<DialogPrimitive.Trigger {...props} ref={forwardedRef}>
			{children}
		</DialogPrimitive.Trigger>
	),
)

DialogTrigger.displayName = 'DialogTrigger'

type DialogHeaderProps = ComponentPropsWithoutRef<'section'> & {
	dialogHeading?: ReactNode
	dialogDescription?: ReactNode
	closeClassName?: string
	onClose?: () => void
	closeTo?: To
}

const DialogHeader = ({
	dialogHeading,
	dialogDescription,
	closeClassName,
	className,
	onClose,
	closeTo,
	...rest
}: DialogHeaderProps) => (
	<section
		className={cn('mb-2 flex grid-rows-1 justify-between border-b border-neutral-1-bd px-8 py-3', className)}
		{...rest}
	>
		<div />
		<div className="text-center">
			{dialogHeading && (
				<DialogPrimitive.Title className="text-title-sm text-neutral-1-fg">{dialogHeading}</DialogPrimitive.Title>
			)}
			{dialogDescription && (
				<DialogPrimitive.Description className="text-body-sm text-neutral-3-fg">
					{dialogDescription}
				</DialogPrimitive.Description>
			)}
		</div>
		<DialogPrimitive.Close asChild className={closeClassName}>
			{closeTo ? (
				<Link to={closeTo}>
					<button className="outline-none">
						<Icon name="cross-1" size="sm" />
					</button>
				</Link>
			) : (
				<button className="outline-none" onClick={onClose}>
					<Icon name="cross-1" size="sm" />
				</button>
			)}
		</DialogPrimitive.Close>
	</section>
)

type DialogFooterProps = ComponentPropsWithoutRef<'footer'> & {
	footerInfo?: ReactNode
	isSubmitting?: boolean
	actions?: (DialogButtonProps | ReactNode)[]
	onClose?: () => void
	closeTo?: To
}

const defaultActions: DialogButtonProps[] = [
	{ label: 'Cancel', type: 'cancel', onClick: () => {} },
	{ label: 'Save', type: 'submit' },
]

const DialogFooter = ({
	footerInfo,
	isSubmitting = false,
	actions = defaultActions,
	className,
	onClose,
	closeTo,
	...rest
}: DialogFooterProps) => {
	const renderedActions = (actions || []).map((action, index) => {
		if (!action) return null

		if (isValidElement(action)) {
			return <Fragment key={index}>{action}</Fragment>
		}

		const buttonAction = action as DialogButtonProps

		if (buttonAction.type === 'cancel') {
			const to = buttonAction.to || closeTo
			const onClick = buttonAction.onClick || onClose

			return (
				<DialogPrimitive.Close asChild key={index}>
					<Button variant="outline" size="sm" asChild={!!to} onClick={!to ? onClick : undefined}>
						{to ? <Link to={to}>{buttonAction.label || 'Cancel'}</Link> : <span>{buttonAction.label || 'Cancel'}</span>}
					</Button>
				</DialogPrimitive.Close>
			)
		}

		if (buttonAction.type === 'submit') {
			return (
				<StatusButton
					key={index}
					status={isSubmitting ? 'pending' : 'idle'}
					size="sm"
					type={buttonAction.formId ? 'submit' : 'button'}
					form={buttonAction.formId}
					onClick={buttonAction.onClick}
					disabled={isSubmitting}
					asChild={!!buttonAction.to}
				>
					{buttonAction.to ? (
						<Link to={buttonAction.to}>{buttonAction.label || 'Save'}</Link>
					) : (
						<span>{buttonAction.label || 'Save'}</span>
					)}
				</StatusButton>
			)
		}

		return null
	})

	return (
		<footer
			className={cn('flex items-center justify-between border-t border-neutral-1-bd px-8 py-4', className)}
			{...rest}
		>
			<div className="text-body-sm">{footerInfo}</div>
			<div className="flex justify-end gap-2">{renderedActions}</div>
		</footer>
	)
}

type NewDialogContentProps = ComponentPropsWithoutRef<typeof DialogPrimitive.Content> &
	VariantProps<typeof dialogContentVariants> & {
		header?: ReactNode
		headerProps?: DialogHeaderProps
		footer?: ReactNode
		footerProps?: DialogFooterProps
		contentProps?: ComponentPropsWithoutRef<'section'> & VariantProps<typeof dialogContentVariants>
	}

//TODO: Rename into DialogContent after refactoring modals
export const NewDialogContent = forwardRef<ElementRef<typeof DialogPrimitive.Content>, NewDialogContentProps>(
	({ children, header, headerProps = {}, footer, footerProps = {}, contentProps = {}, className, ...props }, ref) => (
		<DialogPrimitive.Portal>
			<DialogPrimitive.Overlay className="fixed inset-0 z-50 grid place-items-center overflow-y-auto bg-overlay p-4 animate-in fade-in">
				<DialogPrimitive.Content
					className={cn(
						dialogContentVariants({ size: !contentProps?.className ? contentProps.size : null }),
						className,
					)}
					{...props}
					ref={ref}
				>
					{header ?? <DialogHeader {...headerProps} />}
					<section className={cn('px-8 pb-6 pt-2', contentProps.className)}>{children}</section>
					{footer !== null && <DialogFooter {...footerProps} />}
				</DialogPrimitive.Content>
			</DialogPrimitive.Overlay>
		</DialogPrimitive.Portal>
	),
)

NewDialogContent.displayName = 'NewDialogContent'

type DialogProps = ComponentPropsWithoutRef<typeof DialogPrimitive.Root> &
	VariantProps<typeof dialogContentVariants> & {
		trigger?: ReactNode
		children: ReactNode
		header?: ReactNode
		headerProps?: DialogHeaderProps
		footer?: ReactNode
		footerProps?: DialogFooterProps
		contentProps?: ComponentPropsWithoutRef<'section'> & VariantProps<typeof dialogContentVariants>
		dialogHeading?: ReactNode
		dialogDescription?: ReactNode
		footerInfo?: ReactNode
		isSubmitting?: boolean
		actions?: (DialogButtonProps | ReactNode)[]
		onClose?: () => void
		closeTo?: To
	}

export const Dialog = forwardRef<ElementRef<typeof DialogPrimitive.Content>, DialogProps>(
	(
		{
			trigger,
			children,
			header,
			headerProps = {},
			footer,
			footerProps = {},
			contentProps = {},
			dialogHeading,
			dialogDescription,
			footerInfo,
			isSubmitting,
			actions,
			onClose,
			closeTo,
			size,
			...props
		},
		ref,
	) => {
		const mergedHeaderProps = { ...headerProps, dialogHeading, dialogDescription, onClose, closeTo }
		const mergedFooterProps = { ...footerProps, footerInfo, isSubmitting, actions, onClose, closeTo }
		const mergedContentProps = { ...contentProps, size }

		return (
			<DialogPrimitive.Root {...props}>
				{trigger && <DialogPrimitive.Trigger asChild>{trigger}</DialogPrimitive.Trigger>}
				<NewDialogContent
					ref={ref}
					header={header}
					headerProps={mergedHeaderProps}
					footer={footer}
					footerProps={mergedFooterProps}
					contentProps={mergedContentProps}
				>
					{children}
				</NewDialogContent>
			</DialogPrimitive.Root>
		)
	},
)

Dialog.displayName = 'Dialog'

type DialogContentProps = ComponentPropsWithoutRef<typeof DialogPrimitive.Content> &
	Pick<DialogProps, 'dialogHeading' | 'dialogDescription'> & {
		closeClassName?: string
		cancelBtn?: ReactNode
		saveBtn?: ReactNode
		footerInfo?: ReactNode
	}

/**
 * This component is here temporarily for backward compatibility.
 * TODO: Remove this component when all project modal instances uses Dialog component instead of primitives.
 *
 * @deprecated Use the {@link Dialog} component instead.
 */
export const DialogContent = forwardRef<ElementRef<typeof DialogPrimitive.Content>, DialogContentProps>(
	(
		{ dialogHeading, dialogDescription, children, className, closeClassName, cancelBtn, saveBtn, footerInfo, ...props },
		ref,
	) => (
		<DialogPrimitive.Portal>
			<DialogPrimitive.Overlay className="fixed inset-0 z-50 grid place-items-center overflow-y-auto bg-overlay p-4 animate-in fade-in">
				<DialogPrimitive.Content
					className={cn(
						'm-6 flex max-w-screen-lg flex-col rounded-xl bg-neutral-1-bg shadow-lg animate-in fade-in zoom-in-90',
						className,
					)}
					{...props}
					ref={ref}
				>
					<section
						className={cn(
							'grid grid-cols-[1rem,1fr,1rem] grid-rows-1 px-6',
							dialogDescription ? 'border-b border-neutral-1-bd py-2' : 'py-4',
						)}
					>
						<div />
						<div className="text-center">
							{dialogHeading ? (
								<DialogPrimitive.Title className="text-title-sm text-neutral-1-fg">
									{dialogHeading}
								</DialogPrimitive.Title>
							) : null}
							{dialogDescription ? (
								<DialogPrimitive.Description className="text-body-sm text-neutral-3-fg">
									{dialogDescription}
								</DialogPrimitive.Description>
							) : null}
						</div>
						<DialogPrimitive.Close asChild className={closeClassName}>
							<button className="outline-none">
								<Icon name="cross-1" size="sm" />
							</button>
						</DialogPrimitive.Close>
					</section>

					<section className={cn(dialogDescription ? 'p-10' : 'px-10 pb-6 pt-2')}>{children}</section>
					{(cancelBtn || saveBtn) && (
						<>
							<div className="w-100 border-t border-neutral-1-bd" />
							<section className="flex items-center justify-between px-10 pb-4 pt-4">
								<div className="text-body-sm">{footerInfo}</div>
								<div className="flex justify-end gap-2">
									{cancelBtn}
									{saveBtn}
								</div>
							</section>
						</>
					)}
				</DialogPrimitive.Content>
			</DialogPrimitive.Overlay>
		</DialogPrimitive.Portal>
	),
)
DialogContent.displayName = 'DialogContent'

import * as DialogPrimitive from '@radix-ui/react-dialog'
import { type ReactNode, useEffect, useState } from 'react'
import { useFetcher } from 'react-router-dom'
import { Dialog } from '#src/components/ui/dialog'
import { Button } from './ui/button'
import { StatusButton } from './ui/status-button'

/**
 * IMPORTANT: This will implicitly close the modal after the action is done
 * because it is expected that this component is rendered within the context
 * of the deleted entity which will be removed from the UI after the action.
 */
export default function DeleteConfirm({
	trigger,
	action,
	title,
	description,
	input,
	handleModalClose,
	onSuccess,
	actionTitle,
	onOpenChange,
	method,
	intent,
}: {
	trigger: ReactNode
	action: string
	title: ReactNode
	description: ReactNode
	input?: ReactNode
	onSuccess?: () => void
	handleModalClose?: () => void
	actionTitle?: string
	onOpenChange?: (data: boolean) => void
	method?: 'PUT' | 'POST' | 'PATCH' | 'DELETE'
	intent?: string
}) {
	const [open, setOpen] = useState(false)
	const fetcher = useFetcher<{ success: boolean; message: string }>()

	useEffect(() => {
		if (fetcher.data?.success) {
			setOpen(false)

			if (onSuccess) onSuccess()

			if (handleModalClose) handleModalClose()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetcher.state])

	const handleOpenChange = (v: boolean) => {
		if (handleModalClose && open) {
			handleModalClose()
		}
		onOpenChange?.(v)
		setOpen(v)
	}

	return (
		<Dialog open={open} onOpenChange={handleOpenChange} trigger={trigger} footer={null}>
			<DeleteConfirmContent title={title} description={description}>
				<DeleteConfirmFooter
					actionTitle={actionTitle}
					action={action}
					input={input}
					fetcher={fetcher}
					method={method}
					intent={intent}
				/>
			</DeleteConfirmContent>
		</Dialog>
	)
}

export function DeleteConfirmContent({
	title,
	description,
	children,
}: {
	title: ReactNode
	description: ReactNode
	children: ReactNode
}) {
	return (
		<div className="flex w-full max-w-xl flex-col gap-4">
			<h1 className="text-title-md text-neutral-1-fg">{title}</h1>
			<p className="text-body-lg text-neutral-2-fg">{description}</p>
			{children}
		</div>
	)
}

export function DeleteConfirmFooter({
	action,
	input,
	fetcher,
	actionTitle,
	method,
	intent,
}: {
	action: string
	input?: ReactNode
	fetcher: ReturnType<typeof useFetcher>
	actionTitle?: string
	method?: 'PUT' | 'POST' | 'PATCH' | 'DELETE'
	intent?: string
}) {
	return (
		<div className="mt-4 flex w-full items-center justify-end gap-2">
			<DialogPrimitive.Close asChild>
				<Button type="button" variant="outline">
					Cancel
				</Button>
			</DialogPrimitive.Close>
			<fetcher.Form action={action} method={method ?? 'DELETE'}>
				{input}
				<StatusButton
					status={fetcher.state !== 'idle' ? 'pending' : 'idle'}
					type="submit"
					variant="danger"
					disabled={fetcher.state !== 'idle'}
					{...(!!intent && {
						name: 'intent',
						value: intent,
					})}
				>
					{actionTitle ?? 'Delete'}
				</StatusButton>
			</fetcher.Form>
		</div>
	)
}

import { getFormProps, getInputProps, getSelectProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { captureException } from '@sentry/react'
import { type QueryClient } from '@tanstack/react-query'
import { type ActionFunctionArgs, useFetcher, useLoaderData } from 'react-router-dom'
import { ErrorList, Field, Select } from '#src/components/forms/index'
import { Icon } from '#src/components/ui/icon'
import { StatusButton } from '#src/components/ui/status-button'
import { Toast, ToastDescription, ToastViewport } from '#src/components/ui/toast'
import { userQuery } from '#src/routes/auth/queries'
import { type TeamLoaderResponse } from '#src/routes/settings/team'
import { checkIsAdminSession, checkIsUserSession } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import { sendInvite } from './mutations'
import { membersKeys } from './queries'
import { InviteFormSchema } from './schema'

export type InviteActionResponse = Awaited<ReturnType<ReturnType<typeof action>>>

export const action =
	(queryClient: QueryClient) =>
	async ({ request, params }: ActionFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const user = await queryClient.fetchQuery(userQuery())
		const adminSession = checkIsAdminSession(user.roles)
		const userSession = checkIsUserSession(user.roles)

		if (!adminSession && !userSession) {
			throw new Response('Access Denied', {
				status: 403,
				statusText: 'Forbidden',
			})
		}

		const formData = await request.formData()

		const submission = parseWithZod(formData, {
			schema: InviteFormSchema,
		})

		if (submission.status !== 'success') {
			return {
				ok: false,
				token: null,
				result: submission.reply({
					formErrors: ['Failed to submit form. Make sure all fields are filled out correctly.'],
				}),
			}
		}

		try {
			const response = await sendInvite(params.companyId, {
				email: submission.value.email,
				role: submission.value.role,
			})
			await queryClient.invalidateQueries({
				queryKey: membersKeys.all,
			})

			return {
				ok: true,
				token: response.token,
				result: null,
				email: submission.value.email,
			}
		} catch (error) {
			captureException(error)

			if (error instanceof Response && error.status === 422) {
				const { message } = (await error.json()) as { message: string }

				if (message) {
					return {
						ok: false,
						token: null,
						result: submission.reply({
							formErrors: [message],
						}),
						email: submission.value.email,
					}
				}
			}
			return {
				ok: false,
				token: null,
				result: submission.reply({
					formErrors: ['Ooops! Something went wrong. Please try again later.'],
				}),
				email: submission.value.email,
			}
		}
	}

export function InviteMemberForm() {
	const { roles } = useLoaderData() as TeamLoaderResponse
	const { companyId } = useParsedRouteParams(['companyId'])

	const fetcher = useFetcher<InviteActionResponse>()

	const formId = fetcher?.data?.token ?? 'invite-member-form'
	const defaultRole = Object.keys(roles)[0]
	const [form, fields] = useForm({
		id: formId,
		constraint: getZodConstraint(InviteFormSchema),
		defaultValue: {
			email: null,
			role: defaultRole,
		},
		lastResult: !(fetcher.data instanceof Response) ? fetcher.data?.result : null,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: InviteFormSchema })
		},
		shouldValidate: 'onBlur',
		shouldRevalidate: 'onInput',
	})

	return (
		<>
			<fetcher.Form
				{...getFormProps(form)}
				action={routes.settings.invite({
					companyId,
				})}
				method="POST"
				className="flex w-2/3 flex-col gap-2"
			>
				<section className="grid grid-cols-[1fr,max-content,max-content] grid-rows-1 items-start gap-2">
					<Field
						labelProps={{ children: 'Invite your team' }}
						inputProps={{
							...getInputProps(fields.email, { type: 'text' }),
							placeholder: 'Email',
						}}
						errors={fields.email.errors}
					/>
					<Select
						className="w-[176px]"
						labelProps={{
							children: 'Select Role',
						}}
						inputProps={{
							...getSelectProps(fields.role),
							defaultValue: fields.role.initialValue,
							value: fields.role.value,
							placeholder: '-',
						}}
						options={Object.entries(roles).map(([role, value]) => ({
							value,
							label: role,
						}))}
						errors={fields.role.errors}
					/>

					<StatusButton
						className="mt-5 min-w-20" // NOTE: compensate for items-start alignment because field errors
						type="submit"
						status={fetcher.state !== 'idle' ? 'pending' : 'idle'}
						disabled={fetcher.state !== 'idle'}
					>
						Invite
					</StatusButton>
				</section>

				<ErrorList errors={form.errors} id={form.errorId} />
			</fetcher.Form>
			<Toast shouldOpen={fetcher.state === 'loading' && !!fetcher.data?.ok} duration={3000}>
				<ToastDescription className="flex items-center gap-3">
					<Icon name="checkmark-filled" size="md" className="text-green-70" />
					Invitation was sent to {fetcher.data?.email}
				</ToastDescription>
			</Toast>
			<Toast shouldOpen={fetcher.state === 'loading' && !fetcher.data?.ok && !!fetcher.data?.result} duration={3000}>
				<ToastDescription className="flex items-center gap-3">
					<Icon name="error-filled" size="md" className="text-red-60" />
					Failed to send invitation to {fetcher.data?.email}
				</ToastDescription>
			</Toast>
			<div className="absolute">
				<ToastViewport />
			</div>
		</>
	)
}

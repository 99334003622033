import { type ReactNode } from 'react'
import { type z } from 'zod'
import { AccordionItem, AccordionRoot, AccordionTrigger } from '#src/components/ui/accordion'
import { Icon } from '#src/components/ui/icon'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '#src/components/ui/tooltip'
import { type EcosystemAPISchema } from '#src/routes/calibrate/ecosystem/schema'
import { cn } from '#src/utils/misc'

export type EcosystemAccordionData = {
	id: number
	trigger: ReactNode
	name: ReactNode
	content: ReactNode
	contextMenu: ReactNode
	verticalsCount: number
	personasCount: number
}

export function transformEcosystemsToAccordionData(
	ecosystems: ({ content: ReactNode; contextMenu?: ReactNode } & z.infer<typeof EcosystemAPISchema>)[],
): EcosystemAccordionData[] {
	return ecosystems.map(ecosystem => ({
		id: ecosystem.id,
		name: ecosystem.name,
		trigger: '',
		content: ecosystem.content,
		contextMenu: ecosystem.contextMenu ?? null,
		verticalsCount: ecosystem.verticals.length ?? 0,
		personasCount: ecosystem.verticals.reduce(
			(totalPersonaCount, vertical) => totalPersonaCount + (vertical.personas?.length ? vertical.personas.length : 0),
			0,
		),
	}))
}

export default function EcosystemsAccordion({ ecosystems }: { ecosystems: EcosystemAccordionData[] }) {
	return (
		<AccordionRoot type="multiple" className="flex flex-col gap-4">
			{ecosystems.map(ecosystem => (
				<EcosystemAccordionItem key={ecosystem.id} {...ecosystem} />
			))}
		</AccordionRoot>
	)
}

export function EcosystemAccordionItem({
	id,
	trigger,
	content,
	verticalsCount,
	personasCount,
	contextMenu,
	prefix,
	suffix,
}: EcosystemAccordionData & {
	prefix?: ReactNode
	suffix?: ReactNode
	forceMount?: true
}) {
	return (
		<AccordionItem
			value={`item-${id}`}
			trigger={
				<div
					className={cn(
						'grid grid-rows-1 items-center gap-4',
						prefix ? 'grid-cols-[max-content,max-content,1fr,max-content]' : 'grid-cols-[max-content,1fr,max-content]',
					)}
				>
					{prefix}
					<AccordionTrigger>{trigger}</AccordionTrigger>
					{suffix}
					<div className="grid grid-cols-[repeat(4,1.25rem),max-content]">
						<div className="flex h-5 w-5 items-center justify-center text-label-sm font-normal text-neutral-3-fg">
							<TooltipProvider>
								<Tooltip delayDuration={0}>
									<TooltipTrigger>
										<Icon name="category" size="sm" />
									</TooltipTrigger>
									<TooltipContent side="bottom" align="center">
										Verticals
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>
						</div>
						<div className="flex h-5 w-5 items-center justify-center text-label-sm font-normal text-neutral-3-fg">
							{verticalsCount}
						</div>
						<div className="flex h-5 w-5 items-center justify-center text-label-sm font-normal text-neutral-3-fg">
							<TooltipProvider>
								<Tooltip delayDuration={0}>
									<TooltipTrigger>
										<Icon name="user" size="sm" />
									</TooltipTrigger>
									<TooltipContent side="bottom" align="center">
										Personas
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>
						</div>
						<div className="flex h-5 w-5 items-center justify-center text-label-sm font-normal text-neutral-3-fg">
							{personasCount}
						</div>
						{contextMenu ? <div className="ml-2 flex h-5 w-5 items-center justify-center">{contextMenu}</div> : null}
					</div>
				</div>
			}
			contentProps={{
				className:
					'-my-4 overflow-hidden px-4 radix-state-closed:animate-[acc-slide-up_150ms_ease-in-out] radix-state-open:animate-[acc-slide-down_150ms_ease-in-out]',
			}}
		>
			<div className="py-4">{content}</div>
		</AccordionItem>
	)
}

import { getFormProps, getInputProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { Form, useActionData, useLoaderData } from 'react-router-dom'
import { ErrorList, Field } from '#src/components/forms'
import { StatusButton } from '#src/components/ui/status-button'
import { Surface } from '#src/components/ui/surface'
import {
	type AuthResetPasswordActionRes,
	type AuthResetPasswordLoaderRes,
} from '#src/routes/auth/routes/reset-password'
import { ResetPasswordFormSchema } from '#src/routes/auth/schema'
import { useIsPending } from '#src/utils/misc'

export const ResetPasswordForm = () => {
	const { user } = useLoaderData() as AuthResetPasswordLoaderRes
	const actionData = useActionData() as AuthResetPasswordActionRes

	const isPending = useIsPending()
	const [form, fields] = useForm({
		id: 'reset-password-form',
		constraint: getZodConstraint(ResetPasswordFormSchema),
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: ResetPasswordFormSchema })
		},
		lastResult: actionData instanceof Response ? null : (actionData?.result ?? null),
		shouldValidate: 'onBlur',
		shouldRevalidate: 'onInput',
	})

	return (
		<main className="grid h-full w-full grid-cols-1 grid-rows-1 items-center justify-items-center">
			<Surface className="flex w-full max-w-lg flex-col items-center gap-8 px-16 py-8">
				<div className="flex flex-col gap-2 text-center">
					<h1 className="text-title-md text-neutral-1-fg">{`${user.invitedBy} invited you to make an account!`}</h1>
					<p className="text-body-md text-neutral-2-fg">
						Your email <span className="font-semibold">{user.email}</span> was invited. Set up a password to join the
						team.
					</p>
				</div>
				<Form method="POST" {...getFormProps(form)} className="flex w-full flex-col items-center gap-8">
					<div className="flex w-full flex-col items-center gap-6">
						<Field
							className="w-full"
							labelProps={{ children: 'New password' }}
							inputProps={{
								...getInputProps(fields.password, { type: 'password' }),
								placeholder: 'New password',
								autoFocus: true,
								autoComplete: 'off',
							}}
							errors={fields.password.errors}
						/>
						<Field
							className="w-full"
							labelProps={{ children: 'Reenter password' }}
							inputProps={{
								...getInputProps(fields.confirm, { type: 'password' }),
								placeholder: 'Reenter password',
								autoComplete: 'off',
							}}
							errors={fields.confirm.errors}
						/>
					</div>

					<ErrorList errors={form.errors} id={form.errorId} />

					<StatusButton status={isPending ? 'pending' : (form.status ?? 'idle')} type="submit" disabled={isPending}>
						Get Started
					</StatusButton>
				</Form>
			</Surface>
		</main>
	)
}

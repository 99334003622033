import { type QueryClient } from '@tanstack/react-query'
import { redirect, type LoaderFunctionArgs } from 'react-router-dom'
import { userQuery } from '#src/routes/auth/queries'
import { checkIsAdminSession } from '#src/utils/misc'
import { routes } from '#src/utils/routes.js'
import { restartConversationChat } from './mutations'
import { enableKeys } from './queries'

export const action =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId || !params.conversationId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const user = await queryClient.fetchQuery(userQuery())
		const adminSession = checkIsAdminSession(user.roles)

		if (!adminSession)
			throw new Response('Access Denied', {
				status: 403,
				statusText: 'Forbidden',
			})

		const response = await restartConversationChat(params.companyId, params.conversationId)

		await Promise.all([
			void queryClient.invalidateQueries({
				queryKey: enableKeys.chats(params.companyId),
			}),
			void queryClient.invalidateQueries({
				queryKey: enableKeys.chat(params.companyId, params.conversationId),
			}),
			void queryClient.invalidateQueries({
				queryKey: enableKeys.conversation(params.companyId, params.conversationId),
			}),
		])

		return redirect(
			routes.enable.copilot.index({
				companyId: user.companyId,
				conversationId: response.conversation.id,
			}),
		)
	}

import { type QueryClient } from '@tanstack/react-query'
import { type RouteObject } from 'react-router-dom'
import { action as deleteTermAction } from '#src/routes/enable/glossary/delete'
import TermEdit, { loader as editTermLoader } from '#src/routes/enable/glossary/edit'
import Glossary, { type GlossaryLoaderResponse, loader as glossaryLoader } from '#src/routes/enable/glossary/index'
import { action as saveTermAction } from '#src/routes/enable/glossary/save'
import RouteBoundary from '#src/routes/route-boundary'
import { routes } from '#src/utils/routes'

export const glossaryRouter = (client: QueryClient) =>
	({
		path: 'glossary',
		element: <Glossary />,
		errorElement: <RouteBoundary />,
		loader: glossaryLoader(client),
		handle: {
			layout: {
				enableHeader: true,
			},
			crumb: (data: GlossaryLoaderResponse) => [
				{
					to: routes.enable.glossary.index({
						companyId: data.handle.companyId,
					}),
					label: 'Industry-jargons',
					icon: 'notebook-reference',
				},
			],
			action: (data: GlossaryLoaderResponse) =>
				data.handle.readOnlySession || data.handle.enableOnlySession
					? []
					: [
							{
								to: routes.enable.glossary.edit({
									companyId: data.handle.companyId,
									termId: null,
								}),
								label: 'Add new term',
								icon: 'add',
							},
						],
		},
		children: [
			{
				path: 'save/:termId?',
				action: saveTermAction(client),
			},
			{
				path: 'delete/:termId',
				action: deleteTermAction(client),
			},
			{
				path: 'edit/:termId?',
				element: <TermEdit />,
				loader: editTermLoader(client),
				handle: {
					layout: {
						enableHeader: true,
					},
				},
			},
		],
	}) satisfies RouteObject

import { type QueryClient } from '@tanstack/react-query'
import { type LoaderFunctionArgs, useNavigate, useRouteLoaderData } from 'react-router-dom'
import { DialogContent, DialogRoot } from '#src/components/ui/dialog'
import { criteriaQuery, ecosystemQuery, ecosystemsTemplatesQuery } from '#src/routes/calibrate/ecosystem/queries'
import { companyQuery } from '#src/routes/company/queries'
import { EcosystemForm } from './save'

export type EcosystemLoaderResponse = Awaited<ReturnType<ReturnType<typeof loader>>>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const company = await queryClient.ensureQueryData(companyQuery(params.companyId))

		return {
			ecosystem: params.ecosystemId
				? await queryClient.fetchQuery(ecosystemQuery(params.companyId, params.ecosystemId))
				: null,
			criteria: params.ecosystemId
				? await queryClient.fetchQuery(criteriaQuery(params.companyId, params.ecosystemId))
				: null,
			company,
			ecosystemTemplates: await queryClient.fetchQuery(ecosystemsTemplatesQuery(params.companyId)),
		}
	}

export default function Ecosystem() {
	const data = useRouteLoaderData('ecosystem') as EcosystemLoaderResponse
	const navigate = useNavigate()

	return (
		<DialogRoot defaultOpen={true} onOpenChange={isOpen => !isOpen && navigate(-1)}>
			<DialogContent
				dialogHeading={data.ecosystem ? 'Ecosystem Details' : 'Create Ecosystem'}
				className="w-full max-w-lg"
			>
				<EcosystemForm
					companyId={data.company.id}
					ecosystem={data.ecosystem}
					ecosystemTemplates={data.ecosystemTemplates}
				/>
			</DialogContent>
		</DialogRoot>
	)
}

import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { useEffect, useState } from 'react'
import { useActionData, useLocation } from 'react-router-dom'
import { Wizard, type WizardStep } from '#src/components/wizard'
import {
	COMMUNICATION_STYLE_OPTIONS,
	DECISION_MAKING_PROCESS_OPTIONS,
	EMOTIONAL_STATE_OPTIONS,
	PERSONALITY_TRAIT_OPTIONS,
} from '#src/constants/options'
import { useScenarioTemplate } from '#src/hooks/useScenarioTemplate'
import {
	type CreateSessionFormSchemaType,
	getActiveNewSessionWizardSchema,
} from '#src/routes/enable/roleplay/session/schema'
import { useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import { EvaluationForm, PersonalityForm, SessionForm, PersonaMappingForm } from './'

type ActionData = {
	ok: boolean
	result: {
		initialValue: Record<string, string>
		contactId?: string
	}
}

const stepsIntlVal: WizardStep<CreateSessionFormSchemaType>[] = [
	{
		label: 'Session',
		value: 'session',
		isFinished: false,
		component: fields => (
			<div className="flex flex-col gap-4">
				<SessionForm fields={fields} />
			</div>
		),
	},
	{
		label: 'Persona Mapping',
		value: 'persona-mapping',
		isFinished: false,
		component: fields => (
			<div className="flex flex-col gap-4">
				<PersonaMappingForm fields={fields} />
			</div>
		),
	},
	{
		label: 'Personality',
		value: 'personality',
		isFinished: false,
		component: fields => (
			<div className="flex flex-col gap-4">
				<PersonalityForm fields={fields} />
			</div>
		),
	},
	{
		label: 'Evaluation',
		value: 'evaluation',
		isFinished: false,
		component: fields => (
			<div className="flex flex-col gap-4">
				<EvaluationForm fields={fields} />
			</div>
		),
	},
]

type LocationState = {
	ecosystemId?: number
	verticalId?: number
	personaId?: number
}

export const NewRoleplaySessionWizard = () => {
	const { companyId } = useParsedRouteParams(['companyId'])
	const { scenarioTemplates } = useScenarioTemplate()
	const actionData = useActionData() as ActionData
	const isPending = useIsPending()
	const { state } = useLocation() as { state: LocationState }

	const [intlVal, setIntlVal] = useState<CreateSessionFormSchemaType>({
		title: '',
		scenarioTitle: scenarioTemplates[0].name,
		scenario: scenarioTemplates[0].scenario,
		ecosystemId: state?.ecosystemId ?? 0,
		verticalId: state?.verticalId ?? 0,
		personaId: state?.personaId ?? 0,
		templateId: 0,
		evaluation: scenarioTemplates[0].evaluation ?? '',
		linkedinUrl: '',
		personality: {
			communicationStyle: COMMUNICATION_STYLE_OPTIONS[0].value,
			decisionMakingProcess: DECISION_MAKING_PROCESS_OPTIONS[0].value,
			emotionalState: EMOTIONAL_STATE_OPTIONS[0].value,
			personalityTrait: PERSONALITY_TRAIT_OPTIONS[0].value,
			voice: '',
		},
	})
	const [activeStep, setActiveStep] = useState(stepsIntlVal[0].value)
	const activeSchema = getActiveNewSessionWizardSchema(activeStep)
	const [steps, setSteps] = useState(stepsIntlVal)

	const handleNextStep = () => {
		setSteps(prevState => prevState.map(item => (item.value === activeStep ? { ...item, isFinished: true } : item)))

		const currentStepIndex = steps.findIndex(item => item.value === activeStep)

		if (currentStepIndex === -1) {
			setActiveStep(steps[0].value)

			return
		}

		if (currentStepIndex < steps.length - 1) {
			setActiveStep(steps[currentStepIndex + 1].value)
		}
	}

	useEffect(() => {
		if (actionData?.ok) {
			setIntlVal(prev => ({
				...prev,
				...actionData.result.initialValue,
				...(activeStep === 'persona-mapping' && {
					linkedinUrl: actionData.result.initialValue.linkedinUrl,
				}),
			}))

			handleNextStep()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [actionData])

	const handleValidate = ({ formData }: { formData: FormData }) => {
		return parseWithZod(formData, { schema: activeSchema })
	}

	const formOptions = {
		id: `${activeStep}-roleplay-session`,
		constraint: getZodConstraint(activeSchema),
		defaultValue: intlVal,
		onValidate: handleValidate,
		shouldValidate: 'onSubmit' as const,
		shouldRevalidate: 'onInput' as const,
		lastResult: actionData?.result ?? null,
	}

	return (
		<Wizard
			steps={steps}
			title="Roleplay Session Setup"
			exitRoute={routes.enable.roleplay.index({ companyId })}
			exitTooltipText="Exit setup"
			setActiveStep={setActiveStep}
			activeStep={activeStep}
			formOptions={formOptions}
			disabled={isPending}
			finishBtnText="Start Session"
		/>
	)
}

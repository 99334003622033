import { Link, useRouteLoaderData } from 'react-router-dom'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { type MainLoaderResponse } from '#src/routes/_layout/main'
import { type ChatLoaderResponse } from '#src/routes/enable/chat'
import { routes } from '#src/utils/routes'

const ChatsPanelHeader = () => {
	const {
		handle: { companyId, conversationId },
	} = useRouteLoaderData('copilot-module') as ChatLoaderResponse
	const { user } = useRouteLoaderData('main-loader') as MainLoaderResponse

	return (
		<div className="flex w-full items-center justify-between gap-4">
			<span className="w-full text-center text-body-sm font-semibold tracking-[.02em] text-neutral-3-fg">
				{user.company && user.roles.includes('enable-only') ? user.company : 'Co-pilot'}
			</span>
			{user.roles.some(role => role.includes('admin') || role.includes('user') || role.includes('enable-only')) ? (
				<Button asChild size="sm" className="h-6 w-6 shrink-0 p-0">
					<Link
						to={routes.enable.copilot.new({
							companyId,
							conversationId: conversationId ?? null,
						})}
					>
						<Icon name="add" size="sm" />
					</Link>
				</Button>
			) : null}
		</div>
	)
}

export default ChatsPanelHeader

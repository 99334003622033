import { getFormProps, getInputProps, getTextareaProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { type ReactNode, useEffect, useState } from 'react'
import { useFetcher } from 'react-router-dom'
import { type z } from 'zod'
import { ErrorList, Field, TextareaField } from '#src/components/forms'
import { Dialog, DialogTrigger } from '#src/components/ui/dialog'
import { Icon } from '#src/components/ui/icon'
import { type CompanyEditActionRes, type CompanyEditLoaderRes } from '#src/routes/company/routes/edit'
import { type CompanySchema, EditCompanyFormSchema } from '#src/routes/company/schema'
import { useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

type Props = {
	companyId: z.infer<typeof CompanySchema>['id']
	children: ReactNode
}

export const CompanyEditModal = (props: Props) => {
	const { companyId, children } = props
	const isPending = useIsPending({
		formAction: routes.company.edit({ companyId }),
		formMethod: 'PATCH',
	})

	const [open, setOpen] = useState(false)

	return (
		<Dialog
			open={open}
			onOpenChange={setOpen}
			trigger={<DialogTrigger asChild>{children}</DialogTrigger>}
			dialogHeading="Edit company details"
			isSubmitting={isPending}
			headerProps={{
				dialogHeading: 'Edit company details',
			}}
			actions={[
				{
					type: 'cancel',
					label: 'Cancel',
					onClick: () => setOpen(false),
				},
				{
					type: 'submit',
					label: 'Update',
					formId: getFormName(companyId),
				},
			]}
		>
			<Form onClose={() => setOpen(false)} companyId={companyId} />
		</Dialog>
	)
}

const getFormName = (companyId: z.infer<typeof CompanySchema>['id'], loaded: boolean = true) =>
	`company-${companyId}-edit${loaded ? '' : '-loading'}`

type FormProps = {
	companyId: z.infer<typeof CompanySchema>['id']
	onClose: () => void
}

const Form = (props: FormProps) => {
	const { companyId, onClose } = props
	const loaderFetcher = useFetcher<CompanyEditLoaderRes>()
	const data = loaderFetcher.data
	const actionFetcher = useFetcher<CompanyEditActionRes>()
	const action = routes.company.edit({ companyId })

	useEffect(() => {
		if (loaderFetcher.state === 'idle' && !loaderFetcher.data) {
			loaderFetcher.load(action)
		}
	}, [loaderFetcher, companyId, action])

	useEffect(() => {
		if (actionFetcher.data && actionFetcher.data.ok) {
			onClose()
		}
		// eslint-disable-next-line
	}, [actionFetcher.data])

	const [form, fields] = useForm({
		id: getFormName(companyId, !!data),
		constraint: getZodConstraint(EditCompanyFormSchema),
		defaultValue: data?.data.company,
		lastResult: actionFetcher.data && 'result' in actionFetcher.data ? actionFetcher.data.result : null,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: EditCompanyFormSchema })
		},
		shouldValidate: 'onBlur',
		shouldRevalidate: 'onInput',
	})

	return (
		<actionFetcher.Form method="PATCH" {...getFormProps(form)} action={action} className="flex w-full flex-col gap-6">
			<input {...getInputProps(fields.id, { type: 'hidden' })} defaultValue={companyId} key={undefined} />
			<Field
				labelProps={{ children: 'Name' }}
				inputProps={{
					...getInputProps(fields.name, { type: 'text' }),
					autoFocus: true,
					placeholder: 'Enter company name',
				}}
				errors={fields.name.errors}
			/>

			<div className="flex flex-col gap-4">
				<TextareaField
					labelProps={{
						children: 'Value Proposition',
					}}
					textareaProps={{
						...getTextareaProps(fields.valueProposition),
						placeholder: 'Paste or type here',
						rows: 10,
					}}
					errors={fields.valueProposition.errors}
				/>
				<p className="w-full rounded-sm bg-brand-1-bg p-2 text-center text-body-sm">
					Use our free{' '}
					<a
						href="https://chatgpt.com/g/g-5eVvHz6wz-value-prop-expert-v16"
						target="__blank"
						title="Value Prop Sage GPT"
						className="text-link transition-colors hover:text-link-hover active:text-link-pressed"
					>
						<Icon name="magic-wand" asSuffix>
							Value Proposition Sage GPT
						</Icon>
					</a>{' '}
					AI helper. Paste your Website URL, upload files and answer few questions to get your company&apos;s value
					proposition fast!
				</p>
			</div>

			<ErrorList errors={form.errors} id={form.errorId} />
		</actionFetcher.Form>
	)
}

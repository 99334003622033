import { clsx } from 'clsx'
import { useMemo } from 'react'
import { Icon } from '#src/components/ui/icon'

type Step = {
	name: string
	label: string
}

type Props<T extends readonly Step[]> = {
	active: T[number]['name']
	steps: T
}

export const FormStepper = <T extends readonly Step[]>(props: Props<T>) => {
	const { active, steps } = props

	const stepsMapped = useMemo(() => steps.map(({ name }) => name), [steps])

	return (
		<div className="flex flex-row">
			{steps.map(step => {
				const isActive = step.name === active
				const isCompleted = stepsMapped.indexOf(active) > stepsMapped.indexOf(step.name)
				return (
					<div
						key={`step-${step.name}`}
						className={clsx(
							'align-center flex flex-1 flex-row gap-1 border-t-2 py-3',
							isActive || isCompleted ? 'border-brand-1-bd' : 'border-neutral-1-bd',
						)}
					>
						<div>
							<Icon
								name={isCompleted ? 'checkmark-filled' : 'incomplete'}
								size="sm"
								className={clsx(
									'self-start',
									'text-neutral-3-fg',
									isActive && 'text-brand-1-fg',
									isCompleted && 'text-green-70',
								)}
							/>
						</div>
						<p className="text-neutral-1-fg">{step.label}</p>
					</div>
				)
			})}
		</div>
	)
}

import { parseWithZod } from '@conform-to/zod'
import { type QueryClient } from '@tanstack/react-query'
import { type ActionFunctionArgs } from 'react-router-dom'
import { ecosystemKeys } from '#src/routes/calibrate/ecosystem/queries'
import { personaKeys } from '#src/routes/calibrate/personas/queries'
import { verticalKeys } from '#src/routes/calibrate/verticals/queries'
import { updateCompany } from '#src/routes/company/mutations'
import { companyKeys } from '#src/routes/company/queries'
import { EditCompanyFormSchema } from '#src/routes/company/schema'

export type ActionRes = Awaited<ReturnType<ReturnType<typeof action>>>

export const action =
	(queryClient: QueryClient) =>
	async ({ request }: ActionFunctionArgs) => {
		const formData = await request.formData()

		const submission = parseWithZod(formData, { schema: EditCompanyFormSchema })

		if (submission.status !== 'success') {
			throw Error('Failed to parse form data')
		}

		try {
			const { id, ...companyForm } = submission.value
			const company = await updateCompany(id, companyForm)

			if (!company) {
				throw Error('Could not update company')
			}

			await queryClient.refetchQueries({
				queryKey: companyKeys.detail(company.id),
			})

			void queryClient.invalidateQueries({
				queryKey: ecosystemKeys.all,
			})
			void queryClient.invalidateQueries({
				queryKey: verticalKeys.all,
			})
			void queryClient.invalidateQueries({
				queryKey: personaKeys.all,
			})
			void queryClient.invalidateQueries({
				queryKey: personaKeys.all,
			})
			return {
				ok: true,
			}
		} catch (error) {
			let errorMessage = 'Something went wrong with updating company details. Try again later.'

			if (error instanceof Response) {
				const errorBody = (await error.json()) as { message?: string }

				if (errorBody?.message) {
					errorMessage = errorBody.message
				}
			}

			return {
				ok: false,
				result: submission.reply({
					formErrors: [errorMessage],
				}),
			}
		}
	}

import { type ReactNode } from 'react'
import { Link, useRevalidator, useRouteLoaderData } from 'react-router-dom'
import { Logo } from '#src/components/ui/logo'
import { type MainLoaderResponse } from '#src/routes/_layout/main.js'

export default function Sidebar({ children }: { children: ReactNode }) {
	const { company } = useRouteLoaderData('main-loader') as MainLoaderResponse
	const revalidator = useRevalidator()

	return (
		<div className="pointer-events-none sticky left-0 top-0 z-40 border-r border-r-neutral-1-bd bg-neutral-2-bg">
			<div className="pointer-events-auto flex h-full flex-col items-center gap-2">
				<Link
					to={company?.id ? `/company/${company.id}` : '/'}
					onClick={() => revalidator.revalidate()}
					className="flex items-center p-4"
				>
					<Logo type="symbol" size="xs" />
				</Link>

				{children}
			</div>
		</div>
	)
}

import { useRouteLoaderData } from 'react-router-dom'
import { type z } from 'zod'
import { Icon } from '#src/components/ui/icon'
import { Surface } from '#src/components/ui/surface'
import { type MainLoaderResponse } from '#src/routes/_layout/main'
import { LabeledValue } from '#src/routes/lead-research-agent/views/components'
import { type SignalTemplateAPISchema } from '#src/routes/prioritize/schema'
import { SignalType } from '#src/routes/prioritize/views/components/SignalType'
import { SignalWeightForm } from '#src/routes/prioritize/views/components/SignalWeightForm'
import { WeightValue } from '#src/routes/prioritize/views/components/WeightValue'

type Props = {
	signalTemplate: z.infer<typeof SignalTemplateAPISchema>
}

export const SignalDetailsCard = (props: Props) => {
	const { signalTemplate } = props
	const { readOnlySession } = useRouteLoaderData('main-loader') as MainLoaderResponse

	return (
		<Surface className="flex flex-col gap-6 overflow-hidden p-10">
			{!signalTemplate.available ? (
				<div className="-mx-10 -mt-10 bg-orange-10 px-10 py-2 text-center text-body-sm text-orange-70">
					Currently, this signal is still under development, but you can map personas so we can prioritize them as soon
					as the signal becomes available
				</div>
			) : null}

			<div className="flex flex-col gap-2">
				<SignalType type={signalTemplate.type} />
				<h1 className="flex items-center gap-1 text-title-lg text-neutral-1-fg">
					{signalTemplate.name}
					{signalTemplate.available ? (
						<div className="flex items-center text-label-lg text-green-70">
							<Icon
								name="circle-fill"
								size="sm"
								className="flex flex-nowrap items-center text-green-70 transition-colors"
							/>
							Available
						</div>
					) : null}
				</h1>
			</div>
			<LabeledValue
				label="Weight"
				tooltip="Weight: The importance or relevance of the intent signal, typically as a numerical value"
			>
				{readOnlySession ? (
					<WeightValue value={signalTemplate.config.weight.toString()} />
				) : (
					<SignalWeightForm signalId={signalTemplate.id} weight={signalTemplate.config.weight} showResult />
				)}
			</LabeledValue>
			<LabeledValue
				label="Hack compatible"
				tooltip="Hack Compatible: Suitable for use in the intent-based hack workflow within the TAM organizer"
			>
				{signalTemplate.hack ? 'Yes' : 'No'}
			</LabeledValue>
			<LabeledValue
				label="Example of manual steps"
				tooltip="Example of manual steps: Steps to manually identify and approach this intent signal & prospect"
			>
				{signalTemplate.manualSteps}
			</LabeledValue>
			<LabeledValue
				label="Context & Example"
				tooltip="Context & Example: Illustrates how the intent signal can be valuable for pitching specific solutions"
			>
				{signalTemplate.context}
			</LabeledValue>
		</Surface>
	)
}

import { Link, useLoaderData, useRouteLoaderData } from 'react-router-dom'
import { DashboardCard, DashboardCardCount } from '#src/components/dashboard-card'
import ProductTip from '#src/components/product-tip'
import { PageLayout } from '#src/components/ui/PageLayout'
import { type MainLoaderResponse } from '#src/routes/_layout/main'
import { type OrganizeDashboardLoaderRes } from '#src/routes/organize/routes/dashboard'
import { checkIsAdminSession, checkIsUserSession } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'

export const View = () => {
	const params = useParsedRouteParams(['companyId'])
	const { user } = useRouteLoaderData('main-loader') as MainLoaderResponse
	const {
		data: { lra },
	} = useLoaderData() as OrganizeDashboardLoaderRes

	return (
		<PageLayout title="Organize">
			<div className="grid grid-cols-3 grid-rows-1 gap-6">
				{(checkIsUserSession(user.roles) || checkIsAdminSession(user.roles)) && (
					<Link
						to={routes.leadResearchAgent.index({
							companyId: params.companyId,
						})}
						className="block"
					>
						<DashboardCard
							icon="chart-column"
							title={<div className="flex w-full items-center gap-1">Lead research agent jobs</div>}
							isHoverable
							className="min-h-[146px]"
						>
							<div className="flex gap-4">
								<DashboardCardCount count={lra.launched} countSuffix="Launched" />
								<DashboardCardCount count={lra.completed} countSuffix="Completed" />
							</div>
						</DashboardCard>
					</Link>
				)}
				<Link
					to={routes.organize.decisionTree.index({
						companyId: params.companyId,
					})}
					className="block"
				>
					<DashboardCard
						icon="decision-tree"
						title={
							<div className="flex w-full items-center gap-1">
								Classification decision tree
								<ProductTip
									className="text-neutral-3-fg"
									content="Improve qualification criteria for faster, cost-effective account classification, ensuring your sales team qualifies the right opportunities efficiently."
								/>
							</div>
						}
						isHoverable
						className="min-h-[146px]"
					></DashboardCard>
				</Link>
			</div>
		</PageLayout>
	)
}

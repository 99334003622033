import { type QueryClient } from '@tanstack/react-query'
import { type RouteObject } from 'react-router-dom'
import { loader as indexLoader } from '#src/routes/admin'
import AdminLayout from '#src/routes/admin/_layout'
import Companies, { loader as companiesLoader } from '#src/routes/admin/companies'
import { action as deleteCompanyAction } from '#src/routes/admin/delete'
import EditCompanyDashboard, {
	loader as editCompanyDashboardLoader,
	action as editCompanyDashboardAction,
} from '#src/routes/admin/edit'
import NewCompany, { action as newCompanyAction } from '#src/routes/admin/new'

export default (client: QueryClient) =>
	({
		element: <AdminLayout />,
		children: [
			{
				path: '/admin',
				loader: indexLoader(client),
				children: [
					{
						index: true,
						element: <Companies />,
						loader: companiesLoader(client),
					},
					{
						path: 'company',
						children: [
							{
								path: ':companyId/delete',
								action: deleteCompanyAction(client),
							},
							{
								path: 'new',
								element: <NewCompany />,
								action: newCompanyAction(client),
							},
							{
								path: ':companyId/edit',
								element: <EditCompanyDashboard />,
								loader: editCompanyDashboardLoader(client),
								action: editCompanyDashboardAction(client),
							},
						],
					},
				],
			},
		],
	}) satisfies RouteObject

import { type QueryClient } from '@tanstack/react-query'
import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import DeleteConfirm from '#src/components/delete-confirm'
import { Icon } from '#src/components/ui/icon'
import { deleteCompany } from '#src/routes/admin/mutations'
import { companyKeys } from '#src/routes/company/queries'
import { routes } from '#src/utils/routes'

export const action =
	(queryClient: QueryClient) =>
	async ({ params }: ActionFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		await deleteCompany(params.companyId)

		await queryClient.invalidateQueries({
			queryKey: companyKeys.all,
		})

		throw redirect(routes.admin.index)
	}

export function DeleteCompanyForm({ companyId }: { companyId: string }) {
	return (
		<DeleteConfirm
			trigger={
				<button className="inline-flex items-center justify-center">
					<Icon name="trash" size="font" />
				</button>
			}
			action={routes.admin.company.delete({ companyId })}
			title="Delete company?"
			description="This action cannot be undone. This will permanently delete the company and any associated data such as ecosystems, verticals, personas etc."
		/>
	)
}

import { useQuery, useQueryClient, type QueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { type LoaderFunctionArgs, Outlet, useLoaderData, useRevalidator } from 'react-router-dom'
import { z } from 'zod'
import { EmptyStateCard } from '#src/components'
import Markdown from '#src/components/markdown.js'
import { PageLayout } from '#src/components/ui/PageLayout'
import { Tabs, TabsList, TabTrigger, TabContent } from '#src/components/ui/tabs'
import { userQuery } from '#src/routes/auth/queries'
import { managerAccessSessionDetailsQuery } from '#src/routes/enable/roleplay/scenario/queries'
import { canSee, cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import { conversationQuery, roleplaySessionKeys, roleplaySessionMessagesQuery } from './queries'
import { SessionDataSchema, SessionMessagesSchema } from './schema'
import { ChatLoader } from './index'

export type RoleplayReviewLoaderResponse = Awaited<ReturnType<ReturnType<typeof loader>>>

export const RoleplayReviewLoaderResponseSchema = z.object({
	handle: z.object({
		companyId: z.string(),
		conversationId: z.string(),
		canDeleteChat: z.boolean().optional(),
	}),
	conversationData: SessionDataSchema,
	messages: SessionMessagesSchema,
})

export const loader =
	(queryClient: QueryClient) =>
	async ({ params, request }: LoaderFunctionArgs) => {
		if (!params.companyId || !params.conversationId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})
		const user = await queryClient.fetchQuery(userQuery())

		const hasUserAccess = canSee(['user'], user)
		const scenarioId = new URL(request.url).searchParams.get('scenarioId')

		const conversationData =
			hasUserAccess && scenarioId
				? await queryClient.fetchQuery(
						managerAccessSessionDetailsQuery(params.companyId, scenarioId, params.conversationId),
					)
				: await queryClient.fetchQuery(conversationQuery(params.companyId, params.conversationId))

		const messages = await queryClient.fetchQuery(roleplaySessionMessagesQuery(params.companyId, params.conversationId))

		return {
			handle: {
				companyId: params.companyId,
				conversationId: params.conversationId,
				canDeleteChat: Boolean(conversationData?.conversation.canDelete),
			},
			conversationId: params.conversationId,
			conversationData,
			messages,
		}
	}

export default function RoleplayReview() {
	const {
		conversationData,
		messages,
		handle: { companyId },
	} = useLoaderData() as RoleplayReviewLoaderResponse

	if (['Pending', 'Evaluating'].includes(conversationData?.conversation.evaluation?.status ?? ''))
		return (
			<>
				<RoleplayEvaluating />
				<Outlet />
			</>
		)

	if (messages.length === 0)
		return (
			<PageLayout>
				<EmptyStateCard
					icon="user-service-desk"
					title="This roleplay session does not have any messages"
					actions={[
						{
							title: 'Back to home',
							to: routes.enable.roleplay.index({
								companyId,
							}),
						},
					]}
				/>

				<Outlet />
			</PageLayout>
		)

	return (
		<PageLayout>
			<Tabs defaultValue="evaluation">
				<TabsList className="max-w-screen-md">
					<TabTrigger value="evaluation">Evaluation</TabTrigger>
					<TabTrigger value="transcript">Transcript</TabTrigger>
				</TabsList>
				<TabContent value="evaluation" className="mx-auto max-w-screen-md">
					<RoleplayEvaluation />
				</TabContent>
				<TabContent value="transcript" className="mx-auto max-w-screen-md">
					<RoleplayTranscript />
				</TabContent>
			</Tabs>
		</PageLayout>
	)
}

export function RoleplayEvaluation() {
	const { conversationData } = useLoaderData() as RoleplayReviewLoaderResponse

	switch (conversationData?.conversation.evaluation?.status) {
		case 'Crashed':
			return <Markdown className="text-body-md text-status-danger-fg">Something went wrong with evaluation.</Markdown>
		default:
			return <Markdown className="text-body-md">{conversationData?.conversation.evaluation?.value}</Markdown>
	}
}

export function RoleplayTranscript() {
	const { messages } = useLoaderData() as RoleplayReviewLoaderResponse

	let lastEnumeration = 1
	const enumeratedMessages = messages.map(message => ({
		...message,
		enumeration: message.isAi ? null : lastEnumeration++,
	}))

	return (
		<div className="flex flex-col items-start gap-6">
			{enumeratedMessages.map(({ id, message, isAi, enumeration }) => (
				<div
					key={id}
					className={cn(
						'w-full text-body-lg',
						isAi ? 'rounded-lg bg-brand-1-bg p-4 text-brand-1-fg' : 'text-neutral-2-fg',
					)}
				>
					{!isAi ? `${enumeration}. ${message}` : message}
				</div>
			))}
		</div>
	)
}

function useRoleplayEvaluationRevalidator() {
	const params = useParsedRouteParams(['companyId', 'conversationId'])
	const revalidator = useRevalidator()
	const queryClient = useQueryClient()

	const { data: conversationData } = useQuery({
		...conversationQuery(params.companyId, params.conversationId),
		refetchInterval: ({ state: { data } }) => {
			if (['Pending', 'Evaluating'].includes(data?.conversation.evaluation?.status ?? '')) {
				return 15 * 1000 // 15s
			} else return false
		},
		refetchIntervalInBackground: true,
	})

	useEffect(() => {
		const revalidate = async () => {
			await queryClient.invalidateQueries({
				queryKey: roleplaySessionKeys.all,
			})

			revalidator.revalidate()
		}
		if (conversationData?.conversation.evaluation?.status === 'Completed') {
			void revalidate()
		}
	}, [conversationData, revalidator, queryClient])

	return {
		status: conversationData?.conversation.evaluation?.status,
		value: conversationData?.conversation.evaluation?.value,
	}
}

function RoleplayEvaluating() {
	useRoleplayEvaluationRevalidator()

	return (
		<ChatLoader
			animate={true}
			message="Generating results..."
			subMessage="It might take up to 3 minutes or more"
			className="h-full w-full gap-4 overflow-hidden"
		/>
	)
}

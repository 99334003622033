import { type QueryClient } from '@tanstack/react-query'
import { type LoaderFunctionArgs } from 'react-router-dom'
import { userQuery } from '#src/routes/auth/queries'
import { checkIsAdminSession, checkIsUserSession } from '#src/utils/misc'

export type SettingsLoaderResponse = Awaited<ReturnType<ReturnType<typeof loader>>>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const user = await queryClient.fetchQuery(userQuery())
		const adminSession = checkIsAdminSession(user.roles)
		const userSession = checkIsUserSession(user.roles)

		if (!adminSession && !userSession) {
			throw new Response('Page Not Found', {
				status: 404,
				statusText: 'Not Found',
			})
		}

		return {
			handle: {
				companyId: params.companyId,
			},
		}
	}

import { type LoaderFunctionArgs } from 'react-router-dom'
import { showToast } from '#src/context/ToastContext'
import { signalUsageExampleMutation } from '../mutations'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ params }: LoaderFunctionArgs) => {
	if (!params.companyId || !params.signalId || !params.personaId)
		throw new Response('Missing parameters', {
			status: 400,
			statusText: 'Bad Request',
		})
	try {
		return await signalUsageExampleMutation(params.companyId, params.personaId, params.signalId)
	} catch (e) {
		const res = (e as Response).clone()
		const body = (await res.json()) as { success: boolean; message?: string }
		showToast({
			message: body?.message ?? res?.statusText,
			type: 'error',
		})
		return { ok: false }
	}
}

import { type QueryClient } from '@tanstack/react-query'
import { type LoaderFunctionArgs } from 'react-router-dom'
import { showToast } from '#src/context/ToastContext'
import { userQuery } from '#src/routes/auth/queries'
import { companyQuery, statsQuery } from '#src/routes/company/queries'
import { statisticsQuery } from '#src/routes/lead-research-agent/queries'

export type LoaderRes = Awaited<ReturnType<ReturnType<typeof loader>>>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const company = await queryClient.fetchQuery(companyQuery(params.companyId))

		if (!company)
			throw new Response('Company Not Found', {
				status: 404,
				statusText: 'Not Found',
			})

		let lraStats = {
			inQueueCount: 0,
			inProgressCount: 0,
			completedCount: 0,
		}
		try {
			lraStats = await queryClient.fetchQuery(statisticsQuery(params.companyId))
		} catch (e) {
			showToast({
				type: 'error',
				message: 'LRA stats failed to load',
			})
		}

		return {
			values: { companyId: params.companyId },
			data: {
				company,
				stats: await queryClient.fetchQuery(statsQuery(params.companyId)),
				lraStats,
				user: await queryClient.fetchQuery(userQuery()),
			},
		}
	}

import { z } from 'zod'

export enum MessageStatus {
	Sending = 'sending',
	Error = 'error',
	Generating = 'generating',
}

export const ConversationSchema = z.object({
	id: z.string(),
	name: z.string(),
	user: z.string(),
	createdAt: z.string(),
	canDelete: z.boolean().optional(),
	type: z.string(),
	status: z.enum(['unread', 'initializing', 'ready', 'closed', 'waiting_for_contact', 'crashed']),
	lastMessage: z.string().nullable(),
})

export const ConversationDataSchema = z.object({
	conversation: ConversationSchema,
	participant: z.object({
		name: z.string(),
		status: z.string(),
	}),
	writable: z.boolean(),
})

export const MessageSchema = z.object({
	message: z.string(),
})

export const ChatMessageSchema = z
	.object({
		id: z.number().or(z.string()),
		author: z.string().optional(),
		isAi: z.boolean(),
		createdAt: z.string().optional(),
		status: z.enum([MessageStatus.Sending, MessageStatus.Error, MessageStatus.Generating]).optional(),
	})
	.merge(MessageSchema)

export const ChatSchema = ChatMessageSchema.array()

export const ChatsSchema = z
	.object({
		conversation: ConversationSchema,
		participant: z.object({
			info: z.object({ title: z.string().optional().nullable() }),
			persona: z.object({ type: z.string().optional().nullable() }),
			type: z.string(),
		}),
		lastMessage: z.string().optional(),
	})
	.array()

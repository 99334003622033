import { type QueryClient } from '@tanstack/react-query'
import { Link, NavLink, redirect, useLoaderData, type LoaderFunctionArgs } from 'react-router-dom'
import ContactAvatar, { avatarVariantByPersonaType } from '#src/components/chat/avatar'
import Markdown from '#src/components/markdown'
import { Icon } from '#src/components/ui/icon'
import { Sidebar, SidebarHeader } from '#src/components/ui/sidebar'
import { conversationQuery } from '#src/routes/enable/chat/queries'
import { cn, formatPersonaTypeAbbr } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

export type PersonaInfoLoaderResponse = Awaited<ReturnType<ReturnType<typeof loader>>>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId || !params.conversationId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		if (!params.properties)
			throw redirect(
				routes.enable.copilot.persona({
					companyId: params.companyId,
					conversationId: params.conversationId,
					properties: 'pain-points',
				}),
			)

		const conversationData = await queryClient.fetchQuery(conversationQuery(params.companyId, params.conversationId))

		return {
			companyId: params.companyId,
			conversationId: params.conversationId,
			properties: params.properties,
			conversationData,
		}
	}

export default function Persona() {
	const { companyId, conversationId, conversationData, properties } = useLoaderData() as PersonaInfoLoaderResponse

	return (
		<div className="w-[28rem]">
			<Sidebar
				header={
					<>
						<SidebarHeader>
							<h1 className="flex items-center gap-1 text-body-md font-semibold text-neutral-1-fg">
								<Icon name="user" size="font" />
								Persona Info
							</h1>
							<Link
								className="inline-flex"
								to={routes.enable.copilot.index({
									companyId,
									conversationId,
								})}
							>
								<Icon name="cross-1" />
							</Link>
						</SidebarHeader>
						<section className="sticky top-[44px] z-[50] bg-neutral-1-bg">
							<div className="relative grid w-full grid-cols-[2.5rem,1fr] gap-1.5 bg-transparent px-4 py-4">
								{conversationData.participant.persona.type ? (
									<ContactAvatar
										className="inline-flex shrink-0 items-center justify-center rounded-full"
										variant={avatarVariantByPersonaType(conversationData.participant.persona.type?.toLowerCase())}
										size="md"
										initial={formatPersonaTypeAbbr(conversationData.participant.persona.type ?? '')}
									/>
								) : null}

								<div className="flex flex-col justify-center gap-0.5 overflow-hidden">
									<p className="flex items-center gap-0.5 truncate text-label-sm text-neutral-2-fg">
										{conversationData.participant.persona.expertise ?? ''}
									</p>
									<p className="flex items-center gap-0.5 truncate text-label-sm font-normal text-neutral-3-fg">
										<Icon name="ibm-cloud-pak" size="font" />
										{conversationData.participant.persona.ecosystem} /
										<Icon name="category" size="font" />
										{conversationData.participant.persona.vertical}
									</p>
								</div>
							</div>

							<ul className="flex w-full flex-nowrap items-center border-b-2 border-neutral-1-bd">
								<li className="relative w-1/3">
									<NavLink
										to={routes.enable.copilot.persona({
											companyId,
											conversationId,
											properties: 'pain-points',
										})}
										className={({ isActive }) =>
											cn(
												'group inline-block w-full py-3 text-center text-body-sm text-neutral-1-fg',
												isActive ? 'active' : '',
											)
										}
									>
										Pain Points
										<div className="absolute bottom-[-2px] h-2 w-full border-b-2 border-transparent transition-colors hover:border-brand-1-bd-hover group-hover:border-brand-1-bd-hover group-[.active]:border-brand-1-bd-selected" />
									</NavLink>
								</li>
								<li className="relative w-1/3">
									<NavLink
										to={routes.enable.copilot.persona({
											companyId,
											conversationId,
											properties: 'mapping',
										})}
										className={({ isActive }) =>
											cn(
												'group inline-block w-full py-3 text-center text-body-sm text-neutral-1-fg',
												isActive ? 'active' : '',
											)
										}
									>
										Persona Mapping
										<div className="absolute bottom-[-2px] h-2 w-full border-b-2 border-transparent transition-colors hover:border-brand-1-bd-hover group-hover:border-brand-1-bd-hover group-[.active]:border-brand-1-bd-selected" />
									</NavLink>
								</li>
								<li className="relative w-1/3">
									<NavLink
										to={routes.enable.copilot.persona({
											companyId,
											conversationId,
											properties: 'co-pilot-recos',
										})}
										className={({ isActive }) =>
											cn(
												'group inline-block w-full py-3 text-center text-body-sm text-neutral-1-fg',
												isActive ? 'active' : '',
											)
										}
									>
										Co-pilot recos
										<div className="absolute bottom-[-2px] h-2 w-full border-b-2 border-transparent transition-colors hover:border-brand-1-bd-hover group-hover:border-brand-1-bd-hover group-[.active]:border-brand-1-bd-selected" />
									</NavLink>
								</li>
							</ul>
						</section>
					</>
				}
				main={
					<>
						{properties === 'pain-points' && <Markdown>{conversationData.participant.mapping.pain}</Markdown>}
						{properties === 'mapping' && (
							<section className="flex flex-col gap-2">
								<h4 className="text-body-md font-semibold">Responsibilities</h4>
								<Markdown>{conversationData.participant.mapping.responsibilities}</Markdown>
								<h4 className="pt-2 text-body-md font-semibold">KPIs</h4>
								<Markdown>{conversationData.participant.mapping.kpis}</Markdown>
								<h4 className="pt-2 text-body-md font-semibold">Frustrations</h4>
								<Markdown>{conversationData.participant.mapping.frustrations}</Markdown>
								<h4 className="pt-2 text-body-md font-semibold">Value Propositions</h4>
								<Markdown>{conversationData.participant.mapping.valuePropositions}</Markdown>
								<h4 className="pt-2 text-body-md font-semibold">Objections Concerns</h4>
								<Markdown>{conversationData.participant.mapping.objectionsConcerns}</Markdown>
								<h4 className="pt-2 text-body-md font-semibold">Objection Addressing</h4>
								<Markdown>{conversationData.participant.mapping.objectionAddressing}</Markdown>
								<h4 className="pt-2 text-body-md font-semibold">Buyer Journey Info</h4>
								<Markdown>{conversationData.participant.mapping.buyerJourneyInfo}</Markdown>
								<h4 className="pt-2 text-body-md font-semibold">Buyer Journey Use Cases</h4>
								<Markdown>{conversationData.participant.mapping.buyerJourneyUseCases}</Markdown>
								<h4 className="pt-2 text-body-md font-semibold">Gain Information From</h4>
								<Markdown>{conversationData.participant.mapping.gainInformationFrom}</Markdown>
							</section>
						)}
						{properties === 'co-pilot-recos' && (
							<Markdown>{conversationData.participant.mapping.calibratedQuestions}</Markdown>
						)}
					</>
				}
			/>
		</div>
	)
}

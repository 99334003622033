import { type QueryClient } from '@tanstack/react-query'
import { type LoaderFunctionArgs } from 'react-router-dom'
import { userQuery } from '#src/routes/auth/queries'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem/queries'
import { personasExpertiseQuery, personasQuery } from '#src/routes/enable/personas/queries'
import { findEcosystemByVerticalId } from '#src/utils/misc'

export type LoaderResType = Awaited<ReturnType<ReturnType<typeof loader>>>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId || !params.verticalId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const [user, ecosystems] = await Promise.all([
			queryClient.fetchQuery(userQuery()),
			queryClient.fetchQuery(ecosystemsQuery(params.companyId)),
		])

		const ecosystem = findEcosystemByVerticalId(params.verticalId, ecosystems)

		if (!ecosystem) {
			throw new Response('Ecosystem not found', {
				status: 400,
				statusText: 'Bad Request',
			})
		}

		const [personas, expertises] = await Promise.all([
			queryClient.fetchQuery(
				personasQuery(params.companyId, {
					page: 1,
					ecosystem_id: String(ecosystem.id),
					vertical_id: params.verticalId,
				}),
			),
			queryClient.fetchQuery(
				personasExpertiseQuery(params.companyId, {
					ecosystem_id: String(ecosystem.id),
					vertical_id: params.verticalId,
				}),
			),
		])

		return {
			ecosystems,
			user,
			companyId: params.companyId,
			verticalId: params.verticalId,
			personas,
			expertises,
		}
	}

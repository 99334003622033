import { getFormProps, useForm } from '@conform-to/react'
import { type QueryClient } from '@tanstack/react-query'
import { Link, useLoaderData, type LoaderFunctionArgs, Form, type ActionFunctionArgs, redirect } from 'react-router-dom'
import { z } from 'zod'
import ContactAvatar from '#src/components/chat/avatar'
import ProgressBar from '#src/components/ProgressBar'
import StatusDisplay from '#src/components/StatusDisplay'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { PageLayout } from '#src/components/ui/PageLayout'
import { PagePaginator } from '#src/components/ui/PagePaginator'
import { StatusButton } from '#src/components/ui/status-button'
import { Surface } from '#src/components/ui/surface'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem/queries'
import { createScenarioTestSession } from '#src/routes/enable/roleplay/scenario/mutations'
import { type ParticipantType } from '#src/routes/enable/roleplay/scenario/schema'
import { Status } from '#src/routes/enable/roleplay/schema'
import { roleplaySessionKeys } from '#src/routes/enable/roleplay/session/queries'
import { formatDateToReadableString } from '#src/utils/date'
import { calculatePct, formatAvatarAbbr, useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import usePaginator from '#src/utils/usePaginator'
import { ScenarioTabs } from './components/ScenarioTabs'
import { roleplayScenarioKeys, roleplayScenarioQuery, scenarioTemplatesQuery, voicesQuery } from './queries'

export type RoleplayScenarioLoaderResponse = Awaited<ReturnType<ReturnType<typeof loader>>>

export const newTestSessionAction =
	(queryClient: QueryClient) =>
	async ({ params }: ActionFunctionArgs) => {
		if (!params.companyId || !params.scenarioId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const data = await createScenarioTestSession(params.companyId, params.scenarioId)

		await queryClient.invalidateQueries({
			queryKey: roleplayScenarioKeys.detail(params.companyId, params.scenarioId),
		})
		await queryClient.invalidateQueries({
			queryKey: roleplaySessionKeys.all,
		})

		return redirect(
			routes.enable.roleplay.session.index({
				companyId: data.company,
				conversationId: data.conversationId,
			}),
		)
	}

export const RoleplayScenarioLoaderResponseSchema = z.object({
	handle: z.object({
		companyId: z.string(),
		scenario: z.string(),
	}),
})

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId || !params.scenarioId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const scenario = await queryClient.fetchQuery(roleplayScenarioQuery(params.companyId, params.scenarioId))
		const voices = await queryClient.fetchQuery(voicesQuery(params.companyId))

		const ecosystems = await queryClient.fetchQuery(ecosystemsQuery(params.companyId))
		const scenarioTemplates = await queryClient.fetchQuery(scenarioTemplatesQuery(params.companyId))

		return {
			handle: {
				companyId: params.companyId,
				scenario: scenario.name ?? scenario.title,
			},
			scenario,
			voices,
			ecosystems,
			scenarioTemplates,
		}
	}

export default function ScenarioDetails() {
	const { companyId, scenarioId } = useParsedRouteParams(['companyId', 'scenarioId'])
	const { scenario } = useLoaderData() as RoleplayScenarioLoaderResponse

	const method = 'POST'
	const formId = 'new-test-session'
	const [form] = useForm({
		id: formId,
	})

	const newTestScenarioSessionRoute = routes.enable.roleplay.scenario.newTestSession({
		companyId,
		scenarioId,
	})

	const isPending = useIsPending({
		formAction: newTestScenarioSessionRoute,
		formMethod: method,
	})

	return (
		<PageLayout
			title={scenario.name ?? scenario.title ?? 'Scenario Details'}
			{...(!!scenario.personaMapping && {
				actions: [
					<Form
						key={`test-roleplay-session`}
						method={method}
						{...getFormProps(form)}
						action={newTestScenarioSessionRoute}
					>
						<StatusButton
							status={isPending ? 'pending' : 'idle'}
							className="flex gap-4"
							type="submit"
							disabled={isPending}
							size="md"
							variant="outline"
						>
							<Icon name="play-filled-alt" className="mr-1.5" />
							Test Roleplay session
						</StatusButton>
					</Form>,
				],
			})}
		>
			<Dashboard />
			<Participants />
		</PageLayout>
	)
}

function Dashboard() {
	const { scenario, voices } = useLoaderData() as RoleplayScenarioLoaderResponse

	return (
		<Surface className="w-full">
			<ScenarioTabs scenario={scenario} voices={voices} />
		</Surface>
	)
}

function Participants() {
	const { scenario } = useLoaderData() as RoleplayScenarioLoaderResponse
	const { companyId, scenarioId } = useParsedRouteParams(['companyId', 'scenarioId'])
	const {
		currentPage,
		setCurrentPage,
		pagedData: participantsData,
		totalPages,
	} = usePaginator<ParticipantType>({ data: scenario.sessions })

	const sessionsCount = scenario.sessions.length
	const completedSessionsCount = scenario.sessions?.filter(session => session.status === Status.Closed).length ?? 0
	const hasScenarioSessions = !!scenario.sessions.length

	return (
		<Surface className="w-full p-10">
			<div className="flex justify-between pb-6">
				<div className="flex items-center gap-4">
					<h2 className="flex text-title-md text-neutral-1-fg">Participants</h2>
					{hasScenarioSessions && (
						<div className="flex w-[150px] items-center gap-1.5">
							<ProgressBar percent={calculatePct(completedSessionsCount, sessionsCount)} variant="status" />
							<span className="text-body-sm text-neutral-3-fg">{`${completedSessionsCount}/${sessionsCount}`}</span>
						</div>
					)}
				</div>
				{hasScenarioSessions && (
					<Button asChild className="flex flex-nowrap items-center gap-2">
						<Link
							to={routes.enable.roleplay.scenario.invite({
								companyId,
								scenarioId,
							})}
						>
							<Icon name="add-alt" />
							Invite Participants
						</Link>
					</Button>
				)}
			</div>
			{!participantsData?.length ? (
				<Surface className="flex min-h-[360px] w-full flex-col items-center justify-center gap-4 border-none bg-neutral-2-bg">
					<Icon name="group" size="xxl" className="text-brand-1-fg" />
					<h1 className="text-center text-body-lg text-neutral-2-fg">You didn’t invited any team participants yet</h1>
					<Button asChild className="flex flex-nowrap items-center gap-2">
						<Link
							to={routes.enable.roleplay.scenario.invite({
								companyId,
								scenarioId,
							})}
						>
							<Icon name="add-alt" />
							Invite Participants
						</Link>
					</Button>
				</Surface>
			) : (
				<PagePaginator currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages}>
					<table className="w-full">
						<thead className="text-left">
							<tr>
								<th className="py-3 text-label-md text-neutral-2-fg">User</th>
								<th className="py-3 text-label-md text-neutral-2-fg">Status</th>
								<th className="py-3 text-label-md text-neutral-2-fg">Invited</th>
								<th className="py-3 text-label-md text-neutral-2-fg">Completed</th>
								<th className="py-3 text-label-md text-neutral-2-fg">Actions</th>
							</tr>
						</thead>
						<tbody className="text-left">
							{participantsData.map(session => (
								<tr key={session.id}>
									<td className="py-4">
										<div className="flex items-center gap-2 text-body-sm font-medium text-neutral-2-fg">
											<ContactAvatar
												className="inline-flex shrink-0 items-center justify-center rounded-full"
												variant="gray"
												size="sm"
												initial={formatAvatarAbbr(session.username)}
											/>
											{session.username}
										</div>
									</td>
									<td className="py-4 pr-3 text-left">
										<p className="text-body-md text-neutral-1-fg">
											<StatusDisplay status={session.status} />
										</p>
									</td>
									<td className="py-4 pr-3 text-left">
										<p className="text-body-md text-neutral-1-fg">{formatDateToReadableString(session.createdAt)}</p>
									</td>
									<td className="py-4 pr-3 text-left">
										<p className="text-body-md text-neutral-1-fg">
											{session.completedAt && formatDateToReadableString(session.completedAt)}
										</p>
									</td>
									<td className="py-4 pr-3 text-left">
										<p className="text-body-md text-neutral-1-fg">
											{session.status === Status.Closed && (
												<Link
													to={{
														pathname: routes.enable.roleplay.session.review({
															companyId,
															conversationId: session.id,
														}),
														search: `?scenarioId=${scenarioId}`,
													}}
													className="flex flex-nowrap items-center gap-1 bg-transparent py-2 text-button-sm text-link hover:text-link-hover active:text-link-pressed"
												>
													Review
												</Link>
											)}
										</p>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</PagePaginator>
			)}
		</Surface>
	)
}

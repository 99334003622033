import { useLoaderData } from 'react-router-dom'
import { Button } from '#src/components/ui/button.js'
import { Surface } from '#src/components/ui/surface'
import { type AuthResetPasswordLoaderRes } from '#src/routes/auth/routes/reset-password'
import { ResetPasswordForm } from '#src/routes/auth/views/components'

export const View = () => {
	const { user } = useLoaderData() as AuthResetPasswordLoaderRes

	if (user.accepted) {
		return (
			<main className="grid h-full w-full grid-cols-1 grid-rows-1 items-center justify-items-center">
				<Surface className="flex w-full max-w-lg flex-col items-center gap-8 px-16 py-8">
					<div className="flex flex-col gap-2 text-center">
						<h1 className="text-title-md text-neutral-1-fg">Invitation already accepted</h1>
						<p className="text-body-md text-neutral-2-fg">
							If you think this is a mistake or if you have trouble logging into the workspace, please contact the
							workspace admins or support.
						</p>
					</div>

					<div className="text-center">
						<Button asChild className="px-8">
							<a href="/src/routes/auth/views/login">Go to Login</a>
						</Button>
					</div>
				</Surface>
			</main>
		)
	}

	return <ResetPasswordForm />
}

import { type QueryClient } from '@tanstack/react-query'
import { type LoaderFunctionArgs } from 'react-router-dom'
import { z } from 'zod'
import { companyQuery } from '#src/routes/company/queries'
import { CompanySchema } from '#src/routes/company/schema'

export const LoaderSchema = z.object({
	data: z.object({
		company: CompanySchema,
	}),
})

export type LoaderResType = Awaited<ReturnType<ReturnType<typeof loader>>>

export type LoaderRes = z.infer<typeof LoaderSchema>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs): Promise<LoaderRes> => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const company = await queryClient.fetchQuery(companyQuery(params.companyId))

		if (!company) {
			throw new Response('Company Not Found', {
				status: 404,
				statusText: 'Not Found',
			})
		}

		return {
			data: { company },
		}
	}

import { format } from 'date-fns'

/**
 * Formats a given date into a human-readable string.
 *
 * Example output: "12 August 2024"
 */
export const formatDateToReadableString = (date: Date | string): string => {
	return format(new Date(date), 'dd MMMM, yyyy')
}

import { useQuery } from '@tanstack/react-query'
import { useLoaderData } from 'react-router-dom'
import { Surface } from '#src/components/ui/surface'
import { PERSONA_DETAILS_REFRESH_INTERVAL } from '#src/constants/refetchIntervals'
import { personaQuery } from '#src/routes/calibrate/personas/queries'
import { type PersonasDetailsLoaderResType } from '../routes/details'
import { PersonasDetailsBody, PersonasDetailsHeader } from './components/details'

export const Details = () => {
	const { persona, companyId } = useLoaderData() as PersonasDetailsLoaderResType

	const { data: personaDetails } = useQuery({
		...personaQuery(companyId, String(persona.id)),
		initialData: persona,
		refetchInterval: ({ state: { data } }) =>
			data?.prompt?.status && ['Pending', 'In Progress'].includes(data.prompt.status)
				? PERSONA_DETAILS_REFRESH_INTERVAL
				: false,
		refetchOnWindowFocus: true,
		throwOnError: true,
	})

	return (
		<Surface className="flex w-full flex-col self-start p-0">
			<PersonasDetailsHeader persona={personaDetails} />
			<PersonasDetailsBody persona={personaDetails} />
		</Surface>
	)
}

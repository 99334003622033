import { cn } from '#src/utils/misc'

export const inputCommonClasses = cn(
	'transition-all',
	'flex h-10 w-full rounded px-3 py-2.5',
	'bg-transparent disabled:bg-neutral-2-bg',
	'border border-neutral-2-bd outline-none hover:border-neutral-2-bd-selected focus-visible:border-brand-2-bd aria-[invalid]:border-status-danger-bd',
	'text-body-md text-neutral-1-fg placeholder:text-neutral-3-fg disabled:text-neutral-inverse-fg-disabled',
	'read-only:cursor-not-allowed disabled:cursor-not-allowed disabled:opacity-50',
)

export const fileInputClasses = cn('file:border-0 file:bg-transparent file:text-button-sm')

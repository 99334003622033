import { useState } from 'react'
import { useFetcher } from 'react-router-dom'
import { Dropdown, DropdownItem } from '#src/components/ui/dropdown'
import { Icon } from '#src/components/ui/icon'
import { Toast, ToastDescription, ToastViewport } from '#src/components/ui/toast'
import { type PrioritizeItemAssignActionRes } from '#src/routes/prioritize/routes/assign'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'

export const SignalTemplateContextMenu = ({ signalId }: { signalId: number }) => {
	const [open, setOpen] = useState(false)
	const params = useParsedRouteParams(['companyId'])
	const fetcher = useFetcher<PrioritizeItemAssignActionRes>()
	const action = routes.prioritize.signal.assign({
		companyId: params.companyId,
		signalId: signalId.toString(),
	})

	return (
		<>
			<Dropdown
				open={open}
				onOpenChange={setOpen}
				trigger={
					<button
						type="button"
						data-type="contextmenu"
						className="flex h-5 w-5 items-center justify-center rounded-[2px] outline-none transition-colors hover:bg-neutral-2-bg-hover radix-state-open:bg-neutral-2-bg"
					>
						<Icon name="overflow-menu-horizontal" size="sm" />
					</button>
				}
				contentProps={{
					side: 'bottom',
					align: 'end',
				}}
			>
				<DropdownItem asChild className="justify-start">
					<button
						onClick={() => {
							fetcher.submit({ intent: 'unmap-all' }, { action, method: 'PATCH' })
							setOpen(false)
						}}
						disabled={fetcher.state !== 'idle' && fetcher.formData?.get('intent') === 'unmap-all'}
					>
						<Icon name="subtract-alt" size="sm" />
						{fetcher.state === 'idle' || fetcher.formData?.get('intent') !== 'unmap-all' ? 'Unmap all' : 'Unmapping...'}
					</button>
				</DropdownItem>
			</Dropdown>
			<Toast shouldOpen={fetcher.state === 'loading' && !fetcher.data?.ok} duration={3000}>
				<ToastDescription className="flex items-center gap-3">
					<Icon name="error-filled" size="md" className="text-red-60" />
					{fetcher.data?.action === 'unmap-all'
						? 'Error while unmapping all personas. Try again.'
						: fetcher.data?.action === 'map'
							? 'Error while mapping all personas. Try again.'
							: 'Error while unmapping personas. Try again.'}
				</ToastDescription>
			</Toast>
			<Toast
				shouldOpen={fetcher.state === 'loading' && !!fetcher.data?.ok && ['unmap-all'].includes(fetcher.data?.action)}
				duration={3000}
			>
				<ToastDescription className="flex items-center gap-3">
					<Icon name="checkmark-filled" size="md" className="text-green-70" />
					All personas successfully unmapped
				</ToastDescription>
			</Toast>
			<ToastViewport />
		</>
	)
}

import { type QueryClient } from '@tanstack/react-query'
import { type RouteObject } from 'react-router-dom'
import DefaultSidebarLayout from '#src/layouts/DefaultSidebarLayout'
import { CompanyDashboardView, companyDashboardLoader } from '#src/routes/company/routes/dashboard'
import { companyEditAction, companyEditLoader } from '#src/routes/company/routes/edit'
import RouteBoundary from '#src/routes/route-boundary'

export default (client: QueryClient) =>
	({
		path: '/company/:companyId',
		element: <DefaultSidebarLayout />,
		children: [
			{
				index: true,
				element: <CompanyDashboardView />,
				errorElement: <RouteBoundary />,
				loader: companyDashboardLoader(client),
			},
			{
				path: 'edit',
				loader: companyEditLoader(client),
				action: companyEditAction(client),
			},
		],
	}) satisfies RouteObject

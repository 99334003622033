import { parseWithZod } from '@conform-to/zod'
import { type QueryClient } from '@tanstack/react-query'
import { type ActionFunctionArgs } from 'react-router-dom'
import { updateSignal } from '../mutations'
import { signalsKeys } from '../queries'
import { SignalConfigFormSchema } from '../schema'

export type ActionRes = Awaited<ReturnType<ReturnType<typeof action>>>

export const action =
	(queryClient: QueryClient) =>
	async ({ params, request }: ActionFunctionArgs) => {
		if (!params.companyId || !params.signalId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const formData = await request.formData()

		const submission = parseWithZod(formData, {
			schema: SignalConfigFormSchema,
		})

		if (submission.status !== 'success') {
			throw Error('Failed to parse form data')
		}

		await updateSignal(params.companyId, params.signalId, {
			...submission.value,
		})

		void queryClient.invalidateQueries({
			queryKey: signalsKeys.all,
		})

		return { result: submission.reply() }
	}

import { z } from 'zod'

export const MemberSchema = z.object({
	email: z.string(),
	roles: z.string().array(),
	accepted: z.boolean().optional(),
})

export const MembersSchema = MemberSchema.array()

export const RolesSchema = z.record(z.string(), z.string())

export const InviteFormSchema = z.object({
	email: z.string().email(),
	role: z.enum(['user', 'read-only', 'enable-only']),
})

export const InviteAPISchema = z.object({
	token: z.string(),
})

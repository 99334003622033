import * as React from 'react'

import { fileInputClasses, inputCommonClasses } from '#src/theme'
import { cn } from '#src/utils/misc'

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(({ className, ...props }, ref) => {
	return (
		<textarea
			className={cn(inputCommonClasses, fileInputClasses, 'flex h-full w-full rounded px-3 py-2.5', className)}
			rows={props.rows ?? 3}
			ref={ref}
			{...props}
		/>
	)
})
Textarea.displayName = 'Textarea'

export { Textarea }

import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import { type RadioGroupProps } from '@radix-ui/react-radio-group'
import { clsx } from 'clsx'
import { type LabelHTMLAttributes, type ReactNode, useId } from 'react'
import { ErrorList, type ListOfErrors } from '#src/components/forms/index'
import { Icon, type IconName } from '#src/components/ui/icon'
import { Label } from '#src/components/ui/label'
import { cn } from '#src/utils/misc'

type Props = {
	labelProps?: LabelHTMLAttributes<HTMLLabelElement>
	inputProps: RadioGroupProps & {
		name: string
		form: string
		placeholder?: string
		key?: string
	}
	options: {
		value: string
		label: ReactNode
		disabled?: boolean
		icon?: IconName
		infoText?: string
	}[]
	errors?: ListOfErrors
	className?: string
	onChange?: (data: string) => void
}

export const RadioGroup = (props: Props) => {
	const { labelProps, inputProps, options, errors, className, onChange } = props

	const fallbackId = useId()
	const id = inputProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined

	return (
		<div className={cn('flex flex-col gap-1', className)}>
			{labelProps && <Label htmlFor={id} {...labelProps} />}
			<RadioGroupPrimitive.Root
				className={clsx('flex flex-wrap gap-2', options?.length > 2 ? 'flex-col' : 'flex-row')}
				{...inputProps}
				key={undefined}
				onChange={e => {
					inputProps.onChange?.(e)
					const target = e.target as HTMLInputElement
					if (target.checked) {
						onChange?.(target.value)
					}
				}}
			>
				{options.map(option => (
					<label
						key={`radio-group${id}-${option.value}`}
						className="flex flex-1 items-center gap-3 rounded border border-neutral-2-bd px-4 py-3 text-neutral-2-fg has-[input:checked]:border-brand-1-bd-hover has-[input:checked]:bg-brand-1-bg aria-disabled:opacity-50"
						htmlFor={`${id}-${option.value}`}
						aria-disabled={option.disabled}
					>
						<RadioGroupPrimitive.Item
							value={option.value}
							id={`${id}-${option.value}`}
							className="flex h-4.5 w-4.5 items-center justify-center rounded-full border-2 border-neutral-3-bd data-[state=checked]:border-brand-1-bd-hover"
							disabled={option.disabled}
						>
							<RadioGroupPrimitive.Indicator className="block h-2 w-2 rounded-full bg-brand-1-bd-hover" />
						</RadioGroupPrimitive.Item>
						<div className="flex flex-row items-center gap-2 text-body-md">
							{!!option.icon && <Icon name={option.icon} className="block h-6 w-6 text-neutral-3-fg" />}
							<div className="flex flex-1 flex-col">
								<p>{option.label}</p>
								{!!option.infoText && <small className="text-body-sm text-neutral-3-fg">{option.infoText}</small>}
							</div>
						</div>
					</label>
				))}
			</RadioGroupPrimitive.Root>

			{errorId ? (
				<div className="min-h-[32px] pb-3">
					<ErrorList id={errorId} errors={errors} />
				</div>
			) : null}
		</div>
	)
}

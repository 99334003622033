import { type QueryClient } from '@tanstack/react-query'
import { type RouteObject } from 'react-router-dom'
import DefaultSidebarLayout from '#src/layouts/DefaultSidebarLayout'
import { loader as rootLoader } from '#src/routes/prioritize/loaders/root'
import { prioritizeItemAssignAction } from '#src/routes/prioritize/routes/assign'
import { prioritizeItemExampleAction } from '#src/routes/prioritize/routes/example'
import {
	prioritizeItemLoader,
	type PrioritizeItemLoaderResType,
	PrioritizeItemView,
} from '#src/routes/prioritize/routes/item'
import { prioritizeListLoader, PrioritizeListView } from '#src/routes/prioritize/routes/list'
import { prioritizeListFiltersAction } from '#src/routes/prioritize/routes/list-filters'
import { prioritizeItemSaveAction } from '#src/routes/prioritize/routes/save'
import EnrichPanel from '#src/routes/prioritize/views/components/item/EnrichPanel'
import RouteBoundary from '#src/routes/route-boundary'
import { routes } from '#src/utils/routes'

export default (client: QueryClient) =>
	({
		id: 'prioritize-module',
		path: '/prioritize/:companyId',
		loader: rootLoader(client),
		element: <DefaultSidebarLayout />,
		handle: {
			crumb: (data: PrioritizeItemLoaderResType) => [
				{
					to: routes.prioritize.index({
						companyId: data.handle.companyId,
					}),
					label: 'Prioritize',
					icon: 'upstream',
				},
			],
		},
		children: [
			{
				path: '',
				element: <PrioritizeListView />,
				errorElement: <RouteBoundary />,
				loader: prioritizeListLoader(client),
				handle: {
					layout: {
						noPadding: true,
						fullWidth: true,
					},
				},
				children: [
					{
						path: 'filters',
						action: prioritizeListFiltersAction(client),
					},
				],
			},
			{
				path: 'details/:signalId',
				element: <PrioritizeItemView />,
				errorElement: <RouteBoundary />,
				loader: prioritizeItemLoader(client),
				handle: {
					layout: {
						noPadding: true,
						enableHeader: true,
						fullWidth: true,
						sidePanelContent: <EnrichPanel />,
					},
					crumb: (data: PrioritizeItemLoaderResType) => [
						{
							to: routes.prioritize.signal.index({
								companyId: data.handle.companyId,
								signalId: data.data.signalTemplate.id.toString(),
							}),
							label: data.data.signalTemplate.name,
						},
					],
				},
				children: [
					{
						path: 'save',
						action: prioritizeItemSaveAction(client),
					},
					{
						path: 'assign',
						action: prioritizeItemAssignAction(client),
					},
					{
						path: 'example/:personaId',
						action: prioritizeItemExampleAction,
					},
				],
			},
		],
	}) satisfies RouteObject

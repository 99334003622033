import { type QueryClient } from '@tanstack/react-query'
import { format } from 'date-fns'
import { type LoaderFunctionArgs, useNavigate, useLoaderData, createSearchParams } from 'react-router-dom'
import { z } from 'zod'
import { EmptyStateCard } from '#src/components'
import ContactAvatar from '#src/components/chat/avatar'
import { Chip } from '#src/components/chip'
import { PersonaAvatar } from '#src/components/persona'
import ProgressBar from '#src/components/ProgressBar'
import Role from '#src/components/Role'
import { TrainingAgenda } from '#src/components/training-agenda'
import { Icon } from '#src/components/ui/icon'
import { PageLayout, type PageLayoutPropsAction } from '#src/components/ui/PageLayout'
import { Table } from '#src/components/ui/Table'
import { Tabs, TabsList, TabTrigger, TabContent } from '#src/components/ui/tabs'
import { EMPTY_PAGINATED_RESPONSE } from '#src/constants/responses'
import { userQuery } from '#src/routes/auth/queries'
import { UserSchema } from '#src/routes/auth/schema'
import { ScenariosAPIResSchema } from '#src/routes/enable/roleplay/scenario/schema'
import { formatDateToReadableString } from '#src/utils/date'
import {
	calculatePct,
	canSee,
	checkIsAdminSession,
	checkIsReadOnlySession,
	checkIsUserSession,
	cn,
	formatAvatarAbbr,
} from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import { roleplayScenariosQuery } from './scenario/queries'
import { Status, StatusSchema } from './schema'
import { conversationQuery, roleplaySessionsQuery } from './session/queries'
import { SessionDataSchema, SessionsAPIResSchema } from './session/schema'

export type RoleplayLoaderResponse = Awaited<ReturnType<ReturnType<typeof loader>>>

export const RoleplayLoaderResponseSchema = z.object({
	handle: z.object({
		companyId: z.string(),
	}),
	data: z.object({
		tab: z.string(),
		page: z.number(),
	}),
	conversationId: z.string().nullable().optional(),
	canRestartChat: z.boolean(),
	canDeleteChat: z.boolean().optional(),
	conversationData: SessionDataSchema.nullable(),
	conversationStatus: StatusSchema.nullable(),
	sessions: SessionsAPIResSchema,
	user: UserSchema,
	scenarios: ScenariosAPIResSchema,
})

export const loader =
	(queryClient: QueryClient) =>
	async ({ params, request }: LoaderFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const user = await queryClient.fetchQuery(userQuery())

		const tab = new URL(request.url).searchParams.get('tab') ?? 'scenarios'
		const qPage = parseInt(new URL(request.url).searchParams.get('page') ?? '1')
		const page = Number.isNaN(qPage) ? 1 : qPage

		const adminSession = canSee(['admin'], user)
		const userSession = canSee(['user'], user)
		const isReadOnlySession = checkIsReadOnlySession(user.roles)

		const sessions = await queryClient.fetchQuery(roleplaySessionsQuery(params.companyId, page))
		const scenarios =
			userSession && !isReadOnlySession
				? await queryClient.fetchQuery(roleplayScenariosQuery(params.companyId, page))
				: EMPTY_PAGINATED_RESPONSE

		// todo: cia reik atskirt i kita loaderi ta conversationa, nes cia tiesiog kose makalose
		if (!params.conversationId)
			return {
				handle: {
					companyId: params.companyId,
				},
				data: {
					tab,
					page,
				},
				conversationId: undefined,
				canRestartChat: false,
				conversationData: null,
				conversationStatus: null,
				sessions,
				scenarios,
				user,
			}

		const conversationData = await queryClient.fetchQuery(conversationQuery(params.companyId, params.conversationId))

		return {
			handle: {
				companyId: params.companyId,
			},
			conversationId: params.conversationId,
			conversationData,
			conversationStatus: conversationData?.conversation.status,
			sessions,
			scenarios,
			user,
			canRestartChat:
				[Status.Ready, Status.Crashed, Status.Closed].includes(conversationData?.conversation.status ?? '') &&
				adminSession,
			canDeleteChat: conversationData?.conversation.canDelete,
			data: {
				tab,
				page,
			},
		}
	}

export default function Roleplay() {
	const params = useParsedRouteParams(['companyId'])
	const {
		scenarios,
		sessions,
		user,
		data: { tab },
	} = useLoaderData() as RoleplayLoaderResponse
	const readOnlySession = checkIsReadOnlySession(user.roles)
	const userSession = checkIsUserSession(user.roles)
	const adminSession = checkIsAdminSession(user.roles)
	const navigate = useNavigate()

	return (
		<PageLayout
			title="Roleplay training"
			{...(!readOnlySession && {
				actions: [
					...(userSession || adminSession
						? ([
								{
									variant: 'default',
									to: routes.enable.roleplay.new.scenario({ companyId: params.companyId }),
									icon: 'user-multiple',
									title: 'New scenario',
								},
							] satisfies PageLayoutPropsAction[])
						: []),
					{
						variant: 'default',
						to: routes.enable.roleplay.new.session({ companyId: params.companyId }),
						icon: 'user',
						title: 'New session',
					},
				],
			})}
		>
			{!scenarios.data.length && !sessions.data.length ? (
				<EmptyStateCard
					icon="chat"
					title="You don't have any roleplay chats yet."
					{...(!readOnlySession && {
						actions: [
							...(userSession || adminSession
								? [
										{
											title: 'New scenario',
											to: routes.enable.roleplay.new.scenario({
												companyId: params.companyId,
											}),
										},
									]
								: []),
							{
								title: 'New session',
								to: routes.enable.roleplay.new.session({
									companyId: params.companyId,
								}),
							},
						],
					})}
				/>
			) : (
				<>
					<Role user={user} allow={['enable-only', 'read-only']} exact>
						<RoleplaySessions />
					</Role>
					<Role user={user} allow={['user']} hideIfReadOnly>
						<Tabs
							value={tab}
							onValueChange={value => {
								navigate({
									search: createSearchParams({
										tab: value,
									}).toString(),
								})
							}}
						>
							<TabsList>
								<TabTrigger value="scenarios">
									Scenarios
									<Chip variant="blueFilled" className="ml-1">
										{scenarios.meta.total}
									</Chip>
								</TabTrigger>
								<TabTrigger value="sessions">
									Sessions
									<Chip variant="blueFilled" className="ml-1">
										{sessions.meta.total}
									</Chip>
								</TabTrigger>
							</TabsList>
							<TabContent value="scenarios">
								<RoleplayScenarios />
							</TabContent>
							<TabContent value="sessions">
								<RoleplaySessions />
							</TabContent>
						</Tabs>
					</Role>
				</>
			)}
		</PageLayout>
	)
}

function RoleplaySessions() {
	const { companyId } = useParsedRouteParams(['companyId'])
	const navigate = useNavigate()
	const {
		sessions,
		user,
		data: { tab },
	} = useLoaderData() as RoleplayLoaderResponse

	if (!sessions.data.length) {
		return <EmptyStateCard icon="chat" title="You don't have any roleplay chats yet." />
	}

	return (
		<Table
			uniqueId="conversation.id"
			data={sessions.data}
			currentPage={sessions.meta.current_page}
			totalPages={sessions.meta.last_page}
			onPageChange={page =>
				navigate({
					search: createSearchParams({
						tab,
						page: (page + 1).toString(),
					}).toString(),
				})
			}
			onItemClick={session =>
				navigate(
					session.conversation.status === Status.Closed
						? routes.enable.roleplay.session.review({
								companyId: companyId,
								conversationId: session.conversation.id,
							})
						: routes.enable.roleplay.session.index({
								companyId: companyId,
								conversationId: session.conversation.id,
							}),
				)
			}
			columns={[
				{
					name: 'name',
					heading: 'Session Name',
					render: session => session.conversation.title ?? 'Untitled',
				},
				{
					name: 'agenda',
					heading: 'Agenda',
					render: session => (
						<TrainingAgenda title={session.conversation.scenarioTitle ?? ''} icon="user-service-desk" />
					),
				},
				{
					name: 'persona_used',
					heading: 'Persona used',
					render: session =>
						session.participant ? (
							<div className="grid grid-cols-[max-content,1fr] items-center gap-x-2">
								<PersonaAvatar type={session.participant.persona.type ?? ''} size="sm" className="row-span-2" />
								<div>
									<p className="w-full truncate text-label-sm text-neutral-3-fg">{session.conversation.name}</p>
									<p className="w-full truncate text-body-sm font-semibold text-neutral-1-fg">
										{session.participant.persona.type}
									</p>
								</div>
							</div>
						) : (
							<p className="text-body-sm text-neutral-3-fg">Persona has been deleted</p>
						),
				},
				{
					name: 'status',
					heading: 'Status',
					render: session => (
						<p
							className={cn(
								'flex flex-nowrap items-center gap-1 py-0.5 pl-0.5 pr-2 text-label-md transition-colors',
								['Pending', 'Evaluating'].includes(session.conversation.evaluation?.status ?? '')
									? 'text-yellow-70'
									: session.conversation.status === Status.Closed
										? 'text-green-70'
										: session.conversation.status === Status.Ready
											? 'text-orange-70'
											: 'text-blue-60',
							)}
						>
							<Icon name="circle-fill" size="sm" />
							{['Pending', 'Evaluating'].includes(session.conversation.evaluation?.status ?? '')
								? 'Evaluating'
								: session.conversation.status === Status.Closed
									? 'Completed'
									: session.conversation.status === Status.Ready
										? 'Ready'
										: 'Initializing'}
						</p>
					),
				},
				{
					hidden: !(checkIsUserSession(user.roles) || checkIsAdminSession(user.roles)),
					name: 'created_by',
					heading: 'Created by',
					render: session => (
						<div className="flex items-center gap-2 text-body-sm font-medium text-neutral-2-fg">
							<div className="flex items-center gap-2 text-body-sm font-medium text-neutral-2-fg">
								<ContactAvatar
									className="inline-flex shrink-0 items-center justify-center rounded-full"
									variant="gray"
									size="sm"
									initial={formatAvatarAbbr(session.conversation.user)}
								/>
								{session.conversation.user}
							</div>
						</div>
					),
				},
				{
					name: 'created_at',
					heading: 'Date created',
					render: session => format(new Date(session.conversation.createdAt), 'PP'),
				},
			]}
		/>
	)
}

function RoleplayScenarios() {
	const { companyId } = useParsedRouteParams(['companyId'])
	const navigate = useNavigate()
	const {
		user,
		scenarios,
		data: { tab },
	} = useLoaderData() as RoleplayLoaderResponse

	if (!scenarios.data.length) {
		return <EmptyStateCard icon="chat" title="You don't have any scenarios yet." />
	}

	return (
		<Table
			data={scenarios.data}
			currentPage={scenarios.meta.current_page}
			totalPages={scenarios.meta.last_page}
			onPageChange={page =>
				navigate({
					search: createSearchParams({
						tab,
						page: (page + 1).toString(),
					}).toString(),
				})
			}
			onItemClick={scenario =>
				navigate(
					routes.enable.roleplay.scenario.index({
						companyId: companyId,
						scenarioId: scenario.id,
					}),
				)
			}
			columns={[
				{
					name: 'name',
					heading: 'Scenario Name',
					render: scenario => scenario.name,
				},
				{
					name: 'agenda',
					heading: 'Agenda',
					render: scenario => <TrainingAgenda title={scenario.title ?? ''} icon="user-service-desk" />,
				},
				{
					name: 'persona_used',
					heading: 'Persona used',
					render: scenario =>
						scenario.personaMapping ? (
							<PersonaItem
								personaType={scenario.personaMapping.personaType}
								expertise={scenario.personaMapping.expertise}
							/>
						) : (
							<p className="text-body-sm text-neutral-3-fg">Persona has been deleted</p>
						),
				},
				{
					hidden: !(checkIsUserSession(user.roles) || checkIsAdminSession(user.roles)),
					name: 'created_by',
					heading: 'Created by',
					render: scenario => (
						<div className="flex items-center gap-2 text-body-sm font-medium text-neutral-2-fg">
							<ContactAvatar
								className="inline-flex shrink-0 items-center justify-center rounded-full"
								variant="gray"
								size="sm"
								initial={formatAvatarAbbr(scenario.createdBy)}
							/>
							{scenario.createdBy}
						</div>
					),
				},
				{
					name: 'participation_rate',
					heading: 'Participation rate',
					render: scenario => {
						const sessionsCount = scenario.sessions?.length
						const completedSessionsCount =
							scenario.sessions?.filter(session => session.status === Status.Closed).length ?? 0

						return (
							<div className="flex w-[150px] items-center gap-1.5">
								<ProgressBar percent={calculatePct(completedSessionsCount, sessionsCount)} variant="status" />
								<span className="text-body-sm text-neutral-3-fg">{`${completedSessionsCount}/${sessionsCount}`}</span>
							</div>
						)
					},
				},
				{
					name: 'created_at',
					heading: 'Date created',
					render: scenario => formatDateToReadableString(scenario.createdAt),
				},
			]}
		/>
	)
}

export function PersonaItem({ personaType, expertise }: { personaType: string; expertise: string }) {
	return (
		<div className="grid grid-cols-[max-content,1fr] items-center gap-x-2">
			<PersonaAvatar type={personaType ?? ''} size="sm" className="row-span-2" />
			<div>
				<p className="w-full truncate text-label-sm text-neutral-3-fg">{expertise}</p>
				<p className="w-full truncate text-body-sm font-semibold text-neutral-1-fg">{personaType}</p>
			</div>
		</div>
	)
}

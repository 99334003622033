import { getFormProps, getInputProps, getTextareaProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { type QueryClient } from '@tanstack/react-query'
import { type ReactNode } from 'react'
import { redirect, type LoaderFunctionArgs, useLoaderData, Link, type ActionFunctionArgs, Form } from 'react-router-dom'
import { ErrorList, Field, TextareaField } from '#src/components/forms'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { Sidebar, SidebarFooter, SidebarHeader } from '#src/components/ui/sidebar'
import { StatusButton } from '#src/components/ui/status-button'
import { deleteCriterion, saveCriteria, editEcosystemQualCriteria } from '#src/routes/calibrate/ecosystem/mutations'
import { criteriaQuery, ecosystemKeys } from '#src/routes/calibrate/ecosystem/queries'
import { DeleteCriteriaFormSchema, VerticalsCriteriaFormSchema } from '#src/routes/calibrate/ecosystem/schema'
import { useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { verticalKeys, verticalsQuery } from './queries'

export type VerticalsCriteriaLoaderResponse = Awaited<ReturnType<ReturnType<typeof loader>>>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId || !params.ecosystemId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const criteria = await queryClient.fetchQuery(criteriaQuery(params.companyId, params.ecosystemId))
		const verticals = await queryClient.fetchQuery(verticalsQuery(params.companyId, Number(params.ecosystemId)))

		return {
			companyId: params.companyId,
			ecosystemId: params.ecosystemId,
			criteria: criteria?.map(c => ({
				id: c.id,
				question: c.question,
				dataSources: c.dataSources,
			})),
			verticals: verticals?.map(v => ({
				id: v.id,
				name: v.name,
				answers: v.answers,
			})),
		}
	}

export const action =
	(queryClient: QueryClient) =>
	async ({ params, request }: ActionFunctionArgs) => {
		if (!params.companyId || !params.ecosystemId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const formData = await request.formData()
		const deleteCriterionId = formData.get('deleteCriterion')

		if (deleteCriterionId) {
			const submission = parseWithZod(formData, {
				schema: DeleteCriteriaFormSchema,
			})

			if (submission.status !== 'success') {
				throw Error('Failed to parse form data')
			}

			await deleteCriterion(params.companyId, submission.value.deleteCriterion)

			await queryClient.invalidateQueries({
				queryKey: ecosystemKeys.criteria(params.companyId, params.ecosystemId),
			})
			await queryClient.invalidateQueries({
				queryKey: verticalKeys.list(params.companyId, Number(params.ecosystemId)),
			})

			return redirect(
				routes.calibrate.criteria.create({
					companyId: params.companyId,
					ecosystemId: params.ecosystemId,
				}),
			)
		}

		const submission = parseWithZod(formData, {
			schema: VerticalsCriteriaFormSchema,
		})

		if (submission.status !== 'success') {
			throw Error('Failed to parse form data')
		}
		console.log(submission)
		// NOTE: update/create criteria first
		// since some of them may be new criteria
		// thus we can not update verticals without criteria id
		const criteria = submission.value.criteria.map(c => ({
			ecosystemId: Number(params.ecosystemId),
			id: c.id,
			question: c.question,
			dataSources: c.dataSources,
			qualification: true,
		}))

		const data = await saveCriteria(params.companyId, {
			criteria,
		})

		const verticals = await queryClient.fetchQuery(verticalsQuery(params.companyId, Number(params.ecosystemId)))

		// Map each vertical to an object containing question ID and answer value properties
		const qualCriteriaPerVerticalAnswers = verticals
			.map(vertical => {
				const answers = data.flatMap(criterion => {
					const { id, question } = criterion

					const matchingCriterion = submission.value.criteria.find(item => item.question === question)

					const criterionAnswer = matchingCriterion?.answers.find(item => item.verticalId === vertical.id)

					if (!criterionAnswer?.value) return []

					return {
						id,
						value: criterionAnswer.value,
					}
				})

				return {
					id: vertical.id,
					answers,
				}
			})
			.filter(vertical => vertical.answers.length)

		if (qualCriteriaPerVerticalAnswers.length) {
			await editEcosystemQualCriteria(params.companyId, params.ecosystemId, {
				verticals: qualCriteriaPerVerticalAnswers,
			})
		}

		await Promise.all([
			queryClient.invalidateQueries({
				queryKey: criteriaQuery(params.companyId, params.ecosystemId).queryKey,
			}),
			queryClient.invalidateQueries({
				queryKey: verticalsQuery(params.companyId, Number(params.ecosystemId)).queryKey,
			}),
		])

		return redirect(
			routes.calibrate.verticals.index({
				companyId: params.companyId,
				ecosystemId: params.ecosystemId,
			}),
		)
	}

export default function VerticalsCriteriaEdit() {
	const { companyId, ecosystemId, verticals, criteria } = useLoaderData() as VerticalsCriteriaLoaderResponse

	const action = routes.calibrate.criteria.edit({
		companyId: companyId,
		ecosystemId: ecosystemId,
	})
	const method = 'POST'
	const formId = 'criteria-form-' + criteria.map(c => c.id).join('')

	const isPending = useIsPending({
		formAction: action,
		formMethod: method,
	})

	return (
		<div className="sticky right-0 w-[26rem] shrink-0">
			<Sidebar
				header={
					<SidebarHeader>
						<h1 className="text-body-md font-semibold">Add Criteria</h1>
						<Link
							className="inline-flex"
							to={routes.calibrate.verticals.index({
								companyId: companyId,
								ecosystemId: ecosystemId,
							})}
						>
							<Icon name="cross-1" />
						</Link>
					</SidebarHeader>
				}
				main={
					<CriteriaForm action={action} method={method} formId={formId} criteria={criteria} verticals={verticals} />
				}
				footer={
					<SidebarFooter>
						<Button asChild variant="outline" size="sm">
							<Link
								to={routes.calibrate.verticals.index({
									companyId: companyId,
									ecosystemId: ecosystemId,
								})}
							>
								Cancel
							</Link>
						</Button>
						<StatusButton
							status={isPending ? 'pending' : 'idle'}
							size="sm"
							type="submit"
							form={formId}
							disabled={isPending}
						>
							Save
						</StatusButton>
					</SidebarFooter>
				}
			/>
		</div>
	)
}

function CriteriaForm({
	action,
	method,
	formId,
	criteria,
	verticals,
}: {
	action: string
	method: 'POST'
	formId: string
	criteria: VerticalsCriteriaLoaderResponse['criteria']
	verticals: VerticalsCriteriaLoaderResponse['verticals']
}) {
	const isPending = useIsPending({ formAction: action, formMethod: method })

	const defaultValue = criteria.length
		? {
				criteria: criteria.map(c => ({
					id: c.id,
					question: c.question,
					dataSources: c.dataSources,
					answers: verticals?.length
						? verticals.map(v => ({
								verticalId: v.id,
								name: v.name,
								value: v.answers?.find(a => a.criterionId === c.id)?.value,
							}))
						: null,
				})),
			}
		: {
				criteria: null,
			}

	const [form, fields] = useForm({
		id: formId,
		constraint: getZodConstraint(VerticalsCriteriaFormSchema),
		defaultValue,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: VerticalsCriteriaFormSchema })
		},
		shouldValidate: 'onBlur',
		shouldRevalidate: 'onInput',
	})

	const criteriaFields = fields.criteria.getFieldList()

	return (
		<Form method={method} action={action} {...getFormProps(form)} className="flex flex-col gap-4">
			{criteriaFields.map((criterion, index) => {
				const input = criterion.getFieldset()
				const answers = input.answers.getFieldList()
				const textareaProps = getTextareaProps(input.question)
				const textareaDataSourcesProps = getTextareaProps(input.dataSources)

				return (
					<section
						key={textareaProps.key}
						className="flex flex-col gap-6 overflow-hidden rounded border border-neutral-1-bd pb-6"
					>
						<input {...getInputProps(input.id, { type: 'hidden' })} defaultValue={input.id.value} />

						<h2 className="flex w-full items-center justify-between bg-neutral-2-bg px-3 py-2 text-label-sm text-neutral-1-fg">
							Qualification criterion #{index + 1}{' '}
							{input.id?.value ? (
								<button
									type="submit"
									name="deleteCriterion"
									value={input.id.value}
									disabled={isPending}
									className="flex items-center justify-center outline-none transition-all"
								>
									<Icon name="trash" />
								</button>
							) : (
								<button
									type="button"
									onClick={() => form.remove({ name: fields.criteria.name, index })}
									className="flex items-center justify-center outline-none transition-all"
								>
									<Icon name="trash" />
								</button>
							)}
						</h2>

						<TextareaField
							className="flex-grow px-3"
							labelProps={{
								children: 'Description',
							}}
							textareaProps={{
								...textareaProps,
								placeholder: 'Enter here',
								rows: (input.question.value?.length ?? 0) > 64 ? 3 : 2,
								autoFocus:
									criteriaFields.findIndex(c => !c.getFieldset().question.value) === index ||
									(criteriaFields.every(c => c.getFieldset().question.value) && index === 0),
							}}
							errors={input.question.errors}
						/>

						<TextareaField
							className="flex-grow px-3"
							labelProps={{
								children: 'AI Agent instructions for finding and interpreting data',
							}}
							textareaProps={textareaDataSourcesProps}
							errors={input.dataSources.errors}
						/>

						{answers.length ? (
							<VerticalsCriteriaAnswers
								defaultOpen={!input.id.value}
								label={
									<>
										Criteria answers for verticals{' '}
										<span className="font-medium text-brand-1-fg">{answers.length ?? 0}</span>
									</>
								}
							>
								{answers.map((answer, i) => {
									const vertical = answer.getFieldset()

									return (
										<section key={i} className="relative space-y-4">
											<input {...getInputProps(vertical.name, { type: 'hidden' })} defaultValue={vertical.name.value} />
											<input
												{...getInputProps(vertical.verticalId, {
													type: 'hidden',
												})}
												defaultValue={vertical.verticalId.value}
											/>

											<Field
												className="flex-grow"
												labelProps={{
													children: (
														<span className="flex items-center justify-between pb-1">{vertical.name.value}</span>
													),
												}}
												inputProps={{
													...getInputProps(vertical.value, { type: 'text' }),
													placeholder: 'Enter here',
												}}
												errors={vertical.value.errors}
											/>
										</section>
									)
								})}
							</VerticalsCriteriaAnswers>
						) : null}
					</section>
				)
			})}

			{criteriaFields?.length ? (
				<button
					className="flex w-full items-center gap-2 text-body-md text-link outline-none transition-all hover:text-link-hover focus-visible:text-link-pressed active:text-link-pressed"
					{...form.insert.getButtonProps({
						name: fields.criteria.name,
						defaultValue: {
							question: '',
							dataSources: '',
							answers: verticals.map(v => ({
								verticalId: v.id,
								name: v.name,
								value: '',
							})),
						},
					})}
				>
					<Icon name="add" size="sm" aria-hidden />
					Add
				</button>
			) : (
				<section className="flex flex-col items-center justify-center gap-6 rounded bg-neutral-2-bg p-8">
					<Icon name="list-checked" size="xl" className="text-neutral-1-fg" />
					<p className="text-body-md text-neutral-1-fg">You have not added any qualification criteria yet.</p>
					<Button
						{...form.insert.getButtonProps({
							name: fields.criteria.name,
							defaultValue: {
								question: '',
								dataSources: '',
								answers: verticals.map(v => ({
									verticalId: v.id,
									name: v.name,
									value: '',
								})),
							},
						})}
					>
						+ Add new criterion
					</Button>
				</section>
			)}

			<ErrorList errors={form.errors} id={form.errorId} />
		</Form>
	)
}

function VerticalsCriteriaAnswers({
	defaultOpen,
	label,
	children,
}: {
	defaultOpen?: boolean
	label: ReactNode
	children: ReactNode
}) {
	return (
		<AccordionPrimitive.Root type="multiple" defaultValue={defaultOpen ? ['item-1'] : []} className="px-3">
			<AccordionPrimitive.Item value="item-1" className="group focus-within:relative focus-within:z-10">
				<AccordionPrimitive.Trigger className="flex flex-1 items-center gap-1 text-body-md font-semibold outline-none transition-all focus-visible:text-brand-1-fg">
					<Icon name="chevron-down" className="transform group-radix-state-open:rotate-180" aria-hidden size="sm" />
					{label}
				</AccordionPrimitive.Trigger>
				<AccordionPrimitive.Content
					// WARNING: Must keep this as the children are form elements, w/o them formData will be sparse
					forceMount
					// WARNING: invisible is necessary as the elements are still mounted, this is to prevent content being focused
					className="group -mx-4 overflow-hidden px-4 radix-state-closed:invisible radix-state-closed:h-0 radix-state-open:h-auto"
					// className="group -mx-4 overflow-hidden px-4 radix-state-closed:animate-[acc-slide-up_150ms_ease-in-out] radix-state-open:animate-[acc-slide-down_150ms_ease-in-out]"
				>
					<div className="space-y-2 py-3">{children}</div>
				</AccordionPrimitive.Content>
			</AccordionPrimitive.Item>
		</AccordionPrimitive.Root>
	)
}

import { DecisionTreeAPISchema } from '#src/routes/organize/decision-tree/schema'
import { api } from '#src/utils/endpoints'
import { safeFetch } from '#src/utils/safeFetch'

export const decisionTreeKeys = {
	all: ['decisionTree'] as const,
	details: (companyId: string) => [...decisionTreeKeys.all, 'details', companyId] as const,
}

export const getDecisionTreeQuery = (companyId: string) => ({
	queryKey: decisionTreeKeys.details(companyId),
	queryFn: async () => {
		return await safeFetch(DecisionTreeAPISchema, api.decisionTree.details(companyId), { method: 'GET' })
	},
})

export const createDecisionTreeQuery = async (companyId: string) => {
	await safeFetch(DecisionTreeAPISchema, api.decisionTree.details(companyId), {
		method: 'POST',
	})
}

import { type QueryClient } from '@tanstack/react-query'
import { type RouteObject } from 'react-router-dom'
import {
	loader as organizeLoader,
	type LoaderResType as OrganizeLoaderResType,
} from '#src/routes/organize/loaders/root'
import { routes } from '#src/utils/routes'
import { LRACreateView, lraCreateLoader, lraCreateAction } from './routes/create'
import { lraDownloadAction, lraDownloadLoader } from './routes/download'
import { lraDownloadTemplateAction } from './routes/downloadTemplate'
import { LRAItemView, lraItemLoader, type LRAItemLoaderResType } from './routes/item'
import { LRAListView, lraListLoader } from './routes/list'
import { lraStopAction, lraStopLoader } from './routes/stop'

export default (client: QueryClient) =>
	({
		id: 'lead-research-agent-module',
		path: 'lra',
		loader: organizeLoader,
		handle: {
			crumb: (data: OrganizeLoaderResType) => [
				{
					to: routes.leadResearchAgent.index({ companyId: data.handle.companyId }),
					label: 'Lead Research Agent Jobs',
					icon: 'chart-column',
				},
			],
		},
		children: [
			{
				path: '',
				loader: lraListLoader(client),
				element: <LRAListView />,
				handle: {
					layout: {
						enableHeader: true,
					},
				},
				children: [
					{
						path: 'new',
						element: <LRACreateView />,
						loader: lraCreateLoader(client),
						action: lraCreateAction(client),
					},
				],
			},
			{
				path: ':jobId',
				element: <LRAItemView />,
				loader: lraItemLoader(client),
				handle: {
					layout: {
						enableHeader: true,
					},
					crumb: (data: LRAItemLoaderResType) => [
						{
							to: routes.leadResearchAgent.view({ companyId: data.values.companyId, jobId: data.values.jobId }),
							label: data.data.job.title,
						},
					],
				},
				children: [
					{
						path: 'stop',
						loader: lraStopLoader(client),
						action: lraStopAction(client),
					},
					{
						path: 'download',
						loader: lraDownloadLoader(client),
						action: lraDownloadAction(client),
					},
				],
			},
			{
				path: 'download-template',
				action: lraDownloadTemplateAction(client),
			},
		],
	}) satisfies RouteObject

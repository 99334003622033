import { Link } from 'react-router-dom'
import { DashboardCard } from '#src/components/dashboard-card'
import { Icon } from '#src/components/ui/icon.js'
import { PageLayout } from '#src/components/ui/PageLayout'
import { routes } from '#src/utils/routes.js'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'

export default function SettingsDashboard() {
	const params = useParsedRouteParams(['companyId'])

	return (
		<PageLayout title="Settings">
			<div className="grid w-full grid-cols-1 grid-rows-1 gap-6">
				<Link
					to={routes.settings.team({
						companyId: params.companyId,
					})}
					className="block w-full"
				>
					<DashboardCard
						icon="user-multiple"
						title={
							<div className="flex w-full items-center justify-between">
								Team members <Icon name="chevron-right" size="font" />
							</div>
						}
						isHoverable
					/>
				</Link>
			</div>
		</PageLayout>
	)
}

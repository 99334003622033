import { Link, useParams, useRouteLoaderData } from 'react-router-dom'
import { PanelSectionItem } from '#src/components/panel'
import { type PrioritizeModuleLoaderResponse } from '#src/routes/prioritize/loaders/root'
import { cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'

type EnrichNavSignal = {
	id: number
	to: string
	name: string
}

type EnrichNav = {
	title: string
	signals: EnrichNavSignal[] | null
}

function SignalsPanelItem({ signals }: { signals: EnrichNavSignal[] }) {
	const params = useParams()

	return signals?.map(signal => (
		<PanelSectionItem
			key={signal.id}
			className="group items-start justify-between"
			isTrigger
			isActive={params.signalId === signal.id?.toString()}
		>
			<section className="-my-2 flex w-full flex-nowrap items-start gap-1 truncate py-2">
				<Link
					to={signal.to}
					className={cn(
						'-my-2 w-full truncate py-2',
						params.signalId === signal.id?.toString() ? 'font-bold' : 'font-normal',
					)}
				>
					{`${signal.id}. ${signal.name}`}
				</Link>
			</section>
		</PanelSectionItem>
	))
}

export default function EnrichPanel() {
	const params = useParsedRouteParams(['companyId'])
	const { signals } = useRouteLoaderData('prioritize-module') as PrioritizeModuleLoaderResponse

	const rootNav = {
		title: 'Signals',
	}

	const nav = {
		...rootNav,
		signals: signals?.length
			? signals.map(s => ({
					id: s.id,
					to: routes.prioritize.signal.index({
						companyId: params.companyId,
						signalId: s.id.toString(),
					}),
					name: s.name,
				}))
			: null,
	} satisfies EnrichNav

	return (
		<section className="flex flex-col px-2">
			<PanelSectionItem className="group justify-between">
				<h3 className="text-label-sm font-semibold tracking-widest text-neutral-3-fg">{nav.title}</h3>
			</PanelSectionItem>

			{nav.signals ? (
				<SignalsPanelItem signals={nav.signals} />
			) : (
				<PanelSectionItem asChild isTrigger className="gap-2 text-body-md text-neutral-3-fg">
					No Signals
				</PanelSectionItem>
			)}
		</section>
	)
}

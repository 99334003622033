import * as Sentry from '@sentry/react'
import { type QueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { Outlet, ScrollRestoration, useSearchParams } from 'react-router-dom'
import { Icon } from '#src/components/ui/icon'
import { Toast, ToastDescription, ToastViewport } from '#src/components/ui/toast'
import { userQuery } from '#src/routes/auth/queries'
import { AVAILABLE_FEATURES, toggleFeature } from '#src/utils/feature-flag'

export const loader = (queryClient: QueryClient) => async () => {
	/**
	 * NOTE: Pass through, do not block rendering
	 * as routes have their own business logic
	 */
	try {
		const user = await queryClient.fetchQuery(userQuery())
		if (user?.email) {
			Sentry.setUser({
				email: user.email,
			})
		} else {
			Sentry.setUser(null)
		}
	} catch (error) {
		Sentry.setUser(null)

		return null
	}

	return null
}

export default function Root() {
	return (
		<>
			<Outlet />
			<ScrollRestoration getKey={({ pathname }) => pathname} />
			<FeatureToggle />
		</>
	)
}

function FeatureToggle() {
	const [searchParams, setSearchParams] = useSearchParams()

	useEffect(() => {
		for (const [key, value] of searchParams.entries()) {
			if (AVAILABLE_FEATURES[key]) {
				toggleFeature(key, value)
				searchParams.delete(key)
			}
		}

		setSearchParams(searchParams)
	}, [searchParams, setSearchParams])

	const didToggle = Boolean(
		Array.from(searchParams.entries())
			.flat()
			.filter(f => AVAILABLE_FEATURES[f]).length,
	)

	return (
		<>
			<Toast shouldOpen={didToggle} duration={3000}>
				<ToastDescription className="flex items-center gap-3">
					<Icon name="checkmark-filled" size="md" className="text-green-70" />
					Feature(-s) toggled
				</ToastDescription>
			</Toast>

			<div className="fixed">
				<ToastViewport />
			</div>
		</>
	)
}

import { type ReactElement, type ReactNode } from 'react'
import { Link, Outlet, type To } from 'react-router-dom'
import { Button, type ButtonProps } from '#src/components/ui/button'
import { Icon, type IconName } from '#src/components/ui/icon'

export type PageLayoutPropsAction = {
	icon: IconName
	title: string
	variant: ButtonProps['variant']
	to: To
	state?: unknown
}

export type PageLayoutProps = {
	title?: string
	description?: string
	children: ReactNode
	actions?: (PageLayoutPropsAction | ReactElement)[]
}

export const PageLayout = (props: PageLayoutProps) => {
	const { title, description, children, actions } = props

	return (
		<main className="flex h-full w-full flex-1 flex-col items-center gap-6">
			{(!!title || !!description || !!actions?.length) && (
				<section className="w-full">
					<div className="space-between flex flex-row items-center gap-6">
						{(!!title || !!description) && (
							<div className="flex flex-1 flex-col gap-2">
								{!!title && <h1 className="flex-1 text-heading-sm text-neutral-1-fg">{title}</h1>}
								{!!description && <p className="text-body-md text-neutral-2-fg">{description}</p>}
							</div>
						)}
						{!!actions?.length && (
							<div className="flex flex-row gap-2">
								{actions.map(action => {
									if ('title' in action) {
										return (
											<Button
												key={`action-${action.title}`}
												asChild
												className="flex flex-nowrap items-center gap-2"
												variant={action.variant}
											>
												<Link to={action.to}>
													<Icon name={action.icon} /> {action.title}
												</Link>
											</Button>
										)
									}
									return action
								})}
							</div>
						)}
					</div>
				</section>
			)}
			<div className="flex w-full flex-1 flex-col gap-6">{children}</div>
			<Outlet />
		</main>
	)
}

import { parseWithZod } from '@conform-to/zod'
import { type QueryClient } from '@tanstack/react-query'
import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { type z } from 'zod'
import { showToast } from '#src/context/ToastContext'
import { FORM_STEPS } from '#src/routes/lead-research-agent/constants'
import { createJob } from '#src/routes/lead-research-agent/mutations'
import { leadResearchAgentJobsKeys } from '#src/routes/lead-research-agent/queries'
import {
	LeadResearchAgentCreateJobSchema,
	LeadResearchAgentNewJobStepOneSchema,
	LeadResearchAgentNewJobStepThreeSchema,
	LeadResearchAgentNewJobStepTwoSchema,
} from '#src/routes/lead-research-agent/schema'
import { routes } from '#src/utils/routes'
export type ActionRes = Awaited<ReturnType<ReturnType<typeof action>>>

export const action =
	(queryClient: QueryClient) =>
	async ({ params, request }: ActionFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})
		const formData = await request.formData()

		const submission = parseWithZod(formData, {
			schema: LeadResearchAgentCreateJobSchema,
		})

		if (submission.status !== 'success') {
			throw new Error('Failed to parse form data')
		}

		try {
			await createJob(submission.value)
			await queryClient.invalidateQueries({
				queryKey: leadResearchAgentJobsKeys.all,
			})

			showToast({
				message: 'Job created successfully',
				type: 'success',
			})

			return redirect(routes.leadResearchAgent.index({ companyId: params.companyId }))
		} catch (e) {
			try {
				const res = (e as Response).clone()
				const body = (await res.json()) as {
					success: boolean
					message: string
					errors?: Record<string, string[]>
				}
				showToast({
					message: body.message,
					type: 'error',
				})

				const failedStep = getEarliestFailedStepFromResErrors(body.errors, [
					{
						step: FORM_STEPS.First,
						schema: LeadResearchAgentNewJobStepOneSchema,
					},
					{
						step: FORM_STEPS.Second,
						schema: LeadResearchAgentNewJobStepTwoSchema,
					},
					{
						step: FORM_STEPS.Third,
						schema: LeadResearchAgentNewJobStepThreeSchema,
					},
				])

				return {
					ok: false,
					result: submission.reply({
						fieldErrors: body.errors,
					}),
					failedStep,
				}
			} catch (err) {
				showToast({
					message: 'Unexpected error while creating Lead Research Agent job',
					type: 'error',
				})
				return {
					ok: false,
				}
			}
		}
	}

const getEarliestFailedStepFromResErrors = <T>(
	errors: Record<string, string[]> | undefined,
	steps: { step: T; schema: z.SomeZodObject }[],
): T | undefined => {
	let res: T | undefined = undefined
	if (!errors) {
		return res
	}
	steps.some(({ step, schema }) => {
		const shape = schema.shape
		if (res) {
			return true
		}
		Object.keys(shape).some(key => {
			if (key in errors) {
				res = step
				return true
			}
		})
	})
	return res
}

import { type QueryClient } from '@tanstack/react-query'
import { type LoaderFunctionArgs, Outlet, redirect } from 'react-router-dom'
import { userQuery } from '#src/routes/auth/queries'
import { companyQuery } from '#src/routes/company/queries'
import { checkIsUserSession, checkIsEnableOnlySession, checkIsReadOnlySession } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

export type MainLoaderResponse = Awaited<ReturnType<ReturnType<typeof loader>>>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params, request }: LoaderFunctionArgs) => {
		const user = await queryClient.fetchQuery(userQuery())
		const readOnlySession = checkIsReadOnlySession(user.roles)
		const enableOnlySession = checkIsEnableOnlySession(user.roles)
		const userSession = checkIsUserSession(user.roles)

		if (enableOnlySession && !new URL(request.url).pathname.includes('/enable'))
			throw redirect(routes.enable.index({ companyId: user.companyId }))

		if (!params.companyId) {
			if (enableOnlySession) throw redirect(routes.enable.index({ companyId: user.companyId }))
			throw redirect(routes.company.index({ companyId: user.companyId }))
		}

		if (enableOnlySession) {
			return {
				company: null,
				user,
				readOnlySession,
				enableOnlySession,
				userSession,
			}
		}

		const company = await queryClient.fetchQuery(companyQuery(params.companyId))
		if (!company)
			throw new Response('Company Not Found', {
				status: 404,
				statusText: 'Not Found',
			})

		return {
			user,
			company,
			readOnlySession,
			enableOnlySession,
			userSession,
		}
	}

export default function Layout() {
	return (
		<div className="relative flex h-screen w-screen overflow-hidden bg-neutral-1-bg text-neutral-1-fg">
			<Outlet />
		</div>
	)
}

import { type ReactNode } from 'react'

type CriteriaSectionType = {
	title: string
	items: { label: ReactNode | string; value: ReactNode | string }[]
}

export const PersonaCriteriaAside = ({ sections }: { sections: CriteriaSectionType[] }) => (
	<aside className="sticky top-[calc(var(--builder-header-height)+1rem)] flex flex-col gap-6 self-start rounded-lg bg-neutral-2-bg p-6">
		{sections.map(section => (
			<div key={section.title} className="flex flex-col gap-4">
				<h3 className="w-full text-title-sm text-neutral-1-fg">{section.title}</h3>
				{section.items.map(({ label, value }, index) => (
					<div key={index} className="flex flex-col gap-[1px]">
						<h4 className="text-label-sm text-neutral-3-fg">{label}</h4>
						<p className="break-words text-body-md text-neutral-1-fg">{value}</p>
					</div>
				))}
			</div>
		))}
	</aside>
)

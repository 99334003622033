import { type QueryClient } from '@tanstack/react-query'
import { type RouteObject } from 'react-router-dom'
import Roleplay, { loader as roleplayLoader } from '#src/routes/enable/roleplay/index'
import ScenarioDetails, {
	loader as scenarioDetailsLoader,
	newTestSessionAction,
	type RoleplayScenarioLoaderResponse,
} from '#src/routes/enable/roleplay/scenario'
import { editRoleplayScenarioAction } from '#src/routes/enable/roleplay/scenario/actions/editRoleplayScenarioAction'
import { inviteParticipantsAction } from '#src/routes/enable/roleplay/scenario/actions/inviteParticipantsAction'
import { newRoleplayScenarioWizardAction } from '#src/routes/enable/roleplay/scenario/actions/newRoleplayScenarioWizardAction'
import { InviteParticipantsModal } from '#src/routes/enable/roleplay/scenario/components/InviteParticipantsModal'
import { NewRoleplayScenarioWizard } from '#src/routes/enable/roleplay/scenario/components/NewRoleplayScenarioWizard'
import { inviteParticipantsLoader } from '#src/routes/enable/roleplay/scenario/loaders/inviteParticipantsLoader'
import { newRoleplayScenarioWizardLoader } from '#src/routes/enable/roleplay/scenario/loaders/newRoleplayScenarioWizardLoader'
import RoleplaySession, {
	loader as roleplaySessionLoader,
	type RoleplaySessionLoaderResponse,
} from '#src/routes/enable/roleplay/session'
import { joinRoleplayChatAction } from '#src/routes/enable/roleplay/session/actions/joinRoleplayChatAction'
import { newRoleplaySessionWizardAction } from '#src/routes/enable/roleplay/session/actions/newRoleplaySessionWizardAction'
import { JoinRoleplayChat } from '#src/routes/enable/roleplay/session/components/JoinRoleplayChat'
import { NewRoleplaySessionWizard } from '#src/routes/enable/roleplay/session/components/NewRoleplaySessionWizard'
import DeleteRoleplayChat, { action as deleteRoleplayAction } from '#src/routes/enable/roleplay/session/delete'
import EndRoleplayChat, { action as chatEndRoleplayAction } from '#src/routes/enable/roleplay/session/end'
import { joinRoleplayChatLoader } from '#src/routes/enable/roleplay/session/loaders/joinRoleplayChatLoader'
import { action as restartRoleplayAction } from '#src/routes/enable/roleplay/session/restart'
import RoleplayReview, {
	loader as roleplayReviewLoader,
	type RoleplayReviewLoaderResponse,
} from '#src/routes/enable/roleplay/session/review'
import RouteBoundary from '#src/routes/route-boundary'
import { routes } from '#src/utils/routes'
import { type EnableRoleplayLoaderResponse, loader as enableRoleplayLoader } from './loaders/root'

export const roleplayRouter = (client: QueryClient) =>
	({
		path: 'roleplay',
		loader: enableRoleplayLoader,
		handle: {
			layout: {
				enableHeader: true,
			},
			crumb: (data: EnableRoleplayLoaderResponse) => [
				{
					to: routes.enable.roleplay.index({
						companyId: data.handle.companyId,
					}),
					label: 'Roleplay training',
					icon: 'voice-activate',
				},
			],
		},
		children: [
			{
				index: true,
				element: <Roleplay />,
				errorElement: <RouteBoundary />,
				loader: roleplayLoader(client),
			},
			{
				path: 'scenario/:scenarioId',
				element: <ScenarioDetails />,
				loader: scenarioDetailsLoader(client),
				action: editRoleplayScenarioAction(client),
				handle: {
					crumb: (data: RoleplayScenarioLoaderResponse) => [
						{
							to: routes.enable.roleplay.scenario.index({
								companyId: data.handle.companyId,
								scenarioId: data.scenario.id,
							}),
							label: data.handle.scenario,
							icon: 'user-service-desk',
						},
					],
				},
				children: [
					{
						path: 'invite',
						element: <InviteParticipantsModal />,
						loader: inviteParticipantsLoader(client),
						action: inviteParticipantsAction(client),
					},
					{
						path: 'new-test-session',
						action: newTestSessionAction(client),
					},
				],
			},
			{
				path: 'session/:conversationId',
				element: <RoleplaySession />,
				loader: roleplaySessionLoader(client),
				handle: {
					crumb: (data: RoleplaySessionLoaderResponse) => [
						{
							to: routes.enable.roleplay.session.index({
								companyId: data.handle.companyId,
								conversationId: data.handle.conversationId,
							}),
							label: 'Voice chat',
							icon: 'user-service-desk',
						},
					],
					action: (data: RoleplaySessionLoaderResponse) => [
						...(data.handle.canRestartChat
							? [
									{
										id: 'copilot-restart',
										action: routes.enable.roleplay.session.restart({
											companyId: data.handle.companyId,
											conversationId: data.handle.conversationId,
										}),
										method: 'PUT',
										label: 'Restart Roleplay',
										icon: 'reset',
									},
								]
							: []),
						...(data.handle.canDeleteChat
							? [
									{
										to: routes.enable.roleplay.session.delete({
											companyId: data.handle.companyId,
											conversationId: data.handle.conversationId,
										}),
										icon: 'trash',
										className: 'text-status-danger-fg',
									},
								]
							: []),
					],
				},
				children: [
					{
						path: 'restart',
						action: restartRoleplayAction(client),
					},
					{
						path: 'end-session',
						element: <EndRoleplayChat />,
						action: chatEndRoleplayAction(client),
					},
					{
						path: 'delete',
						element: <DeleteRoleplayChat />,
						action: deleteRoleplayAction(client),
					},
				],
			},
			{
				path: 'session/:conversationId/join',
				element: <JoinRoleplayChat />,
				loader: joinRoleplayChatLoader(client),
				action: joinRoleplayChatAction,
				handle: {
					layout: {
						enableHeader: false,
						disableSidebar: true,
					},
				},
			},
			{
				id: 'roleplay-review',
				path: 'review/:conversationId',
				element: <RoleplayReview />,
				loader: roleplayReviewLoader(client),
				handle: {
					crumb: (data: RoleplayReviewLoaderResponse) => [
						{
							to: routes.enable.roleplay.session.review({
								companyId: data.handle.companyId,
								conversationId: data.handle.conversationId,
							}),
							label: 'Review',
							icon: 'user-service-desk',
						},
					],
					action: (data: RoleplayReviewLoaderResponse) =>
						data.handle.canDeleteChat
							? [
									{
										to: routes.enable.roleplay.session.deleteReviewed({
											companyId: data.handle.companyId,
											conversationId: data.handle.conversationId,
										}),
										icon: 'trash',
										className: 'text-status-danger-fg',
									},
								]
							: [],
				},
				children: [
					{
						path: 'delete',
						element: <DeleteRoleplayChat />,
						action: deleteRoleplayAction(client),
					},
				],
			},
			{
				path: 'scenario',
				children: [
					{
						path: 'new',
						element: <NewRoleplayScenarioWizard />,
						loader: newRoleplayScenarioWizardLoader(client),
						action: newRoleplayScenarioWizardAction(client),
						handle: {
							layout: {
								disableSidebar: true,
								noPadding: true,
								fullWidth: true,
								enableHeader: false,
							},
						},
					},
				],
			},
			{
				path: 'session',
				children: [
					{
						path: 'new',
						element: <NewRoleplaySessionWizard />,
						loader: newRoleplayScenarioWizardLoader(client),
						action: newRoleplaySessionWizardAction(client),
						handle: {
							layout: {
								disableSidebar: true,
								noPadding: true,
								fullWidth: true,
								enableHeader: false,
							},
						},
					},
				],
			},
		],
	}) satisfies RouteObject

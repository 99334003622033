export const PRIORITY = {
	high: 'High',
	medium: 'Medium',
	low: 'Low',
}

export const PRIORITY_OPTIONS = Object.values(PRIORITY).map(status => ({
	value: status,
	label: status,
}))

export const PRIORITY_RANK = {
	[PRIORITY.high]: 1,
	[PRIORITY.medium]: 2,
	[PRIORITY.low]: 3,
}

export const STATUS = {
	ongoing: 'Ongoing',
	paused: 'Paused',
	crashed: 'Crashed',
}

export const STATUS_OPTIONS = Object.values(STATUS).map(status => ({
	value: status,
	label: status,
}))

export const STATUS_RANK = {
	[STATUS.ongoing]: 1,
	[STATUS.paused]: 2,
	[STATUS.crashed]: 3,
}

export const PERSONA_TYPES = {
	decisionmaker: 'Decision Maker',
	influencer: 'Influencer',
	champion: 'Champion',
}

export const PERSONA_TYPE_RANK = {
	[PERSONA_TYPES.decisionmaker]: 1,
	[PERSONA_TYPES.influencer]: 2,
	[PERSONA_TYPES.champion]: 3,
}

export const PERSONA_TYPE_OPTIONS = Object.values(PERSONA_TYPES).map(personaType => ({
	value: personaType,
	label: personaType,
}))

export const MONTH_SELECT_OPTIONS = [
	{
		value: '1',
		label: 'January',
	},
	{
		value: '2',
		label: 'February',
	},
	{
		value: '3',
		label: 'March',
	},
	{
		value: '4',
		label: 'April',
	},
	{
		value: '5',
		label: 'May',
	},
	{
		value: '6',
		label: 'June',
	},
	{
		value: '7',
		label: 'July',
	},
	{
		value: '8',
		label: 'August',
	},
	{
		value: '9',
		label: 'September',
	},
	{
		value: '10',
		label: 'October',
	},
	{
		value: '11',
		label: 'November',
	},
	{
		value: '12',
		label: 'December',
	},
]

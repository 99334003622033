import { type QueryClient } from '@tanstack/react-query'
import { type LoaderFunctionArgs, redirect } from 'react-router-dom'
import { z } from 'zod'
import { showToast } from '#src/context/ToastContext'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem/queries'
import { EcosystemListAPISchema } from '#src/routes/calibrate/ecosystem/schema'
import { routes } from '#src/utils/routes'

export const LoaderSchema = z.object({
	values: z.object({
		companyId: z.string(),
	}),
	data: z.object({
		ecosystems: EcosystemListAPISchema,
	}),
})

export type LoaderResType = Awaited<ReturnType<ReturnType<typeof loader>>>

export type LoaderRes = z.infer<typeof LoaderSchema>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params: { companyId } }: LoaderFunctionArgs): Promise<LoaderRes> => {
		if (!companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		try {
			const ecosystems = await queryClient.fetchQuery(ecosystemsQuery(companyId, { personas: false }))

			return {
				values: {
					companyId,
				},
				data: {
					ecosystems,
				},
			}
		} catch (e) {
			showToast({
				message: `${(e as Response).status}: ${(e as Response).statusText} | ${(e as Response).url}`,
				type: 'error',
			})
			throw redirect(routes.leadResearchAgent.index({ companyId }))
		}
	}

import { type QueryClient } from '@tanstack/react-query'
import { Form, type LoaderFunctionArgs, useLoaderData, type ActionFunctionArgs, redirect } from 'react-router-dom'
import { z } from 'zod'
import Markdown from '#src/components/markdown'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { InlineSpinner } from '#src/components/ui/InlineSpinner'
import { Surface } from '#src/components/ui/surface'
import {
	createDecisionTreeQuery,
	decisionTreeKeys,
	getDecisionTreeQuery,
} from '#src/routes/organize/decision-tree/queries'
import { DecisionTreeAPISchema } from '#src/routes/organize/decision-tree/schema'
import { formatDateToReadableString } from '#src/utils/date'
import { useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

export const DecisionTreeLoaderSchema = z.object({
	handle: z.object({
		companyId: z.string(),
		decisionTree: DecisionTreeAPISchema,
	}),
})

export type DecisionTreeLoaderResponseType = Awaited<ReturnType<ReturnType<typeof loader>>>

export type DecisionTreeLoaderResponse = z.infer<typeof DecisionTreeLoaderSchema>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params: { companyId } }: LoaderFunctionArgs) => {
		if (!companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const decisionTree = await queryClient.fetchQuery(getDecisionTreeQuery(companyId))

		return {
			handle: {
				companyId: companyId,
				decisionTree,
			},
		}
	}

export const action =
	(queryClient: QueryClient) =>
	async ({ params }: ActionFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		await createDecisionTreeQuery(params.companyId)

		await queryClient.invalidateQueries({
			queryKey: decisionTreeKeys.all,
		})

		return redirect(
			routes.organize.decisionTree.index({
				companyId: params.companyId,
			}),
		)
	}

const DecisionTreeHeader = ({ updatedAt, isPending }: { updatedAt: string | null; isPending: boolean }) => (
	<div className="flex flex-col gap-1 pb-6">
		<div className="flex justify-between justify-items-center">
			<h1 className="text-heading-sm text-neutral-1-fg">Classification Decision Tree</h1>
			{updatedAt && (
				<Button disabled={isPending} className="min-w-32 gap-1" type="submit">
					<Icon name="restart" size="sm" />
					Regenerate
				</Button>
			)}
		</div>
		<div className="text-body-sm text-neutral-2-fg">
			{!isPending && updatedAt ? `Generated: ${formatDateToReadableString(updatedAt)}` : updatedAt && <InlineSpinner />}
		</div>
	</div>
)

const DecisionTree = () => {
	const {
		handle: {
			companyId,
			decisionTree: { decisionTree, updatedAt },
		},
	} = useLoaderData() as DecisionTreeLoaderResponse

	const action = routes.organize.decisionTree.create({ companyId: companyId })
	const method = 'POST'

	const isPending = useIsPending({
		formAction: action,
		formMethod: method,
	})

	return (
		<Form method={method} action={action} className="flex w-full max-w-screen-2xl flex-col justify-between">
			<DecisionTreeHeader updatedAt={updatedAt} isPending={isPending} />
			{decisionTree ? (
				<Markdown className="pb-10">{decisionTree}</Markdown>
			) : (
				<Surface className="flex min-h-[460px] w-full flex-grow flex-col items-center justify-center gap-2">
					<Icon name="decision-tree-multiple" className="mb-2 h-16 w-16 text-brand-1-fg" />
					<div className="flex flex-col items-center justify-center text-center">
						<p className="mb-6 text-body-md text-neutral-2-fg">
							Generate a decision tree to refine your qualification criteria, enabling faster and more cost-effective
							account classification
						</p>
						{!isPending ? (
							<Button type="submit" size="sm">
								Generate
							</Button>
						) : (
							<InlineSpinner />
						)}
					</div>
				</Surface>
			)}
		</Form>
	)
}

export default DecisionTree

import { z } from 'zod'

export const DashboardSchema = z.object({
	id: z.number(),
	name: z.string(),
	url: z.string().url().nullable().optional(),
})

export const CompanySchema = z.object({
	id: z.string(),
	name: z.string(),
	valueProposition: z.string(),
	dashboard: DashboardSchema.array().nullable().optional(),
})

export const StatsSchema = z.object({
	time: z
		.object({
			verticals: z.number().optional().nullable(),
			personas: z.number().optional().nullable(),
			signals: z.number().optional().nullable(),
			terms: z.number().optional().nullable(),
		})
		.optional()
		.nullable(),
	calibrate: z
		.object({
			ecosystem_count: z.number().optional().nullable(),
			vertical_count: z.number().optional().nullable(),
			persona_count: z.number().optional().nullable(),
		})
		.optional()
		.nullable(),
	prioritize: z
		.object({
			signals: z.number().optional().nullable(),
			personas: z.number().optional().nullable(),
		})
		.optional()
		.nullable(),
	enable: z
		.object({
			chats: z.number().optional().nullable(),
			terms: z.number().optional().nullable(),
		})
		.optional()
		.nullable(),
	organize: z
		.object({
			aidt_chats_count: z.number().optional().nullable(),
		})
		.optional()
		.nullable(),
})

export const CompaniesSchema = CompanySchema.array()

export const EditCompanyFormSchema = CompanySchema

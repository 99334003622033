import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { type CheckedState } from '@radix-ui/react-checkbox'
import { type ReactNode, useCallback, useRef, useState } from 'react'
import { Checkbox } from '#src/components/ui/checkbox'
import { Icon } from '#src/components/ui/icon'

export type TreeViewItem = {
	id: string
	label: string
	info?: ReactNode | string
	children?: TreeViewItem[]
}

type CheckboxTreeProps = {
	value: string[]
	onChange: (data: string[]) => void
	options: TreeViewItem[]
	closed?: boolean
}

const calculateOpenedItems = (data: TreeViewItem[]) => {
	if (!data.length) {
		return []
	}
	const res: string[] = []
	const recursivelyForEach = (items: TreeViewItem[], level: number = 1) => {
		items.forEach(item => {
			if ('children' in item && item.children?.length) {
				res.push(`${level}-${item.id}`)
				recursivelyForEach(item.children, level + 1)
			}
		})
	}
	recursivelyForEach(data)

	return res
}

const CheckboxTreeInput = (props: CheckboxTreeProps) => {
	const { options, onChange, value, closed } = props
	const [expanded, setExpanded] = useState<string[]>(closed ? [] : calculateOpenedItems(options))

	const getCheckboxState = useCallback(
		(item: TreeViewItem): CheckedState => {
			if (!('children' in item)) {
				return value.includes(item.id)
			}

			let total: number = 0
			let totalSelected: number = 0

			const recursivelyCountTotals = (item: TreeViewItem) => {
				if ('children' in item && item.children) {
					item.children.forEach(child => recursivelyCountTotals(child))
				} else {
					total = total + 1
					if (value.includes(item.id)) {
						totalSelected = totalSelected + 1
					}
				}
			}
			recursivelyCountTotals(item)

			if (!total || !totalSelected) {
				return false
			}
			if (total === totalSelected) {
				return true
			}
			return 'indeterminate'
		},
		[value],
	)

	const handleCheck = (item: TreeViewItem, newValue: CheckedState) => {
		let res = [...value]

		const getChildrenIdsRecursively = (node: TreeViewItem, ids: string[] = []) => {
			if (!node.children) {
				ids.push(node.id)
			} else {
				node.children.forEach(child => getChildrenIdsRecursively(child, ids))
			}
			return ids
		}

		const ids = item.children ? getChildrenIdsRecursively(item) : [item.id]

		// remove all ids from current selected items if user wants to uncheck
		if (newValue === false) {
			res = res.filter(childId => !ids.includes(childId))
		} else {
			// just check if the id already exist or not and push into res
			ids.forEach(childId => {
				if (!res.includes(childId)) res.push(childId)
			})
		}

		onChange(res)
	}

	const isCheckboxHovered = useRef(false)
	const isRootHovered = useRef(false)

	const renderTree = (items: TreeViewItem[], level: number = 1) =>
		items.map(item => {
			const checkboxState = getCheckboxState(item)

			return (
				<AccordionPrimitive.Item key={`tree-level-${level}-${item.id}`} value={`${level}-${item.id}`}>
					<div className="flex items-center text-body-md text-neutral-1-fg">
						<AccordionPrimitive.Trigger asChild>
							<div className="group flex w-full cursor-pointer items-center justify-between rounded-sm p-1.5 transition-colors hover:bg-neutral-2-bg">
								<div className="flex items-center gap-1.5">
									{'children' in item && (
										<span className="transform transition-transform group-radix-state-open:rotate-90">
											<Icon name="chevron-right" />
										</span>
									)}
									<Checkbox
										disabled={'children' in item && !item.children?.length}
										checked={checkboxState}
										onCheckedChange={checked => handleCheck(item, checked)}
										onClick={e => e.stopPropagation()}
										onFocus={e => e.stopPropagation()}
										onMouseEnter={() => {
											isCheckboxHovered.current = true
										}}
										onMouseLeave={() => {
											isCheckboxHovered.current = false
										}}
									/>
									<span className="ml-1">{item.label}</span>
								</div>
								{item.info}
							</div>
						</AccordionPrimitive.Trigger>
					</div>
					{'children' in item && (
						<AccordionPrimitive.Content className="ml-3 pl-6">
							{item.children?.length ? (
								renderTree(item.children, level + 1)
							) : (
								<p className="p-1.5 text-body-sm">No items</p>
							)}
						</AccordionPrimitive.Content>
					)}
				</AccordionPrimitive.Item>
			)
		})

	return (
		<AccordionPrimitive.Root
			type="multiple"
			value={expanded}
			onValueChange={e => {
				if (!isCheckboxHovered.current && isRootHovered.current) {
					setExpanded(e)
				}
			}}
			className="flex flex-col"
			onMouseEnter={() => {
				isRootHovered.current = true
			}}
			onMouseLeave={() => {
				isRootHovered.current = false
			}}
		>
			{renderTree(options)}
		</AccordionPrimitive.Root>
	)
}

export default CheckboxTreeInput

import { queryOptions } from '@tanstack/react-query'
import { api } from '#src/utils/endpoints'
import { safeFetch } from '#src/utils/safeFetch'
import { MembersSchema, RolesSchema } from './schema'

export const membersKeys = {
	all: ['settings', 'team'] as const,
	team: (companyId: string) => [...membersKeys.all, companyId] as const,
	roles: (companyId: string) => [...membersKeys.all, 'roles', companyId] as const,
	allRoles: (companyId: string) => [...membersKeys.all, 'roles', 'all', companyId] as const,
}

export const membersQuery = (companyId: string) =>
	queryOptions({
		queryKey: membersKeys.team(companyId),
		queryFn: async () =>
			await safeFetch(MembersSchema, api.user.list(companyId), {
				method: 'GET',
			}),
	})

export const rolesQuery = (companyId: string) =>
	queryOptions({
		// NOTE: unlikely to change over the lifetime of the user session
		staleTime: Infinity,
		queryKey: membersKeys.roles(companyId),
		queryFn: async () =>
			await safeFetch(RolesSchema, api.user.roles(companyId), {
				method: 'GET',
			}),
	})

export const allRolesQuery = (companyId: string) =>
	queryOptions({
		// NOTE: unlikely to change over the lifetime of the user session
		staleTime: Infinity,
		queryKey: membersKeys.allRoles(companyId),
		queryFn: async () =>
			await safeFetch(RolesSchema, api.user.allRoles(companyId), {
				method: 'GET',
			}),
	})

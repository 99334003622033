import { type ReactNode } from 'react'
import { useFetcher } from 'react-router-dom'
import { type z } from 'zod'
import DeleteConfirm from '#src/components/delete-confirm'
import { type CompanySchema } from '#src/routes/company/schema'
import { type LRAStopLoaderRes } from '#src/routes/lead-research-agent/routes/stop'
import { type LeadResearchAgentItemAPISchema } from '#src/routes/lead-research-agent/schema'
import { routes } from '#src/utils/routes'

type Props = {
	companyId: z.infer<typeof CompanySchema>['id']
	jobId: z.infer<typeof LeadResearchAgentItemAPISchema>['id']
	children: ReactNode
}

export const JobStopModal = (props: Props) => {
	const { jobId, companyId, children } = props
	const loaderFetcher = useFetcher<LRAStopLoaderRes>()

	const onOpenChange = (data: boolean) => {
		if (data) {
			if (loaderFetcher.state === 'idle' && !loaderFetcher.data) {
				loaderFetcher.load(routes.leadResearchAgent.stop({ jobId, companyId }))
			}
		}
	}

	return (
		<DeleteConfirm
			trigger={children}
			action={routes.leadResearchAgent.stop({ companyId, jobId })}
			actionTitle="Stop job"
			title={`Stop "${loaderFetcher.data?.data.job.title ?? '...'}"?`}
			description={
				<>
					Are you sure you want to stop the <b>{loaderFetcher.data?.data.job.title ?? '...'}</b>?
				</>
			}
			onOpenChange={onOpenChange}
		/>
	)
}

import { type getInputProps, useInputControl } from '@conform-to/react'
import { cva, type VariantProps } from 'class-variance-authority'
import {
	useId,
	type InputHTMLAttributes,
	type LabelHTMLAttributes,
	type TextareaHTMLAttributes,
	type ReactNode,
	useRef,
	useEffect,
} from 'react'
import { cn } from '#src/utils/misc'
import { Checkbox, type CheckboxProps } from '../ui/checkbox'
import { Dropdown, DropdownCheckboxItem } from '../ui/dropdown'
import { Icon } from '../ui/icon'
import { Input } from '../ui/input'
import { Label } from '../ui/label'
import { Select as SelectField, SelectItem, type SelectProps } from '../ui/select'
import { Switch } from '../ui/switch'
import { Textarea } from '../ui/textarea'

export type ListOfErrors = Array<string | null | undefined> | null | undefined

/**
 * @deprecated This component is used only with V1 forms.
 */
export function ErrorList({ id, errors }: { errors?: ListOfErrors; id?: string }) {
	const errorsToRender = errors?.filter(Boolean)
	if (!errorsToRender?.length) return null
	return (
		<ul id={id} className="flex flex-col gap-1">
			{errorsToRender.map(e => (
				<li key={e} className="text-body-sm text-status-danger-fg">
					{e}
				</li>
			))}
		</ul>
	)
}

const inputClasses = cva('border rounded p-2', {
	variants: {
		variant: {
			default: '',
		},
		color: {
			default: '',
			white: 'bg-white',
		},
	},
	defaultVariants: {
		variant: 'default',
		color: 'default',
	},
})

export function Field({
	labelProps,
	inputProps,
	errors,
	className,
	variant,
	color,
	isLoading,
}: {
	labelProps?: LabelHTMLAttributes<HTMLLabelElement>
	inputProps: InputHTMLAttributes<HTMLInputElement> & { key?: string }
	errors?: ListOfErrors
	className?: string
	variant?: VariantProps<typeof inputClasses>['variant']
	color?: VariantProps<typeof inputClasses>['color']
	isLoading?: boolean
}) {
	const fallbackId = useId()
	const id = inputProps.id ?? fallbackId
	const { key, ...rest } = inputProps
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={cn('flex flex-col gap-1', className)}>
			{labelProps && <Label htmlFor={id} {...labelProps} />}
			<Input
				key={key}
				id={id}
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				className={inputClasses({ variant, color })}
				isLoading={isLoading}
				{...rest}
			/>
			{errorId ? (
				<div className="min-h-[32px] pb-3">
					<ErrorList id={errorId} errors={errors} />
				</div>
			) : null}
		</div>
	)
}

export function TextareaField({
	labelProps,
	textareaProps,
	errors,
	className,
	variant,
	color,
}: {
	labelProps?: LabelHTMLAttributes<HTMLLabelElement>
	textareaProps: TextareaHTMLAttributes<HTMLTextAreaElement> & { key?: string }
	errors?: ListOfErrors
	className?: string
	variant?: VariantProps<typeof inputClasses>['variant']
	color?: VariantProps<typeof inputClasses>['color']
}) {
	const fallbackId = useId()
	const id = textareaProps.id ?? textareaProps.name ?? fallbackId
	const { key, ...rest } = textareaProps
	const errorId = errors?.length ? `${id}-error` : undefined

	return (
		<div className={cn('flex flex-col gap-1', className)}>
			<Label htmlFor={id} {...labelProps} />
			<Textarea
				key={key}
				id={id}
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				className={inputClasses({ variant, color })}
				{...rest}
			/>
			{errorId ? (
				<div className="min-h-[32px] pb-3">
					<ErrorList id={errorId} errors={errors} />
				</div>
			) : null}
		</div>
	)
}

export function CheckboxField({
	labelProps,
	buttonProps,
	errors,
	className,
}: {
	labelProps: JSX.IntrinsicElements['label']
	buttonProps: CheckboxProps & {
		name: string
		form: string
		value?: string
	}
	errors?: ListOfErrors
	className?: string
}) {
	const { key, defaultChecked, ...checkboxProps } = buttonProps
	const fallbackId = useId()
	const checkedValue = buttonProps.value ?? 'on'
	const input = useInputControl({
		key,
		name: buttonProps.name,
		formId: buttonProps.form,
		initialValue: defaultChecked ? checkedValue : undefined,
	})
	const id = buttonProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined

	return (
		<div className={cn('flex flex-col gap-1', className)}>
			<div className="flex items-center gap-2">
				<Checkbox
					{...checkboxProps}
					id={id}
					aria-invalid={errorId ? true : undefined}
					aria-describedby={errorId}
					checked={input.value === checkedValue}
					onCheckedChange={state => {
						input.change(state.valueOf() ? checkedValue : '')
						buttonProps.onCheckedChange?.(state)
					}}
					onFocus={event => {
						input.focus()
						buttonProps.onFocus?.(event)
					}}
					onBlur={event => {
						input.blur()
						buttonProps.onBlur?.(event)
					}}
					// @ts-expect-error Ignore type mismatch because `type="button"` only causes an inconsistency with Form V1
					type="button"
				/>
				<label htmlFor={id} {...labelProps} className="self-center text-button-sm text-neutral-3-fg" />
			</div>
			{errorId ? (
				<div className="min-h-[32px] pb-3">
					<ErrorList id={errorId} errors={errors} />
				</div>
			) : null}
		</div>
	)
}

export function Select({
	labelProps,
	inputProps,
	options,
	errors,
	className,
}: {
	labelProps?: LabelHTMLAttributes<HTMLLabelElement>
	inputProps: SelectProps & {
		name: string
		form: string
		placeholder?: string
		key?: string
	}
	options: { value: string; label: ReactNode; disabled?: boolean }[]
	errors?: ListOfErrors
	className?: string
}) {
	const {
		defaultValue,
		triggerProps,
		placeholder,
		contentProps,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		key,
		...buttonProps
	} = inputProps

	const fallbackId = useId()
	const selectedValue = inputProps.value ?? ''
	const hasInitialized = useRef(false)

	const input = useInputControl({
		key: inputProps.name,
		name: inputProps.name,
		formId: inputProps.form,
		initialValue: defaultValue ? selectedValue : undefined,
	})

	const id = inputProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined

	// Set initial value
	useEffect(() => {
		if (!hasInitialized.current && options?.length) {
			const isDefaultValueValid = options.some(option => option.value === inputProps.defaultValue)
			const fallbackValue = isDefaultValueValid ? inputProps.defaultValue : options[0].value

			input.change(fallbackValue)
			hasInitialized.current = true
		}
	}, [input, inputProps.defaultValue, options])

	// Set initial value when dependent select field changes
	useEffect(() => {
		if (hasInitialized.current && options?.length && input.value) {
			const isValueValid = options.some(option => option.value === input.value)

			if (!isValueValid) {
				input.change(options[0].value)
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options])

	return (
		<div className={cn('flex flex-col gap-1', className)}>
			{labelProps && <Label htmlFor={id} {...labelProps} />}
			<SelectField
				{...buttonProps}
				value={input.value}
				onValueChange={value => {
					if (value) {
						input.change(value)
					}
					inputProps.onValueChange?.(value)
				}}
				placeholder={placeholder}
				triggerProps={{
					...triggerProps,
					['aria-invalid']: errorId ? true : undefined,
					['aria-describedby']: errorId,
					id: id,
					onFocus: event => {
						input.focus()
						triggerProps?.onFocus?.(event)
					},
					onBlur: event => {
						input.blur()
						triggerProps?.onBlur?.(event)
					},
				}}
				contentProps={{
					...contentProps,
					className: cn('w-[var(--radix-select-trigger-width)]', contentProps?.className),
				}}
			>
				{options.map(({ value, label, disabled }) => (
					<SelectItem key={value} value={value} disabled={disabled}>
						{label}
					</SelectItem>
				))}
			</SelectField>

			{errorId ? (
				<div className="min-h-[32px] pb-3">
					<ErrorList id={errorId} errors={errors} />
				</div>
			) : null}
		</div>
	)
}

export function SelectTextInput({
	labelProps,
	inputProps,
	options,
	errors,
	className,
}: {
	labelProps: LabelHTMLAttributes<HTMLLabelElement>
	inputProps: ReturnType<typeof getInputProps> &
		Pick<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onFocus' | 'autoFocus' | 'placeholder'>
	options: { value: string; label: string }[]
	errors?: ListOfErrors
	className?: string
}) {
	const { defaultValue, onFocus, onChange, ...triggerProps } = inputProps
	const fallbackId = useId()
	const selectedValue = inputProps.value ?? undefined

	const input = useInputControl({
		key: inputProps.name,
		name: inputProps.name,
		formId: inputProps.form,
		initialValue: defaultValue ? defaultValue : selectedValue,
	})

	const id = inputProps.id ?? fallbackId

	const errorId = errors?.length ? `${id}-error` : undefined

	return (
		<div className={cn('flex flex-col gap-1', className)}>
			<Label {...labelProps} />
			<Dropdown
				trigger={
					<input
						role="combobox"
						{...triggerProps}
						value={input.value}
						aria-invalid={errorId ? true : undefined}
						aria-describedby={errorId}
						className={cn(
							'transition-all',
							'flex h-10 w-full items-center justify-between rounded px-3 py-2.5',
							'bg-transparent disabled:bg-neutral-2-bg',
							'border border-neutral-2-bd outline-none hover:border-neutral-2-bd-selected focus-visible:border-brand-2-bd aria-[invalid]:border-status-danger-bd',
							'text-body-md text-neutral-1-fg placeholder:text-neutral-inverse-fg disabled:text-neutral-inverse-fg-disabled',
							'read-only:cursor-not-allowed disabled:cursor-not-allowed disabled:opacity-50',
							'file:border-0 file:bg-transparent file:text-button-sm',
							'truncate pr-8',
							className,
						)}
						onFocus={e => {
							onFocus?.(e)
							input.focus()
						}}
						onChange={e => {
							onChange?.(e)
							input.change(e.currentTarget.value)
						}}
					/>
				}
				contentProps={{
					className: 'px-0',
				}}
				suffix={
					selectedValue ? (
						<button
							type="button"
							onClick={e => {
								e.stopPropagation()
								input.change(undefined)
							}}
							className="absolute right-0 top-0 h-10 rounded p-3 text-label-sm text-neutral-2-fg focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-2-bd focus-visible:ring-offset-2"
						>
							<Icon name="cross-1" />
						</button>
					) : (
						<div className="pointer-events-none absolute right-0 top-0 h-10 p-3 text-label-sm text-neutral-2-fg">
							<Icon name="chevron-down" className="pointer-events-none" />
						</div>
					)
				}
			>
				{options.map(({ value, label }) => (
					<DropdownCheckboxItem
						key={value}
						checked={selectedValue === value}
						onCheckedChange={checked => {
							if (checked) {
								input.change(value)
							} else {
								input.change(undefined)
							}
						}}
						className="rounded-none"
					>
						{label}
					</DropdownCheckboxItem>
				))}
			</Dropdown>

			{errorId ? (
				<div className="min-h-[32px] pb-3">
					<ErrorList id={errorId} errors={errors} />
				</div>
			) : null}
		</div>
	)
}

export function SwitchField({
	labelProps,
	switchProps,
	errors,
	className,
}: {
	labelProps: JSX.IntrinsicElements['label']
	switchProps: CheckboxProps & {
		name: string
		form: string
		value?: string
	}
	errors?: ListOfErrors
	className?: string
}) {
	const { key, defaultChecked, ...checkboxProps } = switchProps
	const fallbackId = useId()
	const checkedValue = switchProps.value ?? 'on'
	const input = useInputControl({
		key,
		name: switchProps.name,
		formId: switchProps.form,
		initialValue: defaultChecked ? checkedValue : undefined,
	})
	const id = switchProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined

	return (
		<div className={cn('flex flex-col gap-1', className)}>
			<div className="flex gap-2">
				<Switch
					{...checkboxProps}
					id={id}
					aria-invalid={errorId ? true : undefined}
					aria-describedby={errorId}
					checked={input.value === checkedValue}
					onCheckedChange={state => {
						input.change(state.valueOf() ? checkedValue : '')
						switchProps.onCheckedChange?.(state)
					}}
					onFocus={event => {
						input.focus()
						switchProps.onFocus?.(event)
					}}
					onBlur={event => {
						input.blur()
						switchProps.onBlur?.(event)
					}}
					type="button"
				/>
				<label
					htmlFor={id}
					{...labelProps}
					className="flex self-center text-body-md tracking-normal text-neutral-1-fg"
				/>
			</div>
			{errorId ? (
				<div className="min-h-[32px] pb-3">
					<ErrorList id={errorId} errors={errors} />
				</div>
			) : null}
		</div>
	)
}

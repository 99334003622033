import { z } from 'zod'
import { EcosystemAPIVerticalSchema } from '#src/routes/calibrate/ecosystem/schema'
import { CompanySchema } from '#src/routes/company/schema'
import { ACCEPTED_FILE_TYPES, EXPORT_TYPE, FILE_TYPES, LRA_JOB_STATUS, MAX_UPLOAD_SIZE } from './constants'

export const LeadResearchAgentStatisticsAPISchema = z.object({
	inQueueCount: z.number(),
	inProgressCount: z.number(),
	completedCount: z.number(),
})

export const LeadResearchAgentListItemAPISchema = z.object({
	id: z.string(),
	createdAt: z.string(),
	status: z.nativeEnum(LRA_JOB_STATUS),
	title: z.string(),
	contactFinderEnabled: z.boolean(),
	comment: z.string().nullable(),
	importType: z.nativeEnum(FILE_TYPES).nullable(),
	statistic: z.object({
		accountCompletedCount: z.number(),
		accountContactCount: z.number(),
		accountCount: z.number(),
		accountInProgressCount: z.number(),
		accountInQueueCount: z.number(),
		accountWithErrorsCount: z.number(),
		accountWithQuestionsCount: z.number(),
		contactCompletedCount: z.number(),
		contactCount: z.number(),
		contactInProgressCount: z.number(),
		contactInQueueCount: z.number(),
		contactWithEmailPhoneCount: z.number(),
		contactWithErrorsCount: z.number(),
		contactWithQuestionsCount: z.number(),
		ibpFalseCount: z.number(),
		ibpNullCount: z.number(),
		ibpRatio: z.number(),
		ibpTrueCount: z.number(),
		icpFalseCount: z.number(),
		icpNullCount: z.number(),
		icpRatio: z.number(),
		icpTrueCount: z.number(),
	}),
	accountsContactsImported: z.number().nullable(),
})

export const LeadResearchAgentItemAPISchema = LeadResearchAgentListItemAPISchema.merge(
	z.object({
		verticals: z.array(
			z.object({
				id: z.string(),
				title: z.string(),
			}),
		),
		importFileUrl: z.string().nullable(),
	}),
)

export const LeadResearchAgentNewJobStepOneSchema = z.object({
	type: z.nativeEnum(FILE_TYPES),
	contactFinderEnabled: z.boolean().optional(),
	file: z
		.instanceof(File, { message: 'Please select a file' })
		.refine(file => {
			return !file || file.size <= MAX_UPLOAD_SIZE
		}, 'File size must be less than 3MB')
		.refine(file => {
			return !file || ACCEPTED_FILE_TYPES.includes(file.type)
		}, 'File must be a CSV'),
})

export const LeadResearchAgentNewJobStepTwoSchema = z.object({
	verticals: z.array(EcosystemAPIVerticalSchema.shape.id).nonempty('You need to select at least one vertical'),
})

export const LeadResearchAgentNewJobStepThreeSchema = z.object({
	title: z.string(),
	comment: z.string().optional(),
})

export const LeadResearchAgentCreateJobSchema = z
	.object({ companyId: CompanySchema.shape.id })
	.merge(LeadResearchAgentNewJobStepOneSchema)
	.merge(LeadResearchAgentNewJobStepTwoSchema)
	.merge(LeadResearchAgentNewJobStepThreeSchema)

export const LeadResearchAgentJobDownloadFormSchema = z.object({
	type: z.nativeEnum(EXPORT_TYPE, { message: 'Incorrect exporting type' }),
})

export const LeadResearchAgentTemplateDownloadFormSchema = z.object({
	type: z.nativeEnum(FILE_TYPES, { message: 'Incorrect exporting type' }),
})

import { type QueryClient } from '@tanstack/react-query'
import { type LoaderFunctionArgs } from 'react-router-dom'
import { signalsTemplatesQuery } from '#src/routes/prioritize/queries'

export type PrioritizeModuleLoaderResponse = Awaited<ReturnType<ReturnType<typeof loader>>>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const signals = await queryClient.fetchQuery(signalsTemplatesQuery(params.companyId))

		if (!signals)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		return {
			handle: {
				companyId: params.companyId,
			},
			signals,
		}
	}

import { clsx, type ClassValue } from 'clsx'
import { useFormAction, useNavigation } from 'react-router-dom'
import { extendTailwindMerge } from 'tailwind-merge'

import { type z } from 'zod'
import { type RoleDataType, type UserDataType } from '#src/routes/auth/schema'
import { type EcosystemAPISchema } from '#src/routes/calibrate/ecosystem/schema'
import { type VoiceSchema } from '#src/routes/enable/roleplay/scenario/schema'
import { PERSONA_TYPES } from './enumerations'
import { extendedTheme } from './extended-theme'
import { theme } from './theme'

type RolesHierarchyType = {
	[key: string]: string[]
}

export type RecursiveNestedItem = {
	id: string
	children?: RecursiveNestedItem[]
}

//TODO: Remove data-trainer eventually
const rolesHierarchy: RolesHierarchyType = {
	admin: ['admin', 'user', 'enable-only', 'data-trainer', 'read-only'],
	user: ['user', 'enable-only', 'data-trainer', 'read-only'],
	enableOnly: ['enable-only', 'read-only'],
	readOnly: ['read-only'],
}

function formatColors() {
	const colors = []

	for (const [key, color] of Object.entries(extendedTheme.colors)) {
		if (typeof color === ('string' as string)) {
			colors.push(key)
		} else {
			const colorGroup = Object.keys(color).map(subKey => (subKey === 'DEFAULT' ? '' : subKey))
			colors.push({ [key]: colorGroup })
		}
	}

	return colors
}

const customTwMerge = extendTailwindMerge<string, string>({
	override: {
		classGroups: {
			'font-size': [{ text: Object.keys(theme.fontSize) }],
		},
	},
	extend: {
		theme: {
			colors: formatColors(),
		},
	},
})

/**
 * A common use-case for this is to whenever `className`
 * is a pass through property to an already styled element
 * This ensures that there are no clashing styles
 *
 * @param inputs ClassValue[]
 * @returns
 */
export function cn(...inputs: ClassValue[]) {
	return customTwMerge(clsx(inputs))
}

/**
 * Returns true if the current navigation is submitting the current route's
 * form. Defaults to the current route's form action and method POST.
 *
 * Defaults state to 'non-idle'
 *
 * NOTE: the default formAction will include query params, but the
 * navigation.formAction will not, so don't use the default formAction if you
 * want to know if a form is submitting without specific query params.
 */
export function useIsPending({
	formAction,
	formMethod = 'POST',
	state = 'non-idle',
}: {
	formAction?: string
	formMethod?: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE'
	state?: 'submitting' | 'loading' | 'non-idle'
} = {}) {
	const contextualFormAction = useFormAction()
	const navigation = useNavigation()
	const isPendingState = state === 'non-idle' ? navigation.state !== 'idle' : navigation.state === state
	return (
		isPendingState &&
		navigation.formAction === (formAction ?? contextualFormAction) &&
		navigation.formMethod?.toUpperCase() === formMethod
	)
}

export type ErrorResponse = {
	status: number
	statusText: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any
}

/**
 * Check if the given error is an error Response generated from a 4xx/5xx
 * Response thrown from an action/loader
 */
export function isRouteErrorResponse(error: unknown): error is ErrorResponse {
	return (
		(error != null &&
			typeof error === 'object' &&
			'status' in error &&
			typeof error.status === 'number' &&
			'data' in error &&
			typeof error.data !== 'undefined') ||
		error instanceof Response
	)
}

export const checkType = <Output = never, Def extends z.ZodTypeDef = z.ZodTypeDef, Input = Output>(
	schema: z.ZodType<Output, Def, Input>,
	data: unknown,
): data is Input => schema.safeParse(data).success

export const checkIsReadOnlySession = (roles: RoleDataType) => roles.includes('read-only')
export const checkIsEnableOnlySession = (roles: RoleDataType) => roles.includes('enable-only')
export const checkIsDataTrainerSession = (roles: RoleDataType) => roles.includes('data-trainer')
export const checkIsAdminSession = (roles: RoleDataType) => roles.includes('admin')
export const checkIsUserSession = (roles: RoleDataType) => roles.includes('user')

export function formatPersonaTypeAbbr(type: string) {
	if (type === PERSONA_TYPES.decisionmaker) return 'DM'
	if (type === PERSONA_TYPES.champion) return 'CH'
	if (type === PERSONA_TYPES.influencer) return 'INF'
	return 'P'
}

export const formatAvatarAbbr = (initial: string) => initial?.slice(0, 1).toUpperCase()

// 	.replaceAll('\n', ' ')
// 	.replaceAll(new RegExp(/[.,/#!$%^&*;:{}=\-_`~()"?“”]/g, 'g'), ' ')
// 	.split(' ')
// 	.filter(w => Boolean(w)).length ?? 0
export const getWordCount = (value: string): number => value?.replaceAll('\n', ' ').split(' ').length ?? 0

export const calculatePct = (currentValue: number, totalValue: number, decimals: number = 0): number => {
	if (totalValue === 0) {
		return 0
	}

	const decimalsMultiplier = new Array(decimals).fill(10).reduce((acc, value) => acc * value, 1) as number

	return Math.round((currentValue / totalValue) * (100 * decimalsMultiplier)) / decimalsMultiplier
}

export const canSee = (allow: RoleDataType, user: UserDataType, hideIfReadOnly?: boolean): boolean => {
	const allowedRoles = Array.isArray(allow) ? allow : [allow]
	const { roles }: UserDataType = user

	if (hideIfReadOnly && roles.includes('read-only')) return false

	if (roles.includes('admin')) return true

	if (roles.includes('user')) {
		return allowedRoles.some(role => rolesHierarchy.user.includes(role))
	}

	if (roles.includes('enable-only')) {
		return allowedRoles.some(role => rolesHierarchy.enableOnly.includes(role))
	}

	if (roles.includes('read-only')) {
		return allowedRoles.some(role => rolesHierarchy.user.includes(role))
	}

	return false
}

export const getVoiceNameById = (
	voices: z.infer<typeof VoiceSchema>[],
	personalityOrVoiceId: { voice: string } | string,
): string | undefined => {
	const voiceId = typeof personalityOrVoiceId === 'string' ? personalityOrVoiceId : personalityOrVoiceId.voice

	return voices.find(v => v.voiceId === voiceId)?.name
}

export const toSentenceCase = (sentence: string): string =>
	sentence
		.split(' ')
		.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
		.join(' ')

export const collectIdsRecursively = <T extends RecursiveNestedItem>(items: T[]): string[] => {
	const ids: string[] = []

	const traverse = (nodes: RecursiveNestedItem[]) => {
		for (const node of nodes) {
			ids.push(node.id)

			if (node.children) {
				traverse(node.children)
			}
		}
	}

	traverse(items)

	return ids
}

export const buildQueryParams = (queryParams?: Record<string, string | number | undefined | boolean | null>) => {
	if (!queryParams) return ''

	const filteredParams = Object.fromEntries(
		Object.entries(queryParams).filter(([, value]) => !(value === undefined || value === null)),
	)

	const params = new URLSearchParams(filteredParams as Record<string, string>)

	return params.toString() ? `?${params.toString()}` : ''
}

export const findEcosystemByVerticalId = (verticalId: string, ecosystems: z.infer<typeof EcosystemAPISchema>[]) => {
	return ecosystems.find(ecosystem => ecosystem.verticals?.some(vertical => String(vertical.id) === verticalId))
}

export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

export const formatToSentenceCase = (str: string, capitalizeFirst: boolean = false) => {
	const formattedStr = str
		.replace(/-/g, ' ')
		.replace(/([a-z])([A-Z])/g, '$1 $2')
		.toLowerCase()

	return capitalizeFirst ? capitalizeFirstLetter(formattedStr) : formattedStr
}

import { type z } from 'zod'
import { api } from '#src/utils/endpoints'
import { safeFetch } from '#src/utils/safeFetch'
import { InviteAPISchema, type InviteFormSchema } from './schema'

export const sendInvite = async (companyId: string, data: z.infer<typeof InviteFormSchema>) => {
	return await safeFetch(InviteAPISchema, api.user.invite(companyId), {
		method: 'POST',
		body: JSON.stringify(data),
	})
}

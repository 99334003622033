import { getFormProps, getInputProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { type QueryClient } from '@tanstack/react-query'
import { Fragment } from 'react'
import { type ActionFunctionArgs, useFetcher, type LoaderFunctionArgs, redirect, useLoaderData } from 'react-router-dom'
import { type z } from 'zod'
import { ErrorList, Field } from '#src/components/forms'
import { StatusButton } from '#src/components/ui/status-button'
import { Surface } from '#src/components/ui/surface'
import { companyKeys, companyQuery } from '#src/routes/company/queries'
import { type CompanySchema } from '#src/routes/company/schema'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import { setCompanyDashboard } from './mutations'
import { CompanyDashboardFormSchema } from './schema'

type EditLoaderResponse = Awaited<ReturnType<ReturnType<typeof loader>>>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const company = await queryClient.fetchQuery(companyQuery(params.companyId))

		if (!company) {
			throw new Response('Company Not Found', {
				status: 404,
				statusText: 'Not Found',
			})
		}

		return {
			dashboard: company.dashboard,
		}
	}

type EditActionResponse = Awaited<ReturnType<ReturnType<typeof action>>>

export const action =
	(queryClient: QueryClient) =>
	async ({ params, request }: ActionFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const formData = await request.formData()

		const submission = parseWithZod(formData, {
			schema: CompanyDashboardFormSchema,
		})

		if (submission.status !== 'success') {
			throw Error('Failed to parse form data')
		}

		try {
			await setCompanyDashboard(params.companyId, submission.value)

			await queryClient.refetchQueries({
				queryKey: companyKeys.detail(params.companyId),
			})

			return redirect(routes.admin.index)
		} catch (error) {
			let errorMessage = 'Something went wrong with updating company dashbaord details. Try again later.'

			if (error instanceof Response) {
				const errorBody = (await error.json()) as { message?: string }

				if (errorBody?.message) {
					errorMessage = errorBody.message
				}
			}

			return {
				ok: false,
				result: submission.reply({
					formErrors: [errorMessage],
				}),
			}
		}
	}

export default function EditCompanyDashboard() {
	const data = useLoaderData() as EditLoaderResponse

	return (
		<main className="grid h-full w-full grid-cols-1 grid-rows-1 items-start justify-items-center">
			<Surface className="flex max-w-screen-md flex-col items-center gap-6 px-24 py-10">
				<h1 className="text-center text-title-md">Edit Company Dashboard URLs</h1>

				<EditCompanyDashboardForm dashboard={data.dashboard} />
			</Surface>
		</main>
	)
}

function EditCompanyDashboardForm({ dashboard }: { dashboard: z.infer<typeof CompanySchema>['dashboard'] }) {
	const params = useParsedRouteParams(['companyId'])
	const fetcher = useFetcher()
	const fetcherData = fetcher.data as EditActionResponse

	const action = `/admin/company/${params.companyId}/edit`

	const [form, fields] = useForm({
		id: 'company-edit-form-' + params.companyId,
		constraint: getZodConstraint(CompanyDashboardFormSchema),
		defaultValue: { dashboard },
		lastResult: !(fetcherData instanceof Response) ? fetcherData?.result : null,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: CompanyDashboardFormSchema })
		},
		shouldValidate: 'onBlur',
		shouldRevalidate: 'onInput',
	})

	const list = fields.dashboard.getFieldList()

	return (
		<fetcher.Form method="POST" {...getFormProps(form)} action={action} className="flex w-full flex-col gap-6">
			{list.map((field, index) => {
				const input = field.getFieldset()
				const label = dashboard?.find(d => d.id.toString() === input.id.value)?.name ?? ''
				return (
					<Fragment key={input.id.value ?? index}>
						{input.id.value ? <input {...getInputProps(input.id, { type: 'hidden' })} /> : null}
						<Field
							labelProps={{ children: label }}
							inputProps={{
								...getInputProps(input.url, { type: 'text' }),
								placeholder: 'Enter company name',
							}}
							errors={input.url.errors}
						/>
					</Fragment>
				)
			})}

			<ErrorList errors={form.errors} id={form.errorId} />

			<div className="flex w-full justify-end gap-2">
				<StatusButton
					status={fetcher.state !== 'idle' ? 'pending' : 'idle'}
					type="submit"
					disabled={fetcher.state !== 'idle'}
					size="sm"
					className="min-w-32"
				>
					Save
				</StatusButton>
			</div>
		</fetcher.Form>
	)
}

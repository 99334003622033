import { type z } from 'zod'
import { admin } from '#src/utils/endpoints'
import { safeFetch, fetch } from '#src/utils/safeFetch'
import { CompanySchema } from '../company/schema'
import { type CompanyDashboardFormSchema, type NewCompanyFormSchema } from './schema'

export const createCompany = async (data: z.infer<typeof NewCompanyFormSchema>) =>
	await safeFetch(CompanySchema, admin.create, {
		method: 'POST',
		body: JSON.stringify(data),
	})

export const deleteCompany = async (companyId: string) => {
	return await fetch(admin.details(companyId), {
		method: 'DELETE',
	})
}

export const setCompanyDashboard = async (companyId: string, data: z.infer<typeof CompanyDashboardFormSchema>) => {
	return await safeFetch(CompanySchema, admin.dashboard(companyId), {
		method: 'PATCH',
		body: JSON.stringify(data),
	})
}

import { type QueryClient } from '@tanstack/react-query'
import { type RouteObject } from 'react-router-dom'
import ChatsPanel from '#src/routes/enable/chat/_layout/ChatsPanel'
import ChatsPanelHeader from '#src/routes/enable/chat/_layout/ChatsPanelHeader'
import DeleteChat, { action as deleteChatAction } from '#src/routes/enable/chat/delete'
import Chat, { type ChatLoaderResponse, loader as chatLoader } from '#src/routes/enable/chat/index'
import ChatContact, { loader as chatContactLoader } from '#src/routes/enable/chat/info/contact'
import ChatPersona, { loader as chatPersonaLoader } from '#src/routes/enable/chat/info/persona'
import NewChat, { action as chatNewAction, loader as chatNewLoader } from '#src/routes/enable/chat/new'
import { action as restartChatAction } from '#src/routes/enable/chat/restart'
import RouteBoundary from '#src/routes/route-boundary'
import { routes } from '#src/utils/routes'
import { type EnableChatLoaderResponse, loader as chatModuleLoader } from './loaders/root'

export const chatRouter = (client: QueryClient) =>
	({
		path: 'copilot',
		loader: chatModuleLoader,
		handle: {
			crumb: (data: EnableChatLoaderResponse) => [
				{
					to: routes.enable.copilot.index({ companyId: data.handle.companyId, conversationId: null }),
					label: 'Co-Pilot chats',
					icon: 'chat',
				},
			],
		},
		children: [
			{
				path: '',
				loader: chatLoader(client),
				element: <Chat />,
				handle: {
					layout: {
						enableHeader: true,
					},
				},
				children: [
					{
						path: 'new',
						element: <NewChat />,
						loader: chatNewLoader(client),
						action: chatNewAction(client),
						handle: {
							layout: {
								enableHeader: true,
							},
						},
					},
				],
			},
			{
				path: ':conversationId',
				id: 'copilot-module',
				element: <Chat />,
				errorElement: <RouteBoundary />,
				loader: chatLoader(client),
				handle: {
					layout: {
						enableHeader: true,
						noPadding: true,
						sidePanelHeader: <ChatsPanelHeader />,
						sidePanelContent: <ChatsPanel />,
					},
					crumb: (data: ChatLoaderResponse) => [
						{
							to: routes.enable.copilot.index({
								companyId: data.handle.companyId,
								conversationId: data.handle.conversationId!,
							}),
							label: data.conversationData?.conversation.name,
							icon: 'user',
						},
					],
					action: (data: ChatLoaderResponse) => [
						...(data.handle.canRestartChat
							? [
									{
										id: 'chat-restart',
										action: routes.enable.copilot.restart({
											companyId: data.handle.companyId,
											conversationId: data.handle.conversationId!,
										}),
										method: 'PUT',
										label: 'Restart Chat',
										icon: 'reset',
									},
								]
							: []),
						{
							to: routes.enable.copilot.persona({
								companyId: data.handle.companyId,
								conversationId: data.handle.conversationId!,
								properties: 'pain-points',
							}),
							label: 'Persona Info',
						},
						{
							to: routes.enable.copilot.contact({
								companyId: data.handle.companyId,
								conversationId: data.handle.conversationId!,
							}),
							label: 'Contact Info',
						},
						...(data.handle.canDeleteChat
							? [
									{
										to: routes.enable.copilot.delete({
											companyId: data.handle.companyId,
											conversationId: data.handle.conversationId!,
										}),
										icon: 'trash',
										className: 'text-status-danger-fg',
									},
								]
							: []),
					],
				},
				children: [
					{
						path: 'new',
						element: <NewChat />,
						loader: chatNewLoader(client),
						action: chatNewAction(client),
					},
					{
						path: 'restart',
						action: restartChatAction(client),
					},
					{
						path: 'delete',
						element: <DeleteChat />,
						action: deleteChatAction(client),
					},
					{
						path: 'persona/:properties',
						element: <ChatPersona />,
						loader: chatPersonaLoader(client),
					},
					{
						path: 'contact',
						element: <ChatContact />,
						loader: chatContactLoader(client),
					},
				],
			},
		],
	}) satisfies RouteObject

import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { type PersonasExpertiseQueryPayload, type PersonasQueryPayload } from '#src/routes/enable/personas/schema'
import { type FILE_TYPES } from '#src/routes/lead-research-agent/constants'
import { type LeadResearchAgentItemAPISchema } from '#src/routes/lead-research-agent/schema'
import { buildQueryParams } from '#src/utils/misc'

export const baseApi = import.meta.env.VITE_API_URL
export const adminApi = import.meta.env.VITE_ADMIN_API_URL
export const organizeApi = import.meta.env.VITE_ORGANIZE_API_URL

export const admin = {
	create: `${adminApi}/company`,
	details: (companyId: string) => `${adminApi}/company/${companyId}`,
	dashboard: (companyId: string) => `${adminApi}/company/${companyId}/dashboard`,
	conversation: (companyId: string, conversationId: string) =>
		`${adminApi}/company/${companyId}/copilot/conversation/${conversationId}/restart`,
	roleplay: (companyId: string, conversationId: string) =>
		`${adminApi}/company/${companyId}/chat/roleplay/${conversationId}/restart`,
}

export const api = {
	user: {
		me: `${baseApi}/user/me`,
		token: (token: string) => `${baseApi}/invitation/${token}`,
		password: `${baseApi}/invitation/password`,
		invite: (companyId: string) => `${baseApi}/company/${companyId}/user/invite`,
		list: (companyId: string) => `${baseApi}/company/${companyId}/user/list`,
		roles: (companyId: string) => `${baseApi}/company/${companyId}/user/roles`,
		allRoles: (companyId: string) => `${baseApi}/company/${companyId}/user/roles/all`,
	},
	company: {
		list: `${baseApi}/company/list`,
		details: (companyId: string) => `${baseApi}/company/${companyId}`,
		stats: (companyId: string) => `${baseApi}/company/${companyId}/stats`,
		sort: (companyId: string) => `${baseApi}/company/${companyId}/ecosystem/sort`,
	},
	ecosystem: {
		create: (companyId: string) => `${baseApi}/company/${companyId}/ecosystem`,
		list: (
			companyId: string,
			queryParams?: {
				verticals?: boolean
				personas?: boolean
			},
		) => `${baseApi}/company/${companyId}/ecosystem/list${buildQueryParams(queryParams)}`,
		details: (companyId: string, ecosystemId: string) => `${baseApi}/company/${companyId}/ecosystem/${ecosystemId}`,
		criteria: (companyId: string, ecosystemId: string) =>
			`${baseApi}/company/${companyId}/ecosystem/${ecosystemId}/criteria`,
		datapoints: (companyId: string, ecosystemId: string) =>
			`${baseApi}/company/${companyId}/ecosystem/${ecosystemId}/datapoints`,
		verticals: (companyId: string, ecosystemId: string) =>
			`${baseApi}/company/${companyId}/ecosystem/${ecosystemId}/verticals`,
		templates: (companyId: string) => `${baseApi}/company/${companyId}/ecosystem/templates`,
		editQualCriteria: (companyId: string, ecosystemId: string) =>
			`${baseApi}/company/${companyId}/ecosystem/${ecosystemId}/qualifications`,
		editEnrichCriteria: (companyId: string, ecosystemId: string) =>
			`${baseApi}/company/${companyId}/ecosystem/${ecosystemId}/datapoints`,
	},
	criterion: {
		create: (companyId: string) => `${baseApi}/company/${companyId}/criterion`,
		details: (companyId: string, criterionId: string) => `${baseApi}/company/${companyId}/criterion/${criterionId}`,
	},
	datapoint: {
		create: (companyId: string) => `${baseApi}/company/${companyId}/datapoint`,
		details: (companyId: string, dataPointId: string) => `${baseApi}/company/${companyId}/datapoint/${dataPointId}`,
	},
	vertical: {
		create: (companyId: string) => `${baseApi}/company/${companyId}/vertical`,
		details: (companyId: string, verticalId: number) => `${baseApi}/company/${companyId}/vertical/${verticalId}`,
		personas: (companyId: string, verticalId: string) =>
			`${baseApi}/company/${companyId}/vertical/${verticalId}/personas`,
		recalculate: (companyId: string, verticalId: string) =>
			`${baseApi}/company/${companyId}/vertical/${verticalId}/recalculate`,
	},
	persona: {
		create: (companyId: string) => `${baseApi}/company/${companyId}/persona`,
		details: (companyId: string, personaId: string) => `${baseApi}/company/${companyId}/persona/${personaId}`,
		recalculate: (companyId: string, personaId: string) =>
			`${baseApi}/company/${companyId}/persona/${personaId}/recalculate`,
		copyValidate: (companyId: string) => `${baseApi}/company/${companyId}/persona/copy-validate`,
		copy: (companyId: string) => `${baseApi}/company/${companyId}/persona/copy`,
		batchDelete: (companyId: string) => `${baseApi}/company/${companyId}/persona/mass`,
		list: (companyId: string, queryParams: PersonasQueryPayload) => {
			const queryString = buildQueryParams(queryParams)
			return `${baseApi}/company/${companyId}/persona/list${queryString}`
		},
		expertiseList: (companyId: string, queryParams: PersonasExpertiseQueryPayload) => {
			const queryString = buildQueryParams(queryParams)
			return `${baseApi}/company/${companyId}/persona/expertise${queryString}`
		},
	},
	salescoach: {
		create: (companyId: string, personaId: string) => `${baseApi}/company/${companyId}/salescoach/${personaId}`,
		list: (companyId: string, personaId: string) => `${baseApi}/company/${companyId}/salescoach/${personaId}`,
		details: (companyId: string, personaId: string, salescoachId: number) =>
			`${baseApi}/company/${companyId}/salescoach/${personaId}/${salescoachId}`,
		recalculate: (companyId: string, personaId: string, salescoachId: string) =>
			`${baseApi}/company/${companyId}/salescoach/${personaId}/recalculate/${salescoachId}`,
	},
	signal: {
		list: (companyId: string) => `${baseApi}/company/${companyId}/signal/all`,
		assigned: (companyId: string) => `${baseApi}/company/${companyId}/signal`,
		assign: (companyId: string, signalId: string) => `${baseApi}/company/${companyId}/signal/${signalId}/assign`,
		config: (companyId: string, signalId: string) => `${baseApi}/company/${companyId}/signal/${signalId}/config`,
		example: (companyId: string, signalId: string, personaId: string) =>
			`${baseApi}/company/${companyId}/signal/${signalId}/example/${personaId}`,
	},
	chat: {
		contact: (contactId: string) => `${baseApi}/company/copilot/contact/${contactId}`,
		status: (companyId: string, conversationId: string) =>
			`${baseApi}/company/${companyId}/copilot/conversation/${conversationId}/status`,
		messages: (companyId: string, conversationId: string) =>
			`${baseApi}/company/${companyId}/copilot/conversation/${conversationId}/messages`,
		new: (companyId: string) => `${baseApi}/company/${companyId}/copilot/conversation/linkedin`,
		context: (companyId: string, conversationId: string) =>
			`${baseApi}/company/${companyId}/copilot/conversation/${conversationId}`,
		delete: (companyId: string, conversationId: string) =>
			`${baseApi}/company/${companyId}/copilot/conversation/${conversationId}`,
		list: (companyId: string) => `${baseApi}/company/${companyId}/copilot/conversation/list`,
		validateLinkedinUrl: (companyId: string) => `${baseApi}/company/${companyId}/chat/linkedin/save`,
	},
	roleplay: {
		session: {
			new: (companyId: string) => `${baseApi}/company/${companyId}/chat/roleplay/session/new`,
			list: (companyId: string, page?: number) =>
				`${baseApi}/company/${companyId}/chat/roleplay/session/list${page ? `?page=${page}` : ''}`,
			index: (companyId: string, conversationId: string) =>
				`${baseApi}/company/${companyId}/chat/roleplay/session/${conversationId}`,
			end: (companyId: string, conversationId: string) =>
				`${baseApi}/company/${companyId}/chat/roleplay/session/${conversationId}/end`,
			status: (companyId: string, conversationId: string) =>
				`${baseApi}/company/${companyId}/chat/roleplay/session/${conversationId}/status`,
			messages: (companyId: string, conversationId: string) =>
				`${baseApi}/company/${companyId}/chat/roleplay/session/${conversationId}/messages`,
			restart: (companyId: string, conversationId: string) =>
				`${baseApi}/company/${companyId}/chat/roleplay/session/${conversationId}/restart`,
		},
		scenario: {
			index: (companyId: string, page?: number) =>
				`${baseApi}/company/${companyId}/chat/roleplay/scenario${page ? `?page=${page}` : ''}`,
			detail: (companyId: string, scenarioId: string) =>
				`${baseApi}/company/${companyId}/chat/roleplay/scenario/${scenarioId}`,
			update: (companyId: string, scenarioId: string) =>
				`${baseApi}/company/${companyId}/chat/roleplay/scenario/${scenarioId}`,
			templates: (companyId: string) => `${baseApi}/company/${companyId}/chat/roleplay/scenario/templates`,
			voices: (companyId: string) => `${baseApi}/company/${companyId}/chat/roleplay/scenario/voices`,
			assignedUsers: (companyId: string, scenarioId: string) =>
				`${baseApi}/company/${companyId}/chat/roleplay/scenario/${scenarioId}/assigned`,
			assignUser: (companyId: string, scenarioId: string, userId: string) =>
				`${baseApi}/company/${companyId}/chat/roleplay/scenario/${scenarioId}/assign/${userId}`,
			createTestSession: (companyId: string, scenarioId: string) =>
				`${baseApi}/company/${companyId}/chat/roleplay/scenario/${scenarioId}/self`,
			managerAccessSessionDetails: (companyId: string, scenarioId: string, conversationId: string) =>
				`${baseApi}/company/${companyId}/chat/roleplay/scenario/${scenarioId}/view/${conversationId}`,
		},
	},
	glossary: {
		list: (companyId: string) => `${baseApi}/company/${companyId}/glossary`,
		create: (companyId: string) => `${baseApi}/company/${companyId}/glossary`,
		details: (companyId: string, termId: string) => `${baseApi}/company/${companyId}/glossary/${termId}`,
	},
	aidt: {
		context: (companyId: string, conversationId: string) =>
			`${baseApi}/company/${companyId}/chat/data-trainer/${conversationId}`,
		list: (companyId: string) => `${baseApi}/company/${companyId}/chat/data-trainer/list`,
		messages: (companyId: string, conversationId: string) =>
			`${baseApi}/company/${companyId}/chat/data-trainer/${conversationId}/messages`,
	},
	decisionTree: {
		details: (companyId: string) => `${baseApi}/company/${companyId}/decision-tree`,
		create: (companyId: string) => `${baseApi}/company/${companyId}/decision-tree`,
	},
	leadResearchAgent: {
		jobs: {
			statistics: (companyId: z.infer<typeof CompanySchema>['id']) =>
				`${organizeApi}/lists/company/${companyId}/statistics`,
			list: (companyId: z.infer<typeof CompanySchema>['id'], page: number, sort: string[]) => {
				const searchParams = new URLSearchParams({
					companyId,
					page: page.toString(),
				})
				sort.forEach(value => {
					searchParams.append('sort[]', value)
				})
				return `${organizeApi}/lists/?${searchParams.toString()}`
			},
			item: (jobId: z.infer<typeof LeadResearchAgentItemAPISchema>['id']) => `${organizeApi}/lists/${jobId}`,
			stop: (jobId: z.infer<typeof LeadResearchAgentItemAPISchema>['id']) => `${organizeApi}/lists/${jobId}/cancel`,
			download: (jobId: z.infer<typeof LeadResearchAgentItemAPISchema>['id'], query: Record<string, string>) =>
				`${organizeApi}/lists-csv/${jobId}?${new URLSearchParams(query).toString()}`,
			create: `${organizeApi}/lists/`,
			downloadTemplate: (type: FILE_TYPES) => `${organizeApi}/lists-csv/template/${type}`,
		},
	},
}

import { type z } from 'zod'
import { EXPORT_TYPE, type FILE_TYPES } from '#src/routes/lead-research-agent/constants'
import {
	type LeadResearchAgentCreateJobSchema,
	type LeadResearchAgentItemAPISchema,
} from '#src/routes/lead-research-agent/schema'
import { api } from '#src/utils/endpoints'
import { objToFormData } from '#src/utils/forms'
import { fetch } from '#src/utils/safeFetch'

export const stopJob = async (jobId: z.infer<typeof LeadResearchAgentItemAPISchema>['id']) => {
	return await fetch(api.leadResearchAgent.jobs.stop(jobId), {
		method: 'POST',
	})
}

export const downloadJob = async (jobId: z.infer<typeof LeadResearchAgentItemAPISchema>['id'], type: EXPORT_TYPE) => {
	const query = ((): Record<string, string> => {
		switch (type) {
			case EXPORT_TYPE.Full:
				return { type: 'account_contact' }
			case EXPORT_TYPE.Accounts:
				return { type: 'accounts' }
			case EXPORT_TYPE.Contacts:
				return { type: 'contact' }
			case EXPORT_TYPE.ICPAccounts:
				return { type: 'account', icp: 'true' }
			case EXPORT_TYPE.IBPContacts:
				return { type: 'contact', ibp: 'true' }
			case EXPORT_TYPE.ICPIBPFull:
				return { type: 'account_contact', icp: 'true', ibp: 'true' }
		}
	})()
	return await fetch(api.leadResearchAgent.jobs.download(jobId, query), {
		method: 'GET',
	})
}

export const downloadTemplate = async (type: FILE_TYPES) => {
	return await fetch(api.leadResearchAgent.jobs.downloadTemplate(type), {
		method: 'GET',
	})
}

export const createJob = async (data: z.infer<typeof LeadResearchAgentCreateJobSchema>) => {
	const body = objToFormData(data)
	return await fetch(api.leadResearchAgent.jobs.create, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
		},
		body,
	})
}

import { queryOptions } from '@tanstack/react-query'
import { api } from '#src/utils/endpoints'
import { safeFetch } from '#src/utils/safeFetch'
import { ChatSchema, ChatsSchema, ConversationDataSchema } from './schema'

export const aidtKeys = {
	all: ['enable', 'aidt'] as const,
	conversation: (companyId: string, conversationId: string) =>
		[...aidtKeys.all, 'chat', companyId, conversationId] as const,
	chats: (companyId: string) => [...aidtKeys.all, 'chats', companyId] as const,
	chat: (companyId: string, conversationId: string) => [...aidtKeys.all, 'chats', companyId, conversationId] as const,
}

export const conversationQuery = (companyId: string, conversationId: string) => ({
	queryKey: aidtKeys.conversation(companyId, conversationId),
	queryFn: async () =>
		await safeFetch(ConversationDataSchema, api.aidt.context(companyId, conversationId), {
			method: 'GET',
		}),
})

export const chatsQuery = (companyId: string) =>
	queryOptions({
		queryKey: aidtKeys.chats(companyId),
		queryFn: async () => {
			return await safeFetch(ChatsSchema, api.aidt.list(companyId), {
				method: 'GET',
			})
		},
	})

export const chatQuery = (companyId: string, conversationId: string) =>
	queryOptions({
		queryKey: aidtKeys.chat(companyId, conversationId),
		queryFn: async () => {
			return await safeFetch(ChatSchema, api.aidt.messages(companyId, conversationId), {
				method: 'GET',
			})
		},
	})

import { type QueryClient } from '@tanstack/react-query'
import { type LoaderFunctionArgs } from 'react-router-dom'
import { conversationQuery } from '#src/routes/enable/roleplay/session/queries'

export type JoinRoleplayChatLoaderType = Awaited<ReturnType<ReturnType<typeof joinRoleplayChatLoader>>>

export const joinRoleplayChatLoader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId || !params.conversationId) {
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})
		}

		const conversationData = await queryClient.fetchQuery(conversationQuery(params.companyId, params.conversationId))

		return {
			companyId: params.companyId,
			conversationId: params.conversationId,
			conversationData,
		}
	}

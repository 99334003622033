import { parseWithZod } from '@conform-to/zod'
import { type QueryClient } from '@tanstack/react-query'
import { type ActionFunctionArgs } from 'react-router-dom'
import { showToast } from '#src/context/ToastContext'
import { downloadTemplate } from '#src/routes/lead-research-agent/mutations'
import { leadResearchAgentJobsKeys } from '#src/routes/lead-research-agent/queries'
import { LeadResearchAgentTemplateDownloadFormSchema } from '#src/routes/lead-research-agent/schema'
import { makeDownloadableFromRes } from '#src/utils/files'

export type ActionRes = Awaited<ReturnType<ReturnType<typeof action>>>

export const action =
	(queryClient: QueryClient) =>
	async ({ request }: ActionFunctionArgs) => {
		const formData = await request.formData()

		const submission = parseWithZod(formData, {
			schema: LeadResearchAgentTemplateDownloadFormSchema,
		})

		if (submission.status !== 'success') {
			showToast({ message: 'Unsupported template type', type: 'error' })
			return { success: false }
		}

		try {
			const res = await downloadTemplate(submission.value.type)
			await makeDownloadableFromRes(res, `${submission.value.type}-template`)
			await queryClient.invalidateQueries({
				queryKey: leadResearchAgentJobsKeys.all,
			})
			return {
				success: true,
			}
		} catch (e) {
			const res = (e as Response).clone()
			const body = (await res.json()) as { success: boolean; message?: string }
			showToast({
				message: body?.message ?? res?.statusText,
				type: 'error',
			})
			return body
		}
	}

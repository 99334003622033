import { type QueryClient } from '@tanstack/react-query'

import { userQuery } from '#src/routes/auth/queries'

export type AdminLoaderResponse = Awaited<ReturnType<ReturnType<typeof loader>>>

export const loader = (queryClient: QueryClient) => async () => {
	const user = await queryClient.fetchQuery(userQuery())

	if (!user.roles.includes('admin')) {
		throw new Response('Page Not Found', {
			status: 404,
			statusText: 'Not Found',
		})
	}

	return null
}

import { type LoaderFunctionArgs } from 'react-router-dom'
import { z } from 'zod'
import { getUserByToken } from '#src/routes/auth/queries'
import { UserByTokenAPISchema } from '#src/routes/auth/schema'
import { checkSession, clearSession } from '#src/routes/auth/utils'

export const LoaderSchema = z.object({
	user: UserByTokenAPISchema,
})

export type LoaderResType = Awaited<ReturnType<typeof loader>>

export type LoaderRes = z.infer<typeof LoaderSchema>

export const loader = async ({ params }: LoaderFunctionArgs): Promise<LoaderRes> => {
	if (!params.token) {
		throw new Response('Missing parameters', {
			status: 400,
			statusText: 'Bad Request',
		})
	}

	if (checkSession()) {
		clearSession()
	}

	const user = await getUserByToken(params.token)

	return {
		user,
	}
}

import { type QueryClient } from '@tanstack/react-query'
import { Link, useLoaderData, type LoaderFunctionArgs } from 'react-router-dom'
import { Icon } from '#src/components/ui/icon'
import { Label } from '#src/components/ui/label'
import { Sidebar, SidebarHeader } from '#src/components/ui/sidebar'
import { routes } from '#src/utils/routes'
import { conversationQuery } from '../queries'
import { CONTACT_INFO_KEY_MAP } from '../schema'

export type ContactInfoLoaderResponse = Awaited<ReturnType<ReturnType<typeof loader>>>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId || !params.conversationId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const conversationData = await queryClient.fetchQuery(conversationQuery(params.companyId, params.conversationId))

		return {
			companyId: params.companyId,
			conversationId: params.conversationId,
			conversationData,
		}
	}

export default function Contact() {
	const { companyId, conversationId, conversationData } = useLoaderData() as ContactInfoLoaderResponse

	return (
		<div className="w-[28rem]">
			<Sidebar
				header={
					<SidebarHeader>
						<h1 className="text-body-md font-semibold text-neutral-1-fg">Contact Info</h1>
						<Link
							className="inline-flex"
							to={routes.enable.copilot.index({
								companyId,
								conversationId,
							})}
						>
							<Icon name="cross-1" />
						</Link>
					</SidebarHeader>
				}
				main={
					<section className="flex flex-col gap-6">
						{Object.entries(conversationData.participant.info)
							.filter(([key, value]) => Boolean(key && value))
							.map(([key, value]) => (
								<div key={key} className="flex flex-col gap-0.5">
									<Label>{CONTACT_INFO_KEY_MAP[key]}</Label>
									{['linkedin', 'website'].includes(key) && value ? (
										<a
											href={value}
											title={CONTACT_INFO_KEY_MAP[key]}
											target="_blank"
											className="text-body-md font-medium text-link hover:text-link-hover active:text-link-pressed"
											rel="noreferrer"
										>
											{value}
										</a>
									) : (
										<p className="text-body-md font-medium text-neutral-1-fg">{value}</p>
									)}
								</div>
							))}
					</section>
				}
			/>
		</div>
	)
}

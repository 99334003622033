import { type z } from 'zod'
import { api } from '#src/utils/endpoints'
import { safeFetch, fetch } from '#src/utils/safeFetch'
import {
	type CriteriaFormSchema,
	CriterionAPISchema,
	DataPointAPISchema,
	type DataPointFormSchema,
	EcosystemAPISchema,
	type EcosystemFormSchema,
	type EcosystemTemplateFormSchema,
	type EditEcosystemEnrichCriteriaPayload,
	type EditEcosystemQualCriteriaPayload,
} from './schema'

export const createEcosystem = async (companyId: string, data: z.infer<typeof EcosystemFormSchema>) => {
	return await safeFetch(EcosystemAPISchema, api.ecosystem.create(companyId), {
		method: 'POST',
		body: JSON.stringify(data),
	})
}

export const createEcosystemFromTemplate = async (
	companyId: string,
	data: z.infer<typeof EcosystemTemplateFormSchema>,
) => {
	return await safeFetch(EcosystemAPISchema, api.ecosystem.templates(companyId), {
		method: 'POST',
		body: JSON.stringify(data),
	})
}

export const updateEcosystem = async (
	companyId: string,
	ecosystemId: number,
	data: z.infer<typeof EcosystemFormSchema>,
) =>
	await safeFetch(EcosystemAPISchema, api.ecosystem.details(companyId, ecosystemId.toString()), {
		method: 'PATCH',
		body: JSON.stringify(data),
	})

export const deleteEcosystem = async (companyId: string, ecosystemId: string) => {
	return await fetch(api.ecosystem.details(companyId, ecosystemId), {
		method: 'DELETE',
	})
}

export const saveCriteria = async (companyId: string, data: z.infer<typeof CriteriaFormSchema>) => {
	return await Promise.all(
		data.criteria.map(criterion => {
			return safeFetch(
				CriterionAPISchema,
				criterion.id ? api.criterion.details(companyId, criterion.id.toString()) : api.criterion.create(companyId),
				{
					method: criterion.id ? 'PATCH' : 'POST',
					body: JSON.stringify(criterion),
				},
			)
		}),
	)
}

export const saveDataPoint = async (companyId: string, data: z.infer<typeof DataPointFormSchema>) => {
	return await Promise.all(
		data.dataPoints.map(datapoint => {
			return safeFetch(
				DataPointAPISchema,
				datapoint.id ? api.datapoint.details(companyId, datapoint.id.toString()) : api.datapoint.create(companyId),
				{
					method: datapoint.id ? 'PATCH' : 'POST',
					body: JSON.stringify(datapoint),
				},
			)
		}),
	)
}

export const deleteCriterion = async (companyId: string, criterionId: string) => {
	return await fetch(api.criterion.details(companyId, criterionId), {
		method: 'DELETE',
	})
}

export const deleteDataPoint = async (companyId: string, dataPointId: string) => {
	return await fetch(api.datapoint.details(companyId, dataPointId), {
		method: 'DELETE',
	})
}

export const editEcosystemQualCriteria = async (
	companyId: string,
	ecosystemId: string,
	data: z.infer<typeof EditEcosystemQualCriteriaPayload>,
) => {
	return await fetch(api.ecosystem.editQualCriteria(companyId, ecosystemId), {
		method: 'PATCH',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(data),
	})
}

export const editEcosystemEnrichCriteria = async (
	companyId: string,
	ecosystemId: string,
	data: z.infer<typeof EditEcosystemEnrichCriteriaPayload>,
) => {
	return await fetch(api.ecosystem.editEnrichCriteria(companyId, ecosystemId), {
		method: 'PATCH',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(data),
	})
}

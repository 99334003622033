import { getFormProps, getInputProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { Form, useActionData, useLoaderData } from 'react-router-dom'
import { ErrorList, Field } from '#src/components/forms'
import { StatusButton } from '#src/components/ui/status-button'
import { Surface } from '#src/components/ui/surface'
import { type AuthLoginActionRes, type AuthLoginLoaderResType } from '#src/routes/auth/routes/login'
import { LoginFormSchema } from '#src/routes/auth/schema'
import { useIsPending } from '#src/utils/misc'

export const View = () => {
	const loaderData = useLoaderData() as AuthLoginLoaderResType
	const actionData = useActionData() as AuthLoginActionRes

	const isPending = useIsPending()
	const [form, fields] = useForm({
		id: 'login-form',
		constraint: getZodConstraint(LoginFormSchema),
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: LoginFormSchema })
		},
		lastResult: actionData?.result ?? null,
		shouldValidate: 'onBlur',
		shouldRevalidate: 'onInput',
	})

	return (
		<main className="grid h-full w-full grid-cols-1 grid-rows-1 items-center justify-items-center">
			<Surface className="flex w-full max-w-sm flex-col items-center gap-8 py-8">
				<h1 className="text-center text-title-md">Login to Evergrowth</h1>
				<Form method="POST" {...getFormProps(form)} className="flex w-full flex-col items-center gap-8">
					<div className="flex w-full flex-col items-center gap-6">
						<Field
							className="w-full"
							labelProps={{ children: 'Username' }}
							inputProps={{
								...getInputProps(fields.username, { type: 'text' }),
								placeholder: 'Enter your username',
								autoFocus: true,
							}}
							errors={fields.username.errors}
						/>
						<Field
							className="w-full"
							labelProps={{ children: 'Password' }}
							inputProps={{
								...getInputProps(fields.password, { type: 'password' }),
								placeholder: 'Enter password',
							}}
							errors={fields.password.errors}
						/>
					</div>

					<ErrorList errors={form.errors || loaderData?.result} id={form.errorId} />

					<StatusButton
						className="w-full max-w-32"
						status={isPending ? 'pending' : (form.status ?? 'idle')}
						type="submit"
						disabled={isPending}
					>
						Login
					</StatusButton>
				</Form>
			</Surface>
		</main>
	)
}

import { type QueryClient } from '@tanstack/react-query'
import { type RouteObject } from 'react-router-dom'
import DefaultSidebarLayout from '#src/layouts/DefaultSidebarLayout'
import leadResearchAgent from '#src/routes/lead-research-agent/router'
import ChatsPanel from '#src/routes/organize/aidt/_layout/panel'
import Aidt, { loader as aidtLoader, type AidtLoaderResponse } from '#src/routes/organize/aidt/views/item'
import { AidtDashboard } from '#src/routes/organize/aidt/views/list'
import DecisionTree, {
	loader as decisionTreeLoader,
	action as decisionTreeAction,
	type DecisionTreeLoaderResponseType,
} from '#src/routes/organize/decision-tree'
import {
	loader as organizeLoader,
	type LoaderResType as OrganizeLoaderResType,
} from '#src/routes/organize/loaders/root'
import { organizeDashboardLoader, OrganizeDashboardView } from '#src/routes/organize/routes/dashboard'
import RouteBoundary from '#src/routes/route-boundary'
import { routes } from '#src/utils/routes'

export default (client: QueryClient) =>
	({
		path: '/organize/:companyId',
		element: <DefaultSidebarLayout />,
		loader: organizeLoader,
		handle: {
			crumb: (data: OrganizeLoaderResType) => {
				return [
					{
						to: routes.organize.index({ companyId: data.handle.companyId }),
						label: 'Organize',
						icon: 'data-unreal',
					},
				]
			},
		},
		children: [
			{
				index: true,
				loader: organizeDashboardLoader(client),
				element: <OrganizeDashboardView />,
				errorElement: <RouteBoundary />,
			},
			{
				path: 'aidt',
				loader: organizeLoader,
				handle: {
					crumb: (data: OrganizeLoaderResType) => [
						{
							to: routes.organize.aidt.index({
								companyId: data.handle.companyId,
								conversationId: null,
							}),
							label: 'Lead Research Agent questions',
							icon: 'machine-learning-model',
						},
					],
				},
				children: [
					{
						index: true,
						loader: aidtLoader(client),
						element: <AidtDashboard />,
						handle: {
							layout: {
								enableHeader: true,
							},
						},
					},
					{
						id: 'aidt-loader',
						path: ':conversationId',
						errorElement: <RouteBoundary />,
						loader: aidtLoader(client),
						element: <Aidt />,
						handle: {
							layout: {
								enableHeader: true,
								noPadding: true,
								sidePanelContent: <ChatsPanel />,
							},
							crumb: (data: AidtLoaderResponse) =>
								data.handle.conversationId && data.handle.conversationData
									? [
											{
												to: routes.organize.aidt.index({
													companyId: data.handle.companyId,
													conversationId: data.handle.conversationId,
												}),
												label: data.handle.conversationData.conversation.name,
												icon: 'chat',
											},
										]
									: [],
						},
					},
				],
			},
			{
				id: 'decision-tree-loader',
				path: 'decision-tree',
				element: <DecisionTree />,
				loader: decisionTreeLoader(client),
				action: decisionTreeAction(client),
				handle: {
					layout: {
						enableHeader: true,
					},
					crumb: (data: DecisionTreeLoaderResponseType) => [
						{
							to: routes.organize.decisionTree.index({
								companyId: data.handle.companyId,
							}),
							label: 'Classification Decision Tree',
							icon: 'decision-tree',
						},
					],
				},
			},
			leadResearchAgent(client),
		],
	}) satisfies RouteObject

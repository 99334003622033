import { type QueryClient } from '@tanstack/react-query'
import { Link, useLoaderData, type LoaderFunctionArgs, useRouteLoaderData } from 'react-router-dom'
import { z } from 'zod'
import { EmptyStateCard } from '#src/components'
import AiGenerated from '#src/components/ai-generated'
import { Icon } from '#src/components/ui/icon'
import { PageLayout } from '#src/components/ui/PageLayout'
import { Surface } from '#src/components/ui/surface'
import { type MainLoaderResponse } from '#src/routes/_layout/main'
import { userQuery } from '#src/routes/auth/queries'
import { DeleteTermAsyncForm } from '#src/routes/enable/glossary/delete'
import { checkIsEnableOnlySession, checkIsReadOnlySession } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import { glossaryQuery } from './queries'
import { GlossaryTermListAPISchema } from './schema'

export type GlossaryLoaderResponse = Awaited<ReturnType<ReturnType<typeof loader>>>

export const GlossaryLoaderResponseSchema = z.object({
	handle: z.object({
		companyId: z.string(),
		readOnlySession: z.boolean(),
		enableOnlySession: z.boolean(),
	}),
	glossary: GlossaryTermListAPISchema,
})

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const user = await queryClient.fetchQuery(userQuery())
		const glossary = await queryClient.fetchQuery(glossaryQuery(params.companyId))

		return {
			handle: {
				companyId: params.companyId,
				readOnlySession: checkIsReadOnlySession(user.roles),
				enableOnlySession: checkIsEnableOnlySession(user.roles),
			},
			glossary,
		}
	}

export default function Glossary() {
	const {
		glossary,
		handle: { companyId },
	} = useLoaderData() as GlossaryLoaderResponse
	const { readOnlySession } = useRouteLoaderData('main-loader') as MainLoaderResponse

	return (
		<PageLayout>
			{!glossary?.length ? (
				<EmptyStateCard
					title="You don't have any Industry-jargons"
					icon="notebook-reference"
					{...(!readOnlySession && {
						actions: [
							{
								title: 'Add new term',
								to: routes.enable.glossary.edit({
									companyId,
									termId: null,
								}),
							},
						],
					})}
				/>
			) : (
				<GlossaryList />
			)}
		</PageLayout>
	)
}

function GlossaryList() {
	const params = useParsedRouteParams(['companyId'])
	const {
		glossary,
		handle: { readOnlySession },
	} = useLoaderData() as GlossaryLoaderResponse

	return (
		<ul className="mx-auto flex max-w-screen-lg flex-col gap-4">
			{glossary.map(term => (
				<Surface as="li" key={term.id} className="flex flex-col gap-6">
					<section className="flex flex-col gap-2">
						<h2 className="flex items-center justify-between text-title-md text-neutral-1-fg">
							{term.term}
							{!readOnlySession ? (
								<div className="flex items-center gap-4 justify-self-end text-neutral-3-fg">
									<Link
										to={routes.enable.glossary.edit({
											companyId: params.companyId,
											termId: term.id.toString(),
										})}
										className="flex items-center justify-center"
									>
										<Icon name="edit" size="font" />
									</Link>
									<DeleteTermAsyncForm termId={term.id} />
								</div>
							) : null}
						</h2>
						<p className="flex items-start gap-2 text-body-sm text-neutral-2-fg">
							<AiGenerated />
							{term.definition}
						</p>
					</section>

					<section className="flex flex-col gap-2">
						<h3 className="text-label-md text-neutral-3-fg">Ecosystem</h3>
						<p className="text-body-sm">{term.ecosystem.name}</p>
					</section>

					{term.manualInput ? (
						<section className="flex flex-col gap-2">
							<h3 className="text-label-md text-neutral-3-fg">Manual input</h3>
							<p className="text-body-sm">{term.manualInput}</p>
						</section>
					) : (
						''
					)}
				</Surface>
			))}
		</ul>
	)
}

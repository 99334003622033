import { generatePath } from 'react-router-dom'
import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { type LeadResearchAgentItemAPISchema } from '#src/routes/lead-research-agent/schema'

const generatePathWithQuery = ({
	path,
	queryArgs,
}: {
	path: string
	queryArgs: string | URLSearchParams | string[][] | Record<string, string>
}) => {
	const query = new URLSearchParams(queryArgs).toString()

	return `${path}?${query}`
}

export const routes = {
	company: {
		index: (params: { companyId: z.infer<typeof CompanySchema>['id'] }) => generatePath('/company/:companyId', params),
		edit: (params: { companyId: z.infer<typeof CompanySchema>['id'] }) =>
			generatePath('/company/:companyId/edit', params),
	},
	enable: {
		index: (params: { companyId: string }) => generatePath('/enable/:companyId', params),
		glossary: {
			index: (params: { companyId: string }) => generatePath('/enable/:companyId/glossary', params),
			save: (params: { companyId: string; termId: string | null }) =>
				generatePath('/enable/:companyId/glossary/save/:termId?', params),
			edit: (params: { companyId: string; termId: string | null }) =>
				generatePath('/enable/:companyId/glossary/edit/:termId?', params),
			delete: (params: { companyId: string; termId: string }) =>
				generatePath('/enable/:companyId/glossary/delete/:termId', params),
		},
		copilot: {
			index: (params: { companyId: string; conversationId: string | null }) =>
				generatePath('/enable/:companyId/copilot/:conversationId?', params),
			init: (params: { contactId: string }) => generatePath('/copilot/:contactId', params),
			new: (params: { companyId: string; conversationId: string | null }) =>
				generatePath('/enable/:companyId/copilot/:conversationId?/new', params),
			restart: (params: { companyId: string; conversationId: string }) =>
				generatePath('/enable/:companyId/copilot/:conversationId/restart', params),
			delete: (params: { companyId: string; conversationId: string }) =>
				generatePath('/enable/:companyId/copilot/:conversationId/delete', params),
			persona: (params: {
				companyId: string
				conversationId: string
				properties: 'pain-points' | 'mapping' | 'co-pilot-recos'
			}) => generatePath('/enable/:companyId/copilot/:conversationId/persona/:properties', params),
			contact: (params: { companyId: string; conversationId: string }) =>
				generatePath('/enable/:companyId/copilot/:conversationId/contact', params),
		},
		roleplay: {
			index: (params: { companyId: string }) => generatePath('/enable/:companyId/roleplay', params),
			new: {
				session: (params: { companyId: string }) => generatePath('/enable/:companyId/roleplay/session/new', params),
				scenario: (params: { companyId: string }) => generatePath('/enable/:companyId/roleplay/scenario/new', params),
			},
			session: {
				index: (params: { companyId: string; conversationId: string }) =>
					generatePath('/enable/:companyId/roleplay/session/:conversationId', params),
				join: (params: { companyId: string; conversationId: string }) =>
					generatePath('/enable/:companyId/roleplay/session/:conversationId/join', params),
				end: (params: { companyId: string; conversationId: string }) =>
					generatePath('/enable/:companyId/roleplay/session/:conversationId/end-session', params),
				restart: (params: { companyId: string; conversationId: string }) =>
					generatePath('/enable/:companyId/roleplay/session/:conversationId/restart', params),
				delete: (params: { companyId: string; conversationId: string }) =>
					generatePath('/enable/:companyId/roleplay/session/:conversationId/delete', params),
				review: (params: { companyId: string; conversationId: string }) =>
					generatePath('/enable/:companyId/roleplay/review/:conversationId', params),
				deleteReviewed: (params: { companyId: string; conversationId: string }) =>
					generatePath('/enable/:companyId/roleplay/review/:conversationId/delete', params),
			},
			scenario: {
				index: (params: { companyId: string; scenarioId: string }) =>
					generatePath('/enable/:companyId/roleplay/scenario/:scenarioId', params),
				edit: (params: { companyId: string; scenarioId: string }) =>
					generatePath('/enable/:companyId/roleplay/scenario/:scenarioId/edit', params),
				invite: (params: { companyId: string; scenarioId: string }) =>
					generatePath('/enable/:companyId/roleplay/scenario/:scenarioId/invite', params),
				newTestSession: (params: { companyId: string; scenarioId: string }) =>
					generatePath('/enable/:companyId/roleplay/scenario/:scenarioId/new-test-session', params),
			},
		},
		personas: {
			index: (params: { companyId: string; verticalId: string }) =>
				generatePath('/enable/:companyId/personas/:verticalId', params),
			details: (params: { companyId: string; verticalId: string; personaId: string }) =>
				generatePath('/enable/:companyId/personas/:verticalId/:personaId', params),
		},
	},
	calibrate: {
		index: (params: { companyId: string }) => generatePath('/calibrate/:companyId', params),
		ecosystem: {
			create: (params: { companyId: string }) => generatePath('/calibrate/:companyId/ecosystem', params),
			delete: (params: { companyId: string; ecosystemId: string }) =>
				generatePath('/calibrate/:companyId/ecosystem/:ecosystemId/delete', params),
			edit: (params: { companyId: string; ecosystemId: string }) =>
				generatePath('/calibrate/:companyId/ecosystem/:ecosystemId', params),
			save: (params: { companyId: string; ecosystemId: string | null }) =>
				generatePath('/calibrate/:companyId/ecosystem/:ecosystemId?/save', params),
			sort: (params: { companyId: string }) => generatePath('/calibrate/:companyId/ecosystem/sort', params),
		},
		verticals: {
			index: (params: { companyId: string; ecosystemId: string }) =>
				generatePath('/calibrate/:companyId/verticals/:ecosystemId', params),
			create: (params: { companyId: string; ecosystemId: string }) =>
				generatePath('/calibrate/:companyId/verticals/:ecosystemId/edit', params),
			edit: (params: { companyId: string; ecosystemId: string; verticalId: string }) =>
				generatePath('/calibrate/:companyId/verticals/:ecosystemId/edit/:verticalId', params),
			duplicate: (params: {
				companyId: string
				ecosystemId: string
				verticalId: string
				autoFocus?: string | null
			}) => {
				return generatePathWithQuery({
					path: generatePath('/calibrate/:companyId/verticals/:ecosystemId/edit', params),
					queryArgs: {
						duplicate: params.verticalId,
						...(params.autoFocus && { autofocus: params.autoFocus }),
					},
				})
			},
			copyPersonas: (params: { companyId: string; ecosystemId: string }) =>
				generatePath('/calibrate/:companyId/verticals/:ecosystemId/persona/copy', params),
			copyValidatePersonas: (params: { companyId: string; ecosystemId: string }) =>
				generatePath('/calibrate/:companyId/verticals/:ecosystemId/persona/copy-validate', params),
			batchDeletePersonas: (params: { companyId: string; ecosystemId: string }) =>
				generatePath('/calibrate/:companyId/verticals/:ecosystemId/persona/batch-delete', params),
			update: (params: { companyId: string; ecosystemId: string; verticalId: string }) =>
				generatePath('/calibrate/:companyId/verticals/:ecosystemId/save/:verticalId', params),
			save: (params: { companyId: string; ecosystemId: string }) =>
				generatePath('/calibrate/:companyId/verticals/:ecosystemId/save', params),
			delete: (params: { companyId: string; ecosystemId: string; verticalId: string }) =>
				generatePath('/calibrate/:companyId/verticals/:ecosystemId/delete/:verticalId', params),
		},
		criteria: {
			create: (params: { companyId: string; ecosystemId: string }) =>
				generatePath('/calibrate/:companyId/verticals/:ecosystemId/criteria', params),
			edit: (params: { companyId: string; ecosystemId: string }) =>
				generatePath('/calibrate/:companyId/verticals/:ecosystemId/criteria', params),
		},
		datapoints: {
			create: (params: { companyId: string; ecosystemId: string }) =>
				generatePath('/calibrate/:companyId/verticals/:ecosystemId/datapoints', params),
			edit: (params: { companyId: string; ecosystemId: string }) =>
				generatePath('/calibrate/:companyId/verticals/:ecosystemId/datapoints', params),
		},
		persona: {
			index: (params: { companyId: string; ecosystemId: string; verticalId: string; personaId: string | null }) =>
				generatePath('/calibrate/:companyId/personas/:ecosystemId/:verticalId/:personaId?', params),
			save: (params: { companyId: string; ecosystemId: string; verticalId: string; personaId: string | null }) =>
				generatePath('/calibrate/:companyId/personas/:ecosystemId/:verticalId/:personaId?/save', params),
			edit: (params: { companyId: string; ecosystemId: string; verticalId: string; personaId: string | null }) =>
				generatePath('/calibrate/:companyId/personas/:ecosystemId/:verticalId/:personaId?/edit', params),
			delete: (params: { companyId: string; ecosystemId: string; verticalId: string; personaId: string }) =>
				generatePath('/calibrate/:companyId/personas/:ecosystemId/:verticalId/:personaId/delete', params),
			setup: (params: { companyId: string; ecosystemId: string; verticalId: string; personaId: string }) =>
				generatePath('/calibrate/:companyId/personas/:ecosystemId/:verticalId/:personaId/setup', params),
			tune: (params: { companyId: string; ecosystemId: string; verticalId: string; personaId: string }) =>
				generatePath('/calibrate/:companyId/personas/:ecosystemId/:verticalId/:personaId/tune', params),
			recalculate: (params: { companyId: string; ecosystemId: string; verticalId: string; personaId: string | null }) =>
				generatePath('/calibrate/:companyId/personas/:ecosystemId/:verticalId/:personaId?/recalculate', params),
			duplicate: (params: {
				companyId: string
				ecosystemId: string
				verticalId: string
				personaId: string | null
				duplicate: string
			}) =>
				generatePathWithQuery({
					path: generatePath('/calibrate/:companyId/personas/:ecosystemId/:verticalId/:personaId?/edit', params),
					queryArgs: {
						duplicate: params.duplicate,
					},
				}),
			coach: {
				index: (params: { companyId: string; ecosystemId: string; verticalId: string; personaId: string }) =>
					generatePath('/calibrate/:companyId/personas/:ecosystemId/:verticalId/:personaId/coach', params),
				recalculate: (params: {
					companyId: string
					ecosystemId: string
					verticalId: string
					personaId: string
					salesCoachId: string
				}) =>
					generatePath(
						'/calibrate/:companyId/personas/:ecosystemId/:verticalId/:personaId/coach/recalculate/:salesCoachId',
						params,
					),
			},
		},
	},
	prioritize: {
		index: (params: { companyId: string }) => generatePath('/prioritize/:companyId', params),
		filters: (params: { companyId: string }) => generatePath('/prioritize/:companyId/filters', params),
		signal: {
			index: (params: { companyId: string; signalId: string }) =>
				generatePath('/prioritize/:companyId/details/:signalId', params),
			assign: (params: { companyId: string; signalId: string }) =>
				generatePath('/prioritize/:companyId/details/:signalId/assign', params),
			save: (params: { companyId: string; signalId: string }) =>
				generatePath('/prioritize/:companyId/details/:signalId/save', params),
			example: (params: { companyId: string; signalId: string; personaId: string }) =>
				generatePath('/prioritize/:companyId/details/:signalId/example/:personaId', params),
		},
	},
	organize: {
		index: (params: { companyId: string }) => generatePath('/organize/:companyId', params),
		aidt: {
			index: (params: { companyId: string; conversationId: string | null }) =>
				generatePath('/organize/:companyId/aidt/:conversationId?', params),
		},
		decisionTree: {
			index: (params: { companyId: string }) => generatePath('/organize/:companyId/decision-tree', params),
			create: (params: { companyId: string }) => generatePath('/organize/:companyId/decision-tree', params),
		},
	},
	settings: {
		index: (params: { companyId: string }) => generatePath('/settings/:companyId', params),
		team: (params: { companyId: string }) => generatePath('/settings/:companyId/team', params),
		invite: (params: { companyId: string }) => generatePath('/settings/:companyId/team/invite', params),
	},
	admin: {
		index: '/admin',
		company: {
			create: '/admin/company/new',
			edit: (params: { companyId: string }) => generatePath('/admin/company/:companyId/edit', params),
			delete: (params: { companyId: string }) => generatePath('/admin/company/:companyId/delete', params),
		},
	},
	developers: {
		playground: () => generatePath('/developers/playground'),
	},
	leadResearchAgent: {
		index: (params: { companyId: z.infer<typeof CompanySchema>['id'] }) =>
			generatePath('/organize/:companyId/lra', params),
		create: (params: { companyId: z.infer<typeof CompanySchema>['id'] }) =>
			generatePath('/organize/:companyId/lra/new', params),
		view: (params: {
			companyId: z.infer<typeof CompanySchema>['id']
			jobId: z.infer<typeof LeadResearchAgentItemAPISchema>['id']
		}) => generatePath('/organize/:companyId/lra/:jobId', params),
		stop: (params: {
			companyId: z.infer<typeof CompanySchema>['id']
			jobId: z.infer<typeof LeadResearchAgentItemAPISchema>['id']
		}) => generatePath('/organize/:companyId/lra/:jobId/stop', params),
		download: (params: {
			companyId: z.infer<typeof CompanySchema>['id']
			jobId: z.infer<typeof LeadResearchAgentItemAPISchema>['id']
		}) => generatePath('/organize/:companyId/lra/:jobId/download', params),
		downloadTemplate: (params: { companyId: z.infer<typeof CompanySchema>['id'] }) =>
			generatePath('/organize/:companyId/lra/download-template', params),
	},
}

import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { routes } from '#src/utils/routes'

export const joinRoleplayChatAction =
	() =>
	({ params }: ActionFunctionArgs) => {
		if (!params.companyId || !params.conversationId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		return redirect(
			routes.enable.roleplay.session.index({
				companyId: params.companyId,
				conversationId: params.conversationId,
			}),
		)
	}

import * as ScrollArea from '@radix-ui/react-scroll-area'
import { type LabelHTMLAttributes, useMemo, useState } from 'react'
import { ErrorList, type ListOfErrors } from '#src/components/forms'
import CheckboxTreeInput, { type TreeViewItem } from '#src/components/forms/CheckboxTreeInput'
import { Button } from '#src/components/ui/button'
import { Checkbox } from '#src/components/ui/checkbox'
import { Icon } from '#src/components/ui/icon'
import { Input } from '#src/components/ui/input'
import { Label } from '#src/components/ui/label'
import { cn } from '#src/utils/misc'

type Props = {
	labelProps?: LabelHTMLAttributes<HTMLLabelElement>
	searchPlaceholder?: string
	hideSearch?: boolean
	options: TreeViewItem[]
	errors?: ListOfErrors
	className?: string
	inputProps: {
		name: string
		defaultValue: string[] | undefined
		onChange: (data: string[]) => void
	}
}

export const CheckboxTreeViewWithSearch = (props: Props) => {
	const { labelProps, inputProps, searchPlaceholder, hideSearch, options, errors, className } = props
	const [search, setSearch] = useState<string>('')

	const filteredOptions = useMemo(() => {
		if (!search) {
			return options
		}

		// search works only up to 2 node levels
		return options
			.map(item => ({
				...item,
				children: item.children?.filter(
					child =>
						child.label.toLowerCase().includes(search.toLowerCase()) ||
						item.label.toLowerCase().includes(search.toLowerCase()),
				),
			}))
			.filter(item => item.children?.length)
	}, [options, search])

	const [selected, setSelected] = useState<string[] | undefined>(inputProps.defaultValue)

	const flattenOptions = useMemo(() => {
		const recursiveOptionParse = (data: TreeViewItem): TreeViewItem[] => {
			if (data.children) {
				return data.children.flatMap(recursiveOptionParse)
			} else {
				return [data]
			}
		}

		return options.map(recursiveOptionParse).flat()
	}, [options])

	const onValueChange = (value: string[]) => {
		setSelected(value)
		inputProps.onChange(value)
	}

	const onSelectAll = () => {
		onValueChange(flattenOptions.map(item => item.id))
	}

	const onDeselectAll = () => {
		onValueChange([])
	}

	return (
		<div className={cn('flex flex-col gap-1', className)}>
			{labelProps && <Label {...labelProps} />}
			<div className="flex flex-col gap-2">
				{!hideSearch && (
					<Input
						value={search}
						onChange={e => setSearch(e.target.value)}
						clearable
						placeholder={searchPlaceholder}
						iconRight="search"
					/>
				)}
				{!search && (
					<div className="flex flex-row gap-2">
						<Button type="button" onClick={onSelectAll} size="sm" variant="ghost">
							Select all
						</Button>
						<Button type="button" onClick={onDeselectAll} size="sm" variant="ghost">
							Deselect all
						</Button>
					</div>
				)}
				<ScrollArea.Root>
					<ScrollArea.Viewport
						className={cn(
							'max-h-[400px] min-h-[238px] overflow-hidden pe-3',
							options.length && !filteredOptions.length && 'flex items-center justify-center',
						)}
					>
						<div className="hidden bg-red-10">
							{flattenOptions.map(option => (
								<div key={`real-option-${option.id}`}>
									<Checkbox
										{...inputProps}
										name={inputProps.name}
										value={option.id}
										defaultChecked={inputProps.defaultValue?.includes(option.id)}
										checked={selected?.includes(option.id)}
										onChange={() => {}}
									/>
									<span className="ml-2">{option.label}</span>
								</div>
							))}
						</div>
						<CheckboxTreeInput value={selected ?? []} options={filteredOptions} onChange={onValueChange} closed />
						{options.length && !filteredOptions.length && (
							<section className="m-auto h-full gap-1 text-center">
								<Icon name="search-dialogue" className="mb-2 h-14 w-14 text-brand-1-fg" />
								<h3 className="text-center text-body-lg font-semibold text-neutral-2-fg">No search results</h3>
								<div className="mx-auto max-w-[300px]">
									<p className="truncate text-body-md">{`No results for “${search}”`}</p>
								</div>
							</section>
						)}
					</ScrollArea.Viewport>
					<ScrollArea.Scrollbar orientation="vertical" className="w-1.5">
						<ScrollArea.Thumb className="rounded-full bg-neutral-2-bd" />
					</ScrollArea.Scrollbar>
					<ScrollArea.Corner />
				</ScrollArea.Root>
			</div>

			{errors?.length ? (
				<div className="min-h-[32px] pb-3">
					<ErrorList errors={errors} />
				</div>
			) : null}
		</div>
	)
}

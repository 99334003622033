import { type QueryClient } from '@tanstack/react-query'
import { type RouteObject } from 'react-router-dom'
import DefaultSidebarLayout from '#src/layouts/DefaultSidebarLayout'
import { loader as calibrateModuleLoader } from '#src/routes/calibrate/_layout'
import CalibratePanel from '#src/routes/calibrate/_layout/panel'
import CompanyDetails, { loader as companyDetailsLoader } from '#src/routes/calibrate/company/details'
import Ecosystem, { loader as ecosystemLoader } from '#src/routes/calibrate/ecosystem'
import { action as deleteEcosystemAction } from '#src/routes/calibrate/ecosystem/delete'
import { action as saveEcosystemAction } from '#src/routes/calibrate/ecosystem/save'
import { action as reorderEcosystemsAction } from '#src/routes/calibrate/ecosystem/sort'
import Personas, {
	PersonaLoaderResponseSchema,
	loader as personasLoader,
	type PersonaLoaderResponse,
} from '#src/routes/calibrate/personas'
import { deletePersonasFormAction } from '#src/routes/calibrate/personas/batch-delete'
import SalesCoach, {
	loader as salesCoachLoader,
	action as salesCoachAction,
} from '#src/routes/calibrate/personas/coach'
import { action as recalculateSalesCoachAction } from '#src/routes/calibrate/personas/coach/recalculate'
import { action as deletePersonaAction } from '#src/routes/calibrate/personas/delete'
import PersonaEdit, { loader as personaEditLoader } from '#src/routes/calibrate/personas/edit'
import { action as recalculatePersonasAction } from '#src/routes/calibrate/personas/recalculate'
import { action as savePersonaAction } from '#src/routes/calibrate/personas/save'
import PersonaSetup, { loader as personaSetupLoader } from '#src/routes/calibrate/personas/setup'
import PersonaTune, { loader as personaTuneLoader } from '#src/routes/calibrate/personas/tune'
import Vertical, {
	loader as verticalsLoader,
	VerticalsLoaderResponseSchema,
	type VerticalLoaderResponse,
} from '#src/routes/calibrate/verticals'
import { copyValidatePersonasAction } from '#src/routes/calibrate/verticals/actions/copyValidatePersonasAction'
import {
	copyPersonasAction,
	copyPersonasLoader,
	CopyPersonasModal,
} from '#src/routes/calibrate/verticals/copy-personas'
import VerticalsCriteriaEdit, {
	loader as verticalsCriteriaLoader,
	action as verticalsCriteriaAction,
} from '#src/routes/calibrate/verticals/criteria'
import { action as deleteVerticalAction } from '#src/routes/calibrate/verticals/delete'
import VerticalEdit, { loader as verticalsEditLoader } from '#src/routes/calibrate/verticals/edit'
import { action as saveVerticalAction } from '#src/routes/calibrate/verticals/save'
import RouteBoundary from '#src/routes/route-boundary'
import { routes } from '#src/utils/routes'
import { autofocusVerticalMutation } from '../calibrate/verticals/autofocus'
import VerticalsDataPointsEdit, {
	loader as verticalsDataPointsLoader,
	action as verticalsDataPointsAction,
} from '../calibrate/verticals/datapoints'

export default (client: QueryClient) =>
	({
		path: '/calibrate/:companyId',
		children: [
			{
				path: '',
				element: <DefaultSidebarLayout />,
				children: [
					{
						path: '',
						element: <CompanyDetails />,
						errorElement: <RouteBoundary />,
						loader: companyDetailsLoader(client),
						children: [
							{
								path: 'ecosystem/:ecosystemId?',
								id: 'ecosystem',
								element: <Ecosystem />,
								loader: ecosystemLoader(client),
								children: [
									{
										path: 'save',
										action: saveEcosystemAction(client),
									},
									{
										path: 'delete',
										action: deleteEcosystemAction(client),
									},
									{
										path: 'sort',
										action: reorderEcosystemsAction(client),
									},
								],
							},
						],
					},
				],
			},
			{
				id: 'calibrate-module',
				path: '',
				loader: calibrateModuleLoader(client),
				element: <DefaultSidebarLayout enableHeader sidePanelContent={<CalibratePanel />} noPadding fullWidth />,
				children: [
					{
						path: 'verticals/:ecosystemId',
						element: <Vertical />,
						errorElement: <RouteBoundary />,
						loader: verticalsLoader(client),
						handle: {
							crumb: (data: VerticalLoaderResponse) => {
								const parsedRouteHandleData = VerticalsLoaderResponseSchema.safeParse(data)
								if (!parsedRouteHandleData.success) return
								const {
									data: { handle },
								} = parsedRouteHandleData
								return [
									{
										to: routes.calibrate.index({ companyId: handle.companyId }),
										label: 'Calibrate',
										icon: 'data-unstructured',
									},
									{
										to: routes.calibrate.verticals.index({
											companyId: handle.companyId,
											ecosystemId: handle.ecosystemId!,
										}),
										label: handle.ecosystemName || 'Ecosystem',
										icon: 'ibm-cloud-pak',
									},
								]
							},
							action: (data: VerticalLoaderResponse) => {
								const parsedRouteHandleData = VerticalsLoaderResponseSchema.safeParse(data)
								if (!parsedRouteHandleData.success) return
								const {
									data: { handle },
								} = parsedRouteHandleData
								if (handle.readOnlySession) return []
								return [
									{
										to: routes.calibrate.criteria.edit({
											companyId: handle.companyId,
											ecosystemId: handle.ecosystemId!,
										}),
										label: 'Edit criteria',
										icon: 'edit',
									},
									{
										to: routes.calibrate.verticals.create({
											companyId: handle.companyId,
											ecosystemId: handle.ecosystemId!,
										}),
										label: 'Add Vertical',
										icon: 'category-new',
									},
								]
							},
						},
						children: [
							{
								path: 'save/:verticalId?',
								action: saveVerticalAction(client),
							},
							{
								path: 'delete/:verticalId',
								action: deleteVerticalAction(client),
							},
							{
								path: 'criteria',
								element: <VerticalsCriteriaEdit />,
								loader: verticalsCriteriaLoader(client),
								action: verticalsCriteriaAction(client),
							},
							{
								path: 'datapoints',
								element: <VerticalsDataPointsEdit />,
								loader: verticalsDataPointsLoader(client),
								action: verticalsDataPointsAction(client),
							},
							{
								path: 'edit/:verticalId?',
								element: <VerticalEdit />,
								loader: verticalsEditLoader(client),
							},
							{
								path: 'persona',
								children: [
									{
										path: 'batch-delete',
										action: deletePersonasFormAction(client),
									},
									{
										path: 'copy-validate',
										action: copyValidatePersonasAction(client),
									},
									{
										element: <CopyPersonasModal />,
										path: 'copy',
										action: copyPersonasAction(client),
										loader: copyPersonasLoader(client),
									},
								],
							},
						],
					},
					{
						id: 'debug',
						path: 'personas/:ecosystemId/:verticalId/:personaId?',
						element: <Personas />,
						errorElement: <RouteBoundary />,
						loader: personasLoader(client),
						handle: {
							crumb: (data: PersonaLoaderResponse) => {
								const parsedRouteHandleData = PersonaLoaderResponseSchema.safeParse(data)
								if (!parsedRouteHandleData.success) return []
								const {
									data: { handle },
								} = parsedRouteHandleData
								return [
									{
										to: routes.calibrate.index({ companyId: handle.companyId }),
										label: 'Calibrate',
										icon: 'data-unstructured',
									},
									{
										to: routes.calibrate.verticals.index({
											companyId: handle.companyId,
											ecosystemId: handle.ecosystemId,
										}),
										label: handle.ecosystemName || 'Ecosystem',
										icon: 'ibm-cloud-pak',
									},
									{
										to: routes.calibrate.verticals.index({
											companyId: handle.companyId,
											ecosystemId: handle.ecosystemId,
										}),
										label: handle.verticalName,
										icon: 'category',
										onClick: () => autofocusVerticalMutation(handle.verticalId),
									},
									...(handle.personaId
										? [
												{
													to: routes.calibrate.persona.index({
														companyId: handle.companyId,
														ecosystemId: handle.ecosystemId,
														verticalId: handle.verticalId,
														personaId: handle.personaId,
													}),
													label: data.persona?.type ?? 'Persona',
													icon: 'user',
												},
											]
										: []),
								]
							},
							action: (data: PersonaLoaderResponse) => {
								const parsedRouteHandleData = PersonaLoaderResponseSchema.safeParse(data)
								if (!parsedRouteHandleData.success) return
								const {
									data: { handle },
								} = parsedRouteHandleData
								if (handle.readOnlySession) return []
								return [
									{
										to: routes.calibrate.persona.edit({
											companyId: handle.companyId,
											ecosystemId: handle.ecosystemId,
											verticalId: handle.verticalId,
											personaId: handle.personaId ?? null,
										}),
										label: 'Add persona',
										icon: 'user-follow',
									},
								]
							},
						},
						children: [
							{
								path: ':personaId?',
								children: [
									{
										path: 'edit',
										element: <PersonaEdit />,
										loader: personaEditLoader(client),
									},
									{
										path: 'save',
										action: savePersonaAction(client),
									},
									{
										path: 'delete',
										action: deletePersonaAction(client),
									},
									{
										path: 'setup',
										element: <PersonaSetup />,
										loader: personaSetupLoader(client),
									},
									{
										path: 'tune',
										element: <PersonaTune />,
										loader: personaTuneLoader(client),
									},
									{
										path: 'recalculate',
										action: recalculatePersonasAction(client),
									},
									{
										path: 'coach',
										element: <SalesCoach />,
										loader: salesCoachLoader(client),
										action: salesCoachAction(client),
										children: [
											{
												path: 'recalculate/:salescoachId',
												action: recalculateSalesCoachAction(client),
											},
										],
									},
								],
							},
						],
					},
				],
			},
		],
	}) satisfies RouteObject

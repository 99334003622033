import { type QueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import { Link, useLoaderData, type LoaderFunctionArgs } from 'react-router-dom'
import { DashboardCard, DashboardCardCount } from '#src/components/dashboard-card'
import ProductTip from '#src/components/product-tip'
import { PageLayout } from '#src/components/ui/PageLayout'
import { EMPTY_PAGINATED_RESPONSE } from '#src/constants/responses'
import { usePersona } from '#src/hooks/usePersona'
import { userQuery } from '#src/routes/auth/queries'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem/queries'
import { checkIsAdminSession, checkIsUserSession } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import { type IconName } from '@/icon-name'
import { chatsQuery } from '../chat/queries'
import { glossaryQuery } from '../glossary/queries'
import { roleplayScenariosQuery } from '../roleplay/scenario/queries'
import { roleplaySessionsQuery } from '../roleplay/session/queries'

type EnableDashboardLoaderResponse = Awaited<ReturnType<ReturnType<typeof loader>>>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const user = await queryClient.fetchQuery(userQuery())
		const isAdminOrUser = checkIsAdminSession(user.roles) || checkIsUserSession(user.roles)

		return {
			chats: await queryClient.fetchQuery(chatsQuery(params.companyId)),
			sessions: await queryClient.fetchQuery(roleplaySessionsQuery(params.companyId)),
			scenarios: isAdminOrUser
				? await queryClient.fetchQuery(roleplayScenariosQuery(params.companyId))
				: EMPTY_PAGINATED_RESPONSE,
			glossary: await queryClient.fetchQuery(glossaryQuery(params.companyId)),
			isAdminOrUser,
			ecosystems: await queryClient.fetchQuery(ecosystemsQuery(params.companyId)),
		}
	}

export default function EnableDashboard() {
	const params = useParsedRouteParams(['companyId'])
	const { chats, sessions, scenarios, glossary, isAdminOrUser, ecosystems } =
		useLoaderData() as EnableDashboardLoaderResponse

	const { getPersonasCountPerCompany } = usePersona()
	const firstVerticalId = ecosystems[0]?.verticals[0]?.id

	const personasCount = useMemo(() => getPersonasCountPerCompany(), [getPersonasCountPerCompany])

	const cardData = [
		{
			link: routes.enable.copilot.index({
				companyId: params.companyId,
				conversationId: null,
			}),
			icon: 'chat',
			title: (
				<div className="flex items-center gap-1">
					Co-Pilot chats
					<ProductTip
						className="text-neutral-3-fg"
						content="Co-pilot Chat: Engage in conversations with simulated versions of your prospects, generated from real data, and receive live coaching on best outreach practices."
					/>
				</div>
			),
			count: chats.length ?? 0,
			countSuffix: chats.length !== 1 ? 'Chats' : 'Chat',
			condition: true,
		},
		{
			link: routes.enable.roleplay.index({ companyId: params.companyId }),
			icon: 'voice-activate',
			title: (
				<div className="flex items-center gap-1">
					Roleplay training
					<ProductTip
						className="text-neutral-3-fg"
						content="Roleplay training: Practice sales conversations with AI-generated responses, and receive feedback on your performance to improve your sales skills."
					/>
				</div>
			),
			countComponent: (
				<div className="flex gap-4">
					{isAdminOrUser && (
						<DashboardCardCount
							count={scenarios.meta.total}
							countSuffix={scenarios.meta.total !== 1 ? 'Scenarios' : 'Scenario'}
						/>
					)}
					<DashboardCardCount
						count={sessions.meta.total}
						countSuffix={sessions.meta.total !== 1 ? 'Sessions' : 'Session'}
					/>
				</div>
			),
			condition: true,
		},
		{
			link: routes.enable.glossary.index({ companyId: params.companyId }),
			icon: 'notebook-reference',
			title: (
				<div className="flex items-center gap-1">
					Industry jargons
					<ProductTip
						className="text-neutral-3-fg"
						content="Industry Jargon: Specialized terms and phrases commonly used within a specific industry or field, enriched with AI. Synchronized with an ecosystem of your choice to enhance relevance and contextual accuracy."
					/>
				</div>
			),
			count: glossary.length ?? 0,
			countSuffix: glossary.length !== 1 ? 'Terms' : 'Term',
			condition: true,
		},
		...(firstVerticalId && (import.meta.env.DEV || import.meta.env.VITE_FEATURE_PERSONA_CARDS === 'true')
			? [
					{
						link: routes.enable.personas.index({
							companyId: params.companyId,
							verticalId: String(firstVerticalId),
						}),
						icon: 'user-multiple',
						title: (
							<div className="flex items-center gap-1">
								Personas
								<ProductTip
									className="text-neutral-3-fg"
									content="Personas: AI-powered personas that help enrich the sales process by understanding customer preferences and behaviors."
								/>
							</div>
						),
						count: personasCount,
						countSuffix: personasCount !== 1 ? 'Personas' : 'Persona',
						condition: true,
					},
				]
			: []),
	]

	return (
		<PageLayout
			title="Enable"
			description="Equip your team with AI-powered insights to personalize interactions and enhance sales engagement, delivering a customer-centric approach that prioritizes the unique needs and preferences of each prospect."
		>
			<div className="grid w-full grid-cols-3 grid-rows-1 gap-6">
				{cardData.map(
					({ link, icon, title, count, countSuffix, condition, countComponent }) =>
						condition && (
							<Link key={link} to={link} className="block">
								<DashboardCard icon={icon as IconName} title={title} isHoverable>
									{countComponent ? countComponent : <DashboardCardCount count={count} countSuffix={countSuffix} />}
								</DashboardCard>
							</Link>
						),
				)}
			</div>
		</PageLayout>
	)
}

import { clsx } from 'clsx'
import {
	type DetailedHTMLProps,
	type InputHTMLAttributes,
	type LabelHTMLAttributes,
	useEffect,
	useId,
	useRef,
	useState,
} from 'react'
import { type Accept, type FileWithPath, useDropzone } from 'react-dropzone'
import { ErrorList, type ListOfErrors } from '#src/components/forms/index'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { Label } from '#src/components/ui/label'
import { cn } from '#src/utils/misc'

type Props = {
	maxFiles?: number
	accept?: Accept
	labelProps?: LabelHTMLAttributes<HTMLLabelElement>
	inputProps: Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'type' | 'className'>
	errors?: ListOfErrors
	className?: string
	onValueChange?: (e: FileWithPath[]) => void
}

export const FileInput = (props: Props) => {
	const { className, inputProps, labelProps, errors, maxFiles, accept, onValueChange } = props
	const multiple = !!(maxFiles && maxFiles > 1)
	const inputRef = useRef<HTMLInputElement>(null)
	const fallbackId = useId()
	const id = inputProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	const [files, setFiles] = useState<FileWithPath[]>([])
	const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
		maxFiles: maxFiles ?? 1,
		accept,
		multiple,
		onDropAccepted: files => {
			setFiles(files)
		},
	})

	const onDeleteFile = (file: File) => {
		const newFiles = [...files]
		newFiles.splice(newFiles.indexOf(file), 1)
		setFiles(newFiles)
	}

	useEffect(() => {
		if (!inputRef.current) {
			return
		}
		const dataTransfer = new DataTransfer()
		files.forEach(file => {
			dataTransfer.items.add(file)
		})
		inputRef.current.files = dataTransfer.files
		inputRef.current.blur()
		onValueChange?.(files)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [files])

	return (
		<div className={cn('flex flex-col gap-1', className)}>
			{labelProps && <Label htmlFor={id} {...labelProps} />}
			<div className="flex min-h-[200px]">
				<div
					{...getRootProps()}
					className={clsx(
						'flex w-full flex-1 flex-col gap-1 rounded border p-6',
						isDragActive ? 'border-brand-1-bd-hover' : 'border-neutral-2-bd',
						files.length ? 'hidden' : 'items-center justify-center border-dashed bg-neutral-2-bg',
					)}
				>
					<input
						id={id}
						type="file"
						className="hidden"
						{...inputProps}
						multiple={multiple}
						ref={inputRef}
						aria-invalid={errorId ? true : undefined}
						aria-describedby={errorId}
					/>
					<input {...getInputProps()} />
					<div className="flex flex-row items-center justify-center gap-2">
						<Icon name="upload" className="block h-4 w-4" />
						<p className="text-body-md">Drag your file here</p>
					</div>
					<Button
						variant="ghost"
						size="sm"
						type="button"
						onClick={e => {
							e.preventDefault()
							e.stopPropagation()
							open()
						}}
					>
						Upload from computer
					</Button>
				</div>
				{!!files.length && (
					<div className="flex w-full flex-col gap-2">
						{files.map(file => (
							<div
								onClick={e => e.stopPropagation()}
								key={`file-${file.path}`}
								className="flex w-full items-center gap-1 rounded border border-neutral-2-bd p-4"
							>
								<Icon name="document" size="md" className="block" />
								<p className="flex-1 truncate text-body-md">{file.name}</p>
								<Button
									variant="icon"
									size="sm"
									type="button"
									onClick={e => {
										e.preventDefault()
										e.stopPropagation()
										onDeleteFile(file)
									}}
								>
									<Icon name="trash" className="block text-status-danger-fg" />
								</Button>
							</div>
						))}
					</div>
				)}
			</div>
			{errorId ? (
				<div className="min-h-[32px] pb-3">
					<ErrorList id={errorId} errors={errors} />
				</div>
			) : null}
		</div>
	)
}

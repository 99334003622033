// global.d.ts
import { z } from 'zod'

export const MetaSchema = z.object({
	current_page: z.number(),
	from: z.number().nullable(),
	last_page: z.number(),
	per_page: z.number(),
	to: z.number().nullable(),
	total: z.number(),
	path: z.string(),
	links: z
		.array(
			z.object({
				url: z.string().nullable(),
				label: z.string(),
				active: z.boolean(),
			}),
		)
		.optional(),
})

export const LinksSchema = z.object({
	first: z.string().nullable(),
	last: z.string().nullable(),
	next: z.string().nullable(),
	prev: z.string().nullable(),
})

export const PaginatedAPIResSchema = <T extends z.ZodTypeAny>(dataSchema: T) => {
	return z.object({
		data: z.array(dataSchema),
		meta: MetaSchema,
		links: LinksSchema.optional(),
	})
}

import { useEffect, useState } from 'react'
import { useLoaderData } from 'react-router-dom'
import { EmptyStateCard } from '#src/components'
import { useEcosystem } from '#src/hooks/useEcosystem'
import { usePersonasQuery } from '#src/routes/enable/personas/queries'
import { PersonasCards, PersonasFilters } from '#src/routes/enable/personas/views/components/index'
import usePaginator from '#src/utils/usePaginator'
import { type PersonasIndexLoaderResType } from '../routes/index'

export type FiltersState = {
	type?: string
	status?: string
	priority?: string
	expertise?: string
}

const filtersIntlVal: FiltersState = {
	type: '',
	status: '',
	priority: '',
	expertise: '',
}

export const Index = () => {
	const [filters, setFilters] = useState<FiltersState>(filtersIntlVal)
	const { personas, companyId, verticalId } = useLoaderData() as PersonasIndexLoaderResType
	const { findEcosystemByVerticalId } = useEcosystem()
	const { currentPage, setCurrentPage } = usePaginator({
		totalItems: personas.meta.total,
	})
	const { personasData, personasLoading } = usePersonasQuery(companyId, {
		page: currentPage,
		ecosystem_id: String(findEcosystemByVerticalId(verticalId)?.id),
		vertical_id: verticalId,
		status: filters.status,
		priority: filters.priority,
		type: filters.type,
		expertise: filters.expertise,
	})

	useEffect(() => {
		setFilters(filtersIntlVal)
	}, [verticalId])

	useEffect(() => {
		setCurrentPage(0)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters])

	return (
		<div className="flex w-full flex-col gap-7">
			<PersonasFilters filters={filters} setFilters={setFilters} />
			{!!personasData?.data.length && (
				<PersonasCards
					personas={personasData}
					isLoading={personasLoading}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
				/>
			)}
			{personasData && !personasData.data.length && (
				<>
					<EmptyStateCard title="No personas found" icon="user-multiple" />
				</>
			)}
		</div>
	)
}

import { type QueryClient } from '@tanstack/react-query'
import { type LoaderFunctionArgs, redirect } from 'react-router-dom'
import { z } from 'zod'
import { showToast } from '#src/context/ToastContext'
import {
	LeadResearchAgentListItemAPISchema,
	LeadResearchAgentStatisticsAPISchema,
} from '#src/routes/lead-research-agent/schema'
import { PaginatedAPIResSchema } from '#src/schemas/global'
import { getValidSortKeys } from '#src/utils/requests'
import { routes } from '#src/utils/routes'
import { statisticsQuery, listQuery } from '../queries'

export const LoaderSchema = z.object({
	values: z.object({
		companyId: z.string(),
		tab: z.enum(['all', 'in_queue', 'in_progress', 'completed', 'stopped']),
		page: z.number(),
		sort: z.array(z.string()),
	}),
	data: z.object({
		statistics: LeadResearchAgentStatisticsAPISchema,
		jobs: PaginatedAPIResSchema(LeadResearchAgentListItemAPISchema),
	}),
})

export type LoaderResType = Awaited<ReturnType<ReturnType<typeof loader>>>

export type LoaderRes = z.infer<typeof LoaderSchema>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params: { companyId }, request }: LoaderFunctionArgs): Promise<LoaderRes> => {
		const tab = new URL(request.url).searchParams.get('tab')
		const page = parseInt(new URL(request.url).searchParams.get('page') ?? '1')
		const sort = getValidSortKeys(new URL(request.url).searchParams.getAll('sort'), LeadResearchAgentListItemAPISchema)
		if (!companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const responseValues = {
			companyId,
			tab: (tab ?? 'all') as LoaderRes['values']['tab'],
			page: Number.isNaN(page) ? 1 : page,
			sort: sort.length ? sort : ['-createdAt'],
		}

		try {
			const [statistics, jobs] = await Promise.all([
				queryClient.fetchQuery(statisticsQuery(responseValues.companyId)),
				queryClient.fetchQuery(listQuery(responseValues.companyId, responseValues.page, responseValues.sort)),
			])

			return {
				values: responseValues,
				data: {
					statistics: statistics,
					jobs: jobs,
				},
			}
		} catch (e) {
			showToast({
				message: `${(e as Response).status}: ${(e as Response).statusText} | ${(e as Response).url}`,
				type: 'error',
			})
			throw redirect(routes.organize.index({ companyId }))
		}
	}

import { useQuery, type QueryClient } from '@tanstack/react-query'
import { type LoaderFunctionArgs, useLoaderData, Navigate } from 'react-router-dom'
import { Logo } from '#src/components/ui/logo'
import { userQuery } from '#src/routes/auth/queries'
import { contactQuery, statusQuery } from '#src/routes/enable/chat/queries'
import { checkIsReadOnlySession } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

export type CopilotMappingLoaderResponse = Awaited<ReturnType<ReturnType<typeof loader>>>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.contactId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const user = await queryClient.fetchQuery(userQuery())

		if (checkIsReadOnlySession(user.roles)) {
			throw new Response('Page Not Found', {
				status: 404,
				statusText: 'Not Found',
			})
		}

		return {
			conversation: await queryClient.fetchQuery(contactQuery(params.contactId)),
		}
	}

export default function Layout() {
	const { conversation } = useLoaderData() as CopilotMappingLoaderResponse

	if (!conversation.conversationId || !conversation.company) throw new Error('Missing parameters')

	const { data: contact } = useQuery({
		...statusQuery(conversation.company, conversation.conversationId),
		refetchInterval: ({ state: { data } }) => {
			if (data?.status !== 'ready')
				return 30 * 1000 // 0.5min
			else return false
		},
		refetchIntervalInBackground: true,
	})

	const Loader = () => {
		return (
			<div className="relative flex h-screen w-screen flex-col items-center justify-center gap-4 overflow-hidden">
				<div className="pointer-events-none absolute inset-0 flex animate-pulsing-rings-1 select-none flex-col items-center justify-center opacity-0">
					<div className="min-h-[670px] w-[42.5%] min-w-[670px] rounded-full border border-[#0A1E7A] bg-transparent pb-[42.5%] opacity-[0.06]" />
				</div>
				<div className="pointer-events-none absolute inset-0 flex animate-pulsing-rings-2 select-none flex-col items-center justify-center opacity-0">
					<div className="min-h-[500px] w-[32.5%] min-w-[500px] rounded-full border border-[#0A1E7A] bg-transparent pb-[32.5%] opacity-[0.09]" />
				</div>
				<div className="pointer-events-none absolute inset-0 flex animate-pulsing-rings-3 select-none flex-col items-center justify-center opacity-0">
					<div className="min-h-[330px] w-[22.5%] min-w-[330px] rounded-full border border-[#0A1E7A] bg-transparent pb-[22.5%] opacity-[0.14]" />
				</div>
				<Logo size="xl" type="symbol" />
			</div>
		)
	}

	if (contact?.status === 'ready')
		return (
			<Navigate
				to={routes.enable.copilot.index({
					companyId: conversation.company,
					conversationId: conversation.conversationId,
				})}
			/>
		)

	return <Loader />
}

import { z } from 'zod'
import { EcosystemAPISchema } from '../ecosystem/schema'

export const VerticalCriterionAPISchema = z.object({
	criterionId: z.number(),
	question: z.string().optional(),
	value: z.string().optional(),
})

export const VerticalDataPointAPISchema = z.object({
	id: z.number(),
	value: z.string().optional(),
	title: z.string().optional(),
	bizModel: z.boolean(),
})

export const VerticalCriteriaAPISchema = z.array(VerticalCriterionAPISchema)

export const VerticalDataPointsAPISchema = z.array(VerticalDataPointAPISchema)

export const VerticalAPISchema = z.object({
	id: z.number(),
	name: z.string(),
	ecosystem: EcosystemAPISchema.pick({ id: true, name: true }).required(),
	answers: VerticalCriteriaAPISchema,
	dataPoints: VerticalDataPointsAPISchema.nullable(),
	status: z.string(),
	priority: z.string(),
	bizDescription: z.string().nullable().optional(),
	bizDescriptionOverride: z.string().nullable().optional(),
	bizModelLen: z.number(),
	prompt: z.object({
		status: z.string().nullable(),
	}),
	listBuildingNotes: z.string().nullable().optional(),
	otherNotes: z.array(z.string()).nullable().optional(),
})

export const VerticalListAPISchema = z.array(VerticalAPISchema)

export const VerticalFormSchema = VerticalAPISchema.omit({
	ecosystem: true,
	answers: true,
	dataPoints: true,
	bizDescription: true,
	prompt: true,
})
	.merge(
		z.object({
			answers: z
				.array(
					z.object({
						criterionId: z.number(),
						question: z.string().optional(),
						value: z.string().optional(),
					}),
				)
				.optional()
				.nullable(),
			dataPoints: z
				.object({
					id: z.number(),
					value: z.string().optional(),
					title: z.string().optional(),
					bizModel: z.boolean().optional(),
				})
				.array()
				.optional()
				.nullable(),
		}),
	)
	.partial({ id: true })

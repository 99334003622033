import { getFormProps, getSelectProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { type ReactNode, useEffect, useState } from 'react'
import { useFetcher } from 'react-router-dom'
import { type z } from 'zod'
import { RadioGroup } from '#src/components/forms/RadioGroup'
import { Dialog, DialogTrigger } from '#src/components/ui/dialog'
import { type CompanySchema } from '#src/routes/company/schema'
import { EXPORT_TYPE } from '#src/routes/lead-research-agent/constants'
import { type LRADownloadActionRes, type LRADownloadLoaderRes } from '#src/routes/lead-research-agent/routes/download'
import {
	type LeadResearchAgentItemAPISchema,
	LeadResearchAgentJobDownloadFormSchema,
} from '#src/routes/lead-research-agent/schema'
import { routes } from '#src/utils/routes'

const getFormName = (jobId: z.infer<typeof LeadResearchAgentItemAPISchema>['id']) => `lra-job-${jobId}-download`

type FormProps = {
	companyId: z.infer<typeof CompanySchema>['id']
	jobId: z.infer<typeof LeadResearchAgentItemAPISchema>['id']
}

const Form = (props: FormProps) => {
	const { jobId, companyId } = props
	const loaderFetcher = useFetcher<LRADownloadLoaderRes>()
	const actionFetcher = useFetcher<LRADownloadActionRes>({
		key: 'lra-job-download-action',
	})

	const data = loaderFetcher.data
	const job = data?.data.job

	useEffect(() => {
		if (loaderFetcher.state === 'idle' && !loaderFetcher.data) {
			loaderFetcher.load(routes.leadResearchAgent.download({ jobId, companyId }))
		}
	}, [loaderFetcher, jobId, companyId])

	const [form, fields] = useForm({
		id: getFormName(jobId),
		constraint: getZodConstraint(LeadResearchAgentJobDownloadFormSchema),
		shouldValidate: 'onBlur',
		shouldRevalidate: 'onInput',
		onValidate: ({ formData }) =>
			parseWithZod(formData, {
				schema: LeadResearchAgentJobDownloadFormSchema,
			}),
	})

	return (
		<actionFetcher.Form
			{...getFormProps(form)}
			method="POST"
			action={routes.leadResearchAgent.download({ companyId, jobId })}
			className="w-full"
		>
			<RadioGroup
				inputProps={{
					...getSelectProps(fields.type),
					defaultValue: EXPORT_TYPE.Full,
				}}
				options={[
					{
						value: EXPORT_TYPE.Full,
						label: 'Accounts + Contacts',
						...(!!job && {
							infoText: `${job.statistic.accountCount} accounts | ${job.statistic.contactCount} contacts`,
						}),
					},
					{
						value: EXPORT_TYPE.ICPIBPFull,
						label: 'ICP Accounts + IBP Contacts',
						...(!!job && {
							infoText: `${job.statistic.icpTrueCount} accounts | ${job.statistic.ibpTrueCount} contacts`,
						}),
					},
					{
						value: EXPORT_TYPE.ICPAccounts,
						label: 'ICP Accounts only',
						...(!!job && {
							infoText: `${job.statistic.icpTrueCount} accounts`,
						}),
					},
					{
						value: EXPORT_TYPE.IBPContacts,
						label: 'IBP Contacts only',
						...(!!job && {
							infoText: `${job.statistic.ibpTrueCount} contacts`,
						}),
					},
					{
						value: EXPORT_TYPE.Accounts,
						label: 'Accounts only',
						...(!!job && {
							infoText: `${job.statistic.accountCount} accounts`,
						}),
					},
					{
						value: EXPORT_TYPE.Contacts,
						label: 'Contacts only',
						...(!!job && {
							infoText: `${job.statistic.contactCount} contacts`,
						}),
					},
				]}
				errors={fields.type.errors}
				onChange={e =>
					form.update({
						name: fields.type.name,
						value: e,
					})
				}
			/>
		</actionFetcher.Form>
	)
}

type JobDownloadFormProps = {
	companyId: z.infer<typeof CompanySchema>['id']
	jobId: z.infer<typeof LeadResearchAgentItemAPISchema>['id']
	children: ReactNode
}

export const JobDownloadModal = (props: JobDownloadFormProps) => {
	const { companyId, jobId, children } = props
	const actionFetcher = useFetcher<LRADownloadActionRes>({
		key: 'lra-job-download-action',
	})

	const [open, setOpen] = useState(false)

	return (
		<Dialog
			open={open}
			onOpenChange={setOpen}
			trigger={<DialogTrigger asChild>{children}</DialogTrigger>}
			dialogHeading="Select export options"
			isSubmitting={actionFetcher.state === 'submitting'}
			headerProps={{
				dialogHeading: 'Select export options',
			}}
			actions={[
				{
					type: 'cancel',
					label: 'Cancel',
					onClick: () => setOpen(false),
				},
				{
					type: 'submit',
					label: 'Download',
					formId: getFormName(jobId),
				},
			]}
		>
			<Form jobId={jobId} companyId={companyId} />
		</Dialog>
	)
}

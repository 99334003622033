import { format } from 'date-fns'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { EmptyStateCard } from '#src/components'
import ContactAvatar, { avatarVariantByPersonaType } from '#src/components/chat/avatar'
import { Icon } from '#src/components/ui/icon'
import { PageLayout } from '#src/components/ui/PageLayout'
import { type AidtLoaderResponse } from '#src/routes/organize/aidt/views/item'
import { cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

export const AidtDashboard = () => {
	const {
		chats,
		handle: { companyId },
	} = useLoaderData() as AidtLoaderResponse
	const navigate = useNavigate()

	return (
		<PageLayout title="Lead Research Agent questions">
			{chats?.length ? (
				<table className="w-full">
					<thead className="text-left">
						<tr>
							<th className="py-3 pl-3 text-label-md text-neutral-2-fg">Questions</th>
							<th className="py-3 text-label-md text-neutral-2-fg">Status</th>
							<th className="py-3 text-label-md text-neutral-2-fg">Last updated</th>
							<th></th>
						</tr>
					</thead>

					<tbody className="text-left">
						{chats.map(chat => {
							const status = chat.conversation.status === 'closed' ? 'resolved' : 'unresolved'

							return (
								<tr
									key={chat.conversation.id}
									className="cursor-pointer border-t border-neutral-1-bd transition-colors first:border-none hover:bg-neutral-1-bg-hover has-[.action-button:hover]:bg-transparent"
									onClick={() =>
										navigate(
											routes.organize.aidt.index({
												companyId,
												conversationId: chat.conversation.id,
											}),
										)
									}
								>
									<td className="py-4 pl-3">
										<div className="grid grid-cols-[max-content,1fr] items-center gap-x-2">
											<ContactAvatar
												className="inline-flex shrink-0 items-center justify-center rounded-full"
												variant={avatarVariantByPersonaType(chat.conversation.type?.toLowerCase())}
												size="md"
												initial={chat.conversation.name.slice(0, 1).toUpperCase()}
												badge={
													(chat.conversation.status === 'closed' && (
														<Icon
															name="checkmark-filled-bordered"
															className="absolute bottom-0 right-0 h-3 w-3 text-status-success-bg"
														/>
													)) ||
													(chat.conversation.status === 'unread' && (
														<div className="absolute bottom-0 right-0 h-3 w-3 rounded-full border-2 border-white bg-brand-1-fg" />
													))
												}
											/>
											<div>
												<p
													className={cn(
														'w-full truncate text-body-md font-semibold text-neutral-1-fg',
														status === 'resolved' && 'text-neutral-3-fg',
													)}
												>
													{chat.conversation.name}
												</p>
												<p
													className={cn(
														'w-full max-w-96 truncate text-body-sm font-medium text-neutral-1-fg',
														status === 'resolved' && 'text-neutral-3-fg',
														chat.conversation.status === 'ready' && 'text-neutral-2-fg',
													)}
												>
													{chat.lastMessage}
												</p>
											</div>
										</div>
									</td>
									<td className="py-4">
										<Status status={status} />
									</td>
									<td className="py-4">
										<p className="text-body-md text-neutral-1-fg">
											{format(new Date(chat.conversation.createdAt), 'PP')}
										</p>
									</td>
									<td className="py-4 pr-3 text-right">
										<Icon name="chevron-right" size="sm" className="text-neutral-2-fg" />
									</td>
								</tr>
							)
						})}
					</tbody>
				</table>
			) : (
				<EmptyStateCard
					icon="conversation"
					title="You don’t have any Lead Research Agent questions."
					description="Contact your manager for further assistance"
				/>
			)}
		</PageLayout>
	)
}

function Status({ status }: { status: 'resolved' | 'unresolved' }) {
	return (
		<div
			className={cn(
				'flex items-center gap-0.5 text-label-md',
				(status === 'resolved' && 'text-neutral-inverse-bd') || (status === 'unresolved' && 'text-coolgray-60'),
			)}
		>
			{(status === 'resolved' && (
				<>
					<Icon size="sm" name="checkmark-filled" className="text-status-success-bg" />
					Resolved
				</>
			)) ||
				(status === 'unresolved' && (
					<>
						<Icon size="sm" name="checkmark-outline" className="text-coolgray-60" />
						Unresolved
					</>
				))}
		</div>
	)
}

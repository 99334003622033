import { type z, type AnyZodObject } from 'zod'

export const objToFormData = (data: z.infer<AnyZodObject>) => {
	const res = new FormData()
	Object.entries(data).forEach(([key, value]) => {
		if (value === null || value === undefined) {
			return
		}
		if (Array.isArray(value)) {
			value.forEach(val => {
				res.append(`${key}[]`, val as string | Blob)
			})
		} else {
			res.append(key, value as string | Blob)
		}
	})

	return res
}

/** @description: this one looks more valid, i took it from stackoverflow
 * @ref: https://stackoverflow.com/questions/22783108/convert-js-object-to-form-data  */
export const objectToFormData = (
	obj: unknown,
	rootName: string | undefined = undefined,
	// ignoreList,
) => {
	const formData = new FormData()

	const appendFormData = (data: unknown, root: string | undefined) => {
		// if (!ignore(root)) {
		root = root || ''
		if (data instanceof File) {
			formData.append(root, data)
		} else if (Array.isArray(data)) {
			data.forEach((val, i) => {
				appendFormData(val, root + '[' + i + ']')
			})
		} else if (typeof data === 'object' && data) {
			Object.entries(data).forEach(([key, value]) => {
				if (Object.hasOwn(data, key)) {
					if (root === '') {
						appendFormData(value, key)
					} else {
						appendFormData(value, root + '.' + key)
					}
				}
			})
		} else {
			if (data !== null && typeof data !== 'undefined') {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-expect-error
				formData.append(root, data)
			}
		}
		// }
	}

	// const ignore = (root) => {
	// 	return Array.isArray(ignoreList)
	// 		&& ignoreList.some(function(x) { return x === root; });
	// }

	appendFormData(obj, rootName)

	return formData
}

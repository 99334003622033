import { z } from 'zod'

export const EcosystemAPIPersonaSchema = z.object({
	id: z.number(),
	type: z.string(),
	jobTitles: z.string().nullable().optional(),
	jobSeniority: z.string().nullable().optional(),
	expertise: z.string(),
	status: z.string(),
	priority: z.string(),
})

export const EcosystemAPIVerticalSchema = z.object({
	id: z.number(),
	name: z.string(),
	personas: EcosystemAPIPersonaSchema.array(),
	status: z.string(),
	priority: z.string(),
})

export const EcosystemAPISchema = z.object({
	id: z.number(),
	name: z.string(),
	verticals: EcosystemAPIVerticalSchema.array(),
})

export const EcosystemListAPISchema = z.array(EcosystemAPISchema)

export const EcosystemTemplate = z.object({
	id: z.number(),
	name: z.string(),
})

export const EcosystemTemplateListAPISchema = z.array(EcosystemTemplate)

export const EcosystemTemplateFormSchema = z.object({
	ecosystemId: z.string(),
})

export const CriterionAPISchema = z.object({
	id: z.number(),
	question: z.string(),
	dataSources: z.string().nullable().optional(),
	createdAt: z.string(),
	qualification: z.boolean(),
})

export const CriteriaAPISchema = z.array(CriterionAPISchema)

export const DataPointAPISchema = z.object({
	id: z.number(),
	title: z.string(),
	inBizmodel: z.boolean().optional(),
	dataSources: z.string().nullable().optional(),
})

export const DataPointsAPISchema = z.array(DataPointAPISchema)

export const EcosystemFormSchema = z.object({
	id: z.string().optional(),
	name: z.string().optional(),
	template: z.string().nullable().optional(),
})

export const EcosystemsOrderFormSchema = z.object({
	ecosystems: z.array(z.number()),
})

export const EditEcosystemQualCriteriaPayload = z.object({
	verticals: z.array(
		z.object({
			id: z.number(),
			answers: z.array(
				z.object({
					id: z.number(),
					value: z.string(),
				}),
			),
		}),
	),
})

export const EditEcosystemEnrichCriteriaPayload = z.object({
	verticals: z.array(
		EditEcosystemQualCriteriaPayload.shape.verticals.element.omit({ answers: true }).extend({
			dataPoints: z.array(
				z.object({
					id: z.number(),
					value: z.string(),
				}),
			),
		}),
	),
})

export const CriteriaFormSchema = z.object({
	criteria: z.array(
		CriterionAPISchema.pick({
			id: true,
			question: true,
			dataSources: true,
		}).partial({ id: true }),
	),
	deleteCriterion: z.string().optional(),
})

export const DataPointFormSchema = z.object({
	dataPoints: z.array(
		DataPointAPISchema.pick({
			id: true,
			title: true,
			inBizmodel: true,
			dataSources: true,
		}).partial({ id: true }),
	),
	deleteDataPoint: z.string().optional(),
})

export const DeleteCriteriaFormSchema = z.object({
	deleteCriterion: z.string(),
})

export const DeleteDataPointFormSchema = z.object({
	deleteDataPoint: z.string(),
})

export const VerticalsCriteriaFormSchema = z.object({
	criteria: z
		.object({
			id: z.number().optional(),
			question: z.string(),
			dataSources: z.string().nullable().optional(),
			answers: z
				.object({
					verticalId: z.number(),
					name: z.string(),
					value: z.string().optional(),
				})
				.array(),
		})
		.array(),
})

export const VerticalsDataPointsFormSchema = z.object({
	dataPoints: z
		.object({
			id: z.number().optional(),
			title: z.string(),
			inBizmodel: z.boolean().optional(),
			dataSources: z.string().optional(),
			answers: z
				.object({
					verticalId: z.number(),
					title: z.string(),
					value: z.string().optional(),
				})
				.array(),
		})
		.array(),
})

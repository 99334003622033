import { type QueryClient } from '@tanstack/react-query'
import { type LoaderFunctionArgs } from 'react-router-dom'
import { z } from 'zod'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem/queries'
import { EcosystemListAPISchema } from '#src/routes/calibrate/ecosystem/schema'
import { assignedSignalsQuery, signalTemplateQuery } from '#src/routes/prioritize/queries'
import { AssignedSignalsAPISchema, SignalTemplateAPISchema } from '#src/routes/prioritize/schema'

export const LoaderSchema = z.object({
	handle: z.object({
		companyId: z.string(),
		signalId: z.string(),
	}),
	data: z.object({
		signalTemplate: SignalTemplateAPISchema,
		assignedSignals: AssignedSignalsAPISchema,
		ecosystems: EcosystemListAPISchema,
	}),
})

export type LoaderResType = Awaited<ReturnType<ReturnType<typeof loader>>>

type LoaderRes = z.infer<typeof LoaderSchema>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs): Promise<LoaderRes> => {
		if (!params.companyId || !params.signalId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		return {
			handle: {
				companyId: params.companyId,
				signalId: params.signalId,
			},
			data: {
				assignedSignals: (await queryClient.fetchQuery(assignedSignalsQuery(params.companyId))).filter(
					s => s.signalId === Number(params.signalId),
				),
				ecosystems: await queryClient.fetchQuery(ecosystemsQuery(params.companyId)),
				signalTemplate: await queryClient.fetchQuery(signalTemplateQuery(params.companyId, params.signalId)),
			},
		}
	}

export const selectionControlSizeVariants = {
	sm: 'h-4 w-4',
	md: 'h-4.5 w-4.5',
	lg: 'h-5 w-5',
}

export const defaultLabelSizeVariants = {
	sm: 'text-body-sm',
	md: 'text-body-md',
	lg: 'text-body-lg',
}

export const selectionControlDisabledVariants = {
	true: 'cursor-not-allowed opacity-50 border-neutral-2-bd-disabled',
	false: 'cursor-pointer border-neutral-2-bd',
}

export const selectionControlLayoutVariants = {
	vertical: 'flex-col space-y-2',
	horizontal: 'flex space-x-3.5',
}

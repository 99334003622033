import { type QueryClient } from '@tanstack/react-query'
import { type ActionFunctionArgs } from 'react-router-dom'
import { showToast } from '#src/context/ToastContext'
import { stopJob } from '#src/routes/lead-research-agent/mutations'
import { leadResearchAgentJobsKeys } from '#src/routes/lead-research-agent/queries'

export type ActionRes = Awaited<ReturnType<ReturnType<typeof action>>>

export const action =
	(queryClient: QueryClient) =>
	async ({ params }: ActionFunctionArgs) => {
		if (!params.companyId || !params.jobId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		try {
			await stopJob(params.jobId)
			await queryClient.invalidateQueries({
				queryKey: leadResearchAgentJobsKeys.all,
			})

			showToast({
				message: 'Job stopped successfully',
				type: 'success',
			})

			return {
				success: true,
			}
		} catch (e) {
			const message = `${(e as Response).status}: ${(e as Response).statusText} | ${(e as Response).url}`
			showToast({
				message,
				type: 'error',
			})
			return {
				success: false,
				message,
			}
		}
	}

import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useRouteLoaderData } from 'react-router-dom'
import { type z } from 'zod'
import { PersonaData } from '#src/components/persona'
import { Icon } from '#src/components/ui/icon'
import { type MainLoaderResponse } from '#src/routes/_layout/main'
import { type EcosystemAPIPersonaSchema } from '#src/routes/calibrate/ecosystem/schema'
import { type AssignedSignalsAPISchema, type AssignedSignalsFormSchema } from '#src/routes/prioritize/schema'
import { AssignedPersonaManualInput } from '#src/routes/prioritize/views/components/item/AssignedPersonaManualInput'
import { SignalUsageExample } from '#src/routes/prioritize/views/components/item/SignalUsageExample'
import { Table } from '#src/routes/prioritize/views/components/Table'
import { TableAction } from '#src/routes/prioritize/views/components/TableAction'

type Props = {
	signalId: string
	companyId: string
	ecosystemId: number
	verticalId: number
	personas: z.infer<typeof EcosystemAPIPersonaSchema>[]
	assignedSignals: z.infer<typeof AssignedSignalsAPISchema>
	manualInputRequired: boolean
}

type FormType = z.infer<typeof AssignedSignalsFormSchema>

export const PersonasTable = (props: Props) => {
	const { signalId, companyId, ecosystemId, verticalId, personas, assignedSignals, manualInputRequired } = props
	const { readOnlySession } = useRouteLoaderData('main-loader') as MainLoaderResponse

	const form = useFormContext<FormType>()
	const personasIds = useMemo(() => personas.map(i => String(i.id)), [personas])

	const selectedPersonasLength = Object.entries(form.watch('personas')).filter(
		([personaId, { selected }]) => personasIds.includes(personaId) && selected === 'on',
	).length

	return (
		<Table
			data={personas}
			columns={[
				{
					hidden: readOnlySession,
					name: 'check',
					heading: {
						className: 'w-8',
						title: (
							<TableAction
								checked={
									selectedPersonasLength === personas.length ? true : selectedPersonasLength ? 'indeterminate' : false
								}
								onCheckedChange={checked =>
									personas.forEach(item => {
										form.setValue(`personas.${item.id}.selected`, checked ? 'on' : 'off')
									})
								}
							/>
						),
					},
					body: {
						render: item => (
							<TableAction
								checked={form.watch(`personas.${item.id}.selected`) === 'on'}
								onCheckedChange={checked => form.setValue(`personas.${item.id}.selected`, checked ? 'on' : 'off')}
							/>
						),
					},
				},
				{
					name: 'persona',
					heading: {
						title: 'Persona',
					},
					body: {
						render: item => (
							<PersonaData
								id={item.id}
								type={item.type}
								status={item.status}
								priority={item.priority}
								expertise={item.expertise}
								companyId={companyId}
								ecosystemId={ecosystemId}
								verticalId={verticalId.toString()}
							/>
						),
					},
				},
				{
					name: 'status',
					heading: {
						title: 'Status',
					},
					body: {
						render: item =>
							assignedSignals.some(s => s.personaId === item.id) ? (
								<span className="inline-flex items-center gap-1 text-body-md text-neutral-1-fg">
									<Icon name="checkmark-filled" size="sm" className="text-green-70" />
									Mapped
								</span>
							) : (
								<span className="inline-flex items-center gap-1 text-body-md text-neutral-3-fg">
									<Icon name="checkmark-outline" size="sm" />
									Unmapped
								</span>
							),
					},
				},
				{
					hidden: readOnlySession,
					name: 'manual-input',
					heading: {
						title: <span>Manual Input {manualInputRequired && <span className="text-status-danger-fg">*</span>}</span>,
					},
					body: {
						render: item => (
							<AssignedPersonaManualInput
								value={form.watch(`personas.${item.id}.manualInput`) ?? ''}
								onValueChange={value => {
									form.setValue(`personas.${item.id}.manualInput`, value)
									form.setValue(`personas.${item.id}.selected`, 'on')
								}}
								onChangeAll={value => {
									console.log('this one is called')
									personas.forEach(item => {
										form.setValue(`personas.${item.id}.selected`, 'on')
										form.setValue(`personas.${item.id}.manualInput`, value)
									})
								}}
							/>
						),
					},
				},
				{
					hidden: readOnlySession,
					name: 'usage-example',
					heading: {
						title: 'Usage example',
					},
					body: {
						render: item => (
							<SignalUsageExample
								companyId={companyId}
								signalId={signalId}
								personaId={String(item.id)}
								personaJobTitle={item.jobTitles ?? item.expertise}
							/>
						),
					},
				},
			]}
		/>
	)
}

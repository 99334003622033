import { parseWithZod } from '@conform-to/zod'
import { type QueryClient } from '@tanstack/react-query'
import { type ActionFunctionArgs } from 'react-router-dom'
import { showToast } from '#src/context/ToastContext'
import { downloadJob } from '#src/routes/lead-research-agent/mutations'
import { leadResearchAgentJobsKeys } from '#src/routes/lead-research-agent/queries'
import { LeadResearchAgentJobDownloadFormSchema } from '#src/routes/lead-research-agent/schema'
import { makeDownloadableFromRes } from '#src/utils/files'

export type ActionRes = Awaited<ReturnType<ReturnType<typeof action>>>

export const action =
	(queryClient: QueryClient) =>
	async ({ params, request }: ActionFunctionArgs) => {
		if (!params.companyId || !params.jobId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const formData = await request.formData()

		const submission = parseWithZod(formData, {
			schema: LeadResearchAgentJobDownloadFormSchema,
		})

		if (submission.status !== 'success') {
			throw Error('Unsupported exporting type')
		}

		try {
			const res = await downloadJob(params.jobId, submission.value.type)
			await makeDownloadableFromRes(res)
			await queryClient.invalidateQueries({
				queryKey: leadResearchAgentJobsKeys.all,
			})
			return {
				success: true,
			}
		} catch (e) {
			const res = (e as Response).clone()
			const body = (await res.json()) as { success: boolean; message?: string }
			showToast({
				message: body?.message ?? res?.statusText,
				type: 'error',
			})
			return body
		}
	}

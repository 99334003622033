import { type LoaderFunctionArgs } from 'react-router-dom'

export type LoaderResType = Awaited<ReturnType<typeof loader>>

export const loader = ({ params }: LoaderFunctionArgs) => {
	if (!params.companyId || !params.verticalId)
		throw new Response('Missing parameters', {
			status: 400,
			statusText: 'Bad Request',
		})

	return {
		handle: {
			companyId: params.companyId,
			verticalId: params.verticalId,
		},
	}
}

import { parseWithZod } from '@conform-to/zod'
import { type QueryClient } from '@tanstack/react-query'
import { redirect, type ActionFunctionArgs } from 'react-router-dom'
import { routes } from '#src/utils/routes'
import { createGlossaryTerm, updateGlossaryTerm } from './mutations'
import { glossaryKeys } from './queries'
import { EditTermFormSchema } from './schema'

export type SaveGlossaryTermActionResponse = Awaited<ReturnType<ReturnType<typeof action>>>

export const action =
	(queryClient: QueryClient) =>
	async ({ params, request }: ActionFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const formData = await request.formData()

		const submission = parseWithZod(formData, { schema: EditTermFormSchema })

		if (submission.status !== 'success') {
			throw new Response('Invalid FormData', {
				status: 400,
				statusText: 'Bad Request',
			})
		}

		if (params.termId) {
			await updateGlossaryTerm(params.companyId, params.termId, submission.value)
		} else {
			await createGlossaryTerm(params.companyId, submission.value)
		}

		await queryClient.invalidateQueries({
			queryKey: glossaryKeys.all,
		})

		return redirect(routes.enable.glossary.index({ companyId: params.companyId }))
	}

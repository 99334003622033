import { useQuery } from '@tanstack/react-query'
import { type z } from 'zod'
import { PERSONA_DETAILS_REFRESH_INTERVAL } from '#src/constants/refetchIntervals'
import { useCompany } from '#src/hooks/useCompany'
import { salesCoachQuery } from '#src/routes/calibrate/personas/coach/queries'
import { type PersonaAPISchema } from '#src/routes/calibrate/personas/schema'

const generatePromptStatus = (status?: string | null) => ({
	isGenerating: !['Completed', 'Crashed'].includes(status ?? ''),
	hasCrashed: status === 'Crashed',
})

export const useSalesCoachQuery = (persona: z.infer<typeof PersonaAPISchema>) => {
	const { company } = useCompany()

	const { data: salesCoachData, status: salesCoachStatus } = useQuery({
		...salesCoachQuery(company!.id, String(persona.id)),
		refetchInterval: ({ state: { data } }) => {
			const promptStatuses = [data?.calibrated?.prompt.status, data?.timing?.prompt.status]
			return promptStatuses.some(status => ['Pending', 'In Progress'].includes(status ?? ''))
				? PERSONA_DETAILS_REFRESH_INTERVAL
				: false
		},
		refetchIntervalInBackground: true,
	})

	const personaPromptStatus = generatePromptStatus(persona.prompt?.status)
	const salesCoachGeneralPromptStatus = {
		isGenerating:
			personaPromptStatus.isGenerating || generatePromptStatus(salesCoachData?.calibrated?.prompt?.status).isGenerating,
		hasCrashed: generatePromptStatus(salesCoachData?.calibrated?.prompt?.status).hasCrashed,
	}
	const salesCoachTimingPromptStatus = generatePromptStatus(salesCoachData?.timing?.prompt.status)

	const isSalesCoachEmpty = () => {
		const calibratedPromptStatus = salesCoachData?.calibrated?.prompt.status
		const timingPromptStatus = salesCoachData?.timing?.prompt.status
		const calibratedQuestions = salesCoachData?.calibrated?.questions
		const timingContext = salesCoachData?.timing?.timingContext

		return (
			!personaPromptStatus.isGenerating &&
			salesCoachStatus !== 'pending' &&
			!calibratedQuestions &&
			(!calibratedPromptStatus || calibratedPromptStatus === 'Completed') &&
			!timingContext &&
			!salesCoachData?.timing?.questions &&
			(!timingPromptStatus || timingPromptStatus === 'Completed')
		)
	}

	return {
		salesCoachData: {
			...salesCoachData,
			coldEmail: persona?.coldEmail,
		},
		isSalesCoachEmpty: isSalesCoachEmpty(),
		personaPromptStatus,
		salesCoachGeneralPromptStatus,
		salesCoachTimingPromptStatus,
	}
}

import { type QueryClient } from '@tanstack/react-query'
import { type RouteObject } from 'react-router-dom'
import DefaultSidebarLayout from '#src/layouts/DefaultSidebarLayout'
import RouteBoundary from '#src/routes/route-boundary'
import { loader as settingsLoader } from '#src/routes/settings'
import SettingsDashboard from '#src/routes/settings/dashboard'
import Team, { loader as teamLoader, type TeamLoaderResponse } from '#src/routes/settings/team'
import { action as inviteAction } from '#src/routes/settings/team/invite'
import { routes } from '#src/utils/routes'

export default (client: QueryClient) =>
	({
		id: 'settings-loader',
		path: '/settings/:companyId',
		loader: settingsLoader(client),
		element: <DefaultSidebarLayout />,
		handle: {
			crumb: (data: TeamLoaderResponse) => [
				{
					to: routes.settings.index({ companyId: data.handle.companyId }),
					label: 'Settings',
					icon: 'settings',
				},
			],
		},
		children: [
			{
				index: true,
				element: <SettingsDashboard />,
				errorElement: <RouteBoundary />,
			},
			{
				path: 'team',
				element: <Team />,
				errorElement: <RouteBoundary />,
				loader: teamLoader(client),
				handle: {
					layout: {
						enableHeader: true,
					},
					crumb: (data: TeamLoaderResponse) => [
						{
							to: routes.settings.team({
								companyId: data.handle.companyId,
							}),
							label: 'Team members',
							icon: 'user-multiple',
						},
					],
				},
				children: [
					{
						path: 'invite',
						action: inviteAction(client),
					},
				],
			},
		],
	}) satisfies RouteObject

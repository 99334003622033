import { type QueryClient } from '@tanstack/react-query'
import { type LoaderFunctionArgs } from 'react-router-dom'
import { FILTERS, Hack, MappedPersonas, SORT } from '#src/routes/prioritize/constants'
import { assignedSignalsQuery, filterQuery, signalsTemplatesQuery, sortQuery } from '#src/routes/prioritize/queries'

export type LoaderResType = Awaited<ReturnType<ReturnType<typeof loader>>>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const [
			filtersType,
			filtersSource,
			filtersHack,
			filtersWeight,
			filtersMappedPersonas,
			sortBy,
			assignedSignals,
			signalsTemplatesReq,
		] = await Promise.all([
			queryClient.fetchQuery(filterQuery(FILTERS.TYPE)),
			queryClient.fetchQuery(filterQuery(FILTERS.SOURCE)),
			queryClient.fetchQuery(filterQuery(FILTERS.HACK)),
			queryClient.fetchQuery(filterQuery(FILTERS.WEIGHT)),
			queryClient.fetchQuery(filterQuery(FILTERS.MAPPED_PERSONAS)),
			queryClient.fetchQuery(sortQuery()),
			queryClient.fetchQuery(assignedSignalsQuery(params.companyId)),
			queryClient.fetchQuery(signalsTemplatesQuery(params.companyId)),
		])

		const filters = {
			[FILTERS.TYPE]: filtersType,
			[FILTERS.SOURCE]: filtersSource,
			[FILTERS.HACK]: filtersHack,
			[FILTERS.WEIGHT]: filtersWeight,
			[FILTERS.MAPPED_PERSONAS]: filtersMappedPersonas,
		}

		const signalsTemplates = signalsTemplatesReq.map(st => ({
			...st,
			mappedPersonaCount: assignedSignals?.filter(as => as.signalId === st.id)?.length ?? 0,
		}))

		if (!signalsTemplates?.length)
			throw new Response('Signals Not Found', {
				status: 404,
				statusText: 'Not Found',
			})

		return {
			handle: {
				companyId: params.companyId,
			},
			signalsTemplates: signalsTemplates
				?.filter(st => (filters[FILTERS.TYPE]?.length && st.type ? filters[FILTERS.TYPE]?.includes(st.type) : true))
				?.filter(st =>
					filters[FILTERS.SOURCE]?.length && st.source ? filters[FILTERS.SOURCE]?.includes(st.source) : true,
				)
				?.filter(st => {
					if (typeof st.hack === 'undefined') return true

					const isHack = st.hack ? Hack.HACK : Hack.NO_HACK

					return filters[FILTERS.HACK]?.length ? filters[FILTERS.HACK]?.includes(isHack) : true
				})
				?.filter(st =>
					filters[FILTERS.WEIGHT]?.length && st.config?.weight?.toString()
						? filters[FILTERS.WEIGHT]?.includes(st.config?.weight?.toString() ?? '')
						: true,
				)
				?.filter(st => {
					if (
						filters[FILTERS.MAPPED_PERSONAS]?.includes(MappedPersonas.MAPPED) &&
						!filters[FILTERS.MAPPED_PERSONAS]?.includes(MappedPersonas.NOT_MAPPED)
					) {
						return assignedSignals?.some(s => s.signalId === st.id)
					}

					if (
						filters[FILTERS.MAPPED_PERSONAS]?.includes(MappedPersonas.NOT_MAPPED) &&
						!filters[FILTERS.MAPPED_PERSONAS]?.includes(MappedPersonas.MAPPED)
					) {
						return assignedSignals?.every(s => s.signalId !== st.id)
					}

					return true
				})
				.sort((a, b) => {
					if ([SORT.NUMBER, SORT.WEIGHT, SORT.MAPPED_PERSONAS].includes(sortBy?.key ?? '')) {
						let numberA: number = 0
						let numberB: number = 0

						if (sortBy?.key === SORT.NUMBER) {
							numberA = a.id
							numberB = b.id
						} else if (sortBy?.key === SORT.WEIGHT) {
							numberA = Number(a.config?.weight ?? 1)
							numberB = Number(b.config?.weight ?? 1)
						} else if (sortBy?.key === SORT.MAPPED_PERSONAS) {
							numberA = a.mappedPersonaCount
							numberB = b.mappedPersonaCount
						}

						return sortBy?.direction === 'asc' ? numberA - numberB : numberB - numberA
					} else if (sortBy?.key === SORT.STATUS)
						return sortBy?.direction === 'asc' ? (a.available ? 1 : -1) : a.available ? -1 : 1
					else if ([SORT.INTENT, SORT.INTENT_TYPE, SORT.INTENT_SOURCE, SORT.HACK].includes(sortBy?.key ?? '')) {
						let keyA: string = ''
						let keyB: string = ''

						if (sortBy?.key === SORT.INTENT) {
							keyA = a.name
							keyB = b.name
						} else if (sortBy?.key === SORT.INTENT_TYPE) {
							keyA = a.type
							keyB = b.type
						} else if (sortBy?.key === SORT.INTENT_SOURCE) {
							keyA = a.source
							keyB = b.source
						} else if (sortBy?.key === SORT.HACK) {
							// NOTE: because N is before Y
							keyA = b.hack ? 'Yes' : 'No'
							keyB = a.hack ? 'Yes' : 'No'
						}

						return sortBy?.direction === 'asc' ? keyB.localeCompare(keyA) : keyA.localeCompare(keyB)
					} else {
						return 0
					}
				}),
			filters,
			sortBy,
		}
	}

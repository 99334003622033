import { type LoaderFunctionArgs } from 'react-router-dom'

export type LoaderResType = Awaited<ReturnType<typeof loader>>

export const loader = ({ params: { companyId } }: LoaderFunctionArgs) => {
	if (!companyId)
		throw new Response('Missing parameters', {
			status: 400,
			statusText: 'Bad Request',
		})

	return {
		handle: {
			companyId,
		},
	}
}
